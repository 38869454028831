import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { append, remove, indexOf } from 'ramda';
import { Expander, Checkbox, Input, Slider } from 'shared/view/elements';

interface IMultipleProps {
  children: Array<React.ReactElement<IOptionProps>>;
  selected: string[];
  onChange?: (id: string, vals: string[]) => void;
}

interface IRangeProps {
  from: number;
  to: number;
  fromVal?: number;
  toVal?: number;
  onChange?: (id: string, data: { from: number; to: number; }) => void;
}

interface ICommonProps {
  id: string;
  title: string;
  type: TFilterType;
}

type TFilterType = 'multiple' | 'range';

type Props<T extends TFilterType> = ICommonProps & (
  T extends 'multiple' ? IMultipleProps :
  T extends 'range' ? IRangeProps : {}
);

const b = block('product-filter');

class ProductFilters<T extends TFilterType> extends React.PureComponent<Props<T>> {
  public static Option = Option;
  public render() {
    const { title, children } = this.props;
    let view: React.ReactElement<any> | null = null;

    switch (this.props.type as TFilterType) {
      case 'multiple': {
        const { selected } = (this.props as any as IMultipleProps);
        const options = React.Children.map(
          children, (e: React.ReactElement<IOptionProps>) => e.props,
        );
        view = (
          <div className={b('options')()}>
            {options.map(opt =>
              <div key={opt.value} className={b('option')()}>
                <Checkbox
                  checked={selected.indexOf(opt.value) >= 0}
                  onChange={this.onNewOptionSelect.bind(this, opt)}
                  label={opt.label}
                />
              </div>,
            )}
          </div>
        );
        break;
      }
      case 'range': {
        const { from, to, onChange, fromVal, toVal } = (this.props as any as IRangeProps);
        view = (
          <>
            <div className={b('inputs')()}>
              <div className={b('input')()}>
                <span className={b('label')()}>от</span>
                <Input placeholder="от" value={fromVal || from} onChange={this.onFromChange} />
              </div>
              <div className={b('input')()}>
                <span className={b('label')()}>до</span>
                <Input placeholder="до" value={toVal || to} onChange={this.onToChange} />
              </div>
            </div>
            <div>
              <Slider
                onChange={onChange && onChange.bind(this, this.props.id)}
                fromVal={fromVal || from}
                from={from}
                to={to}
                toVal={toVal || to}
              />
            </div>
          </>
        );
        break;
      }
    }

    return (
      <Expander title={title}>{view}</Expander>
    );
  }

  @bind
  private onNewOptionSelect(option: IOptionProps, value: boolean) {
    const { onChange, selected } = this.props as any as IMultipleProps;
    const index = indexOf(option.value, selected);
    const nextSelected = value && index < 0 ? append(option.value, selected) : remove(index, 1, selected);
    onChange && onChange(this.props.id, nextSelected);
  }

  @bind
  private onToChange(value: string) {
    const val = +value;
    const { onChange, id, fromVal, from } = (this.props as any) as (IRangeProps & ICommonProps);

    if (isNaN(val) || !onChange) { return; }
    onChange(id, { to: this.alignRangeValue(val), from: fromVal || from });
  }

  @bind
  private onFromChange(value: string) {
    const val = +value;
    const { onChange, id, toVal, to } = (this.props as any) as (IRangeProps & ICommonProps);

    if (isNaN(val) || !onChange) { return; }
    onChange(id, { from: this.alignRangeValue(val), to: toVal || to });
  }

  private alignRangeValue(value: number) {
    const { to, from } = (this.props as any) as (IRangeProps & ICommonProps);

    if (value > to) { return to; }
    if (value < from) { return from; }

    return value;
  }
}

interface IOptionProps {
  value: string;
  label: string;
}

function Option(props: IOptionProps) {
  return null;
}

export default ProductFilters;

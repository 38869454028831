import * as NS from '../namespace';
import { initField } from 'shared/helpers/redux';

export const initial: NS.IReduxState = {
  communication: {
    signingIn: {
      error: '',
      isRequesting: false,
    },
    signingUp: {
      error: '',
      isRequesting: false,
    },
    checking: {
      error: '',
      isRequesting: false,
    },
    updating: {
      error: '',
      isRequesting: false,
    },
    restoreStarting: {
      error: '',
      isRequesting: false,
    },
    restoreFinishing: {
      error: '',
      isRequesting: false,
    },
    passwordChanging: {
      error: '',
      isRequesting: false,
    },
  },
  view: {
    isModalOpened: false,
  },
  data: {
    user: null,
  },
  edit: {
    signIn: {
      email: '',
      password: '',
      remember: false,
    },
    signUp: {
      email: '',
      name: '',
      password: '',
    },
    restore: {
      token: '',
      confirm: {
        error: '',
        value: '',
      },
      email: {
        error: '',
        value: '',
      },
      password: {
        error: '',
        value: '',
      },
    },
    changePassword: {
      confirm: '',
      password: '',
    },
    profile: {
      submitted: false,
      address: initField(''),
      firstName: initField(''),
      lastName: initField(''),
      phone: initField(''),
    },
  },
};

import * as React from 'react';
import * as block from 'bem-cn';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bind } from 'decko';
import {
  TProductFilter, TAppliedProductFilter,
  isChoicesProductFilter, isRangeProductFilter, IRangeProductFilter,
  IProductFilterChoice, isProductFilterChoice, IChoiceProductFilter, ICategory,
} from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { Button } from 'shared/view/elements';

import { selectors, actions } from '../../../redux';
import Filter from './Filter';
import './ProductsFilters.scss';

const b = block('products-filters');

interface IStateProps {
  availableFilters: TProductFilter[];
  selectedFilters: TAppliedProductFilter[];
  category: ICategory | null;
}

interface IActionProps {
  changeFiltering: typeof actions.changeFiltering;
  loadProducts: typeof actions.loadCategoryProducts;
}

type Props = IStateProps & IActionProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    category: selectors.selectCategory(state),
    availableFilters: selectors.selectProductFilters(state),
    selectedFilters: selectors.selectSelectedProductFilters(state),
  };
}

function mapActions(dispatch: Dispatch<any>): IActionProps {
  return bindActionCreators({
    changeFiltering: actions.changeFiltering,
    loadProducts: actions.loadCategoryProducts,
  }, dispatch);
}

class ProductFilters extends React.PureComponent<Props> {
  public render() {
    const { availableFilters, selectedFilters } = this.props;

    if (!availableFilters.length) { return null; }

    return (
      <div className={b()}>
        <h2 className={b('title')()}>Фильтры</h2>
        {
          availableFilters.map(filter => {
            // polymorphic filter value, check it type by guards and work with it
            const selectedChoices =
              (isChoicesProductFilter(filter) ?
                filter.choices.filter(ch => Boolean(
                  selectedFilters.find(sf => !isRangeProductFilter(sf) ? sf.id === ch.id : false),
                )) : []
              ).map(ch => ch.id);
            const selectedRange = selectedFilters
              .filter<IRangeProductFilter>(isRangeProductFilter)
              .find(f => f.id === filter.id);

            return (
              <div key={filter.name} className={b('filter')()}>
                {
                  filter.type === 'choice' ? (
                    <Filter<'multiple'>
                      id={filter.id}
                      type="multiple"
                      title={filter.name}
                      selected={selectedChoices}
                      onChange={this.onMultipleFilterChange}
                    >
                      {filter.choices.map(
                        choice => <Filter.Option key={choice.id} value={choice.id} label={choice.choice} />)}
                    </Filter>
                  ) : null
                }
                {
                  filter.type === 'range' ? (
                    <Filter<'range'>
                      id={filter.id}
                      type="range"
                      from={filter.min}
                      to={filter.max}
                      title={filter.name}
                      fromVal={selectedRange && selectedRange.min}
                      toVal={selectedRange && selectedRange.max}
                      onChange={this.onRangeFilterChange}
                    />
                  ) : null
                }
              </div>
            );
          })
        }

        <div className={b('submit')()}>
          <Button onClick={this.onSubmit} theme="light"><span>Применить</span></Button>
        </div>
      </div>
    );
  }

  @bind
  private onSubmit() {
    const { category } = this.props;

    if (category) {
      this.props.loadProducts({
        page: category.products.page - 1,
        size: category.products.size,
        sort: category.products.sorting,
      });
    }
  }

  @bind
  private onMultipleFilterChange(id: string, choicesIds: string[]) {
    const rangeFilters = this.props.selectedFilters.filter<IRangeProductFilter>(isRangeProductFilter);
    const choicesFilters = this.props.availableFilters
      .filter<IChoiceProductFilter>(isChoicesProductFilter);
    const filter = choicesFilters.find(f => f.id === id);

    const prevChoices = this.props.selectedFilters
      .filter<IProductFilterChoice>(isProductFilterChoice)
      .filter(f => filter && !filter.choices.some(c => c.id === f.id));

    const selectedChoices = [
      ...rangeFilters,
      ...prevChoices,
      ...choicesFilters
        .map(f => f.choices)
        .reduce((fs, f) => fs.concat(f), [])
        .filter(c => choicesIds.includes(c.id)),
    ];

    this.props.changeFiltering(selectedChoices);
  }

  @bind
  private onRangeFilterChange(id: string, { from, to }: { from: number; to: number; }) {
    const filter = this.props.availableFilters
      .filter<IRangeProductFilter>(isRangeProductFilter)
      .find(f => f.id === id);

    if (!filter) { return; }

    const filters = [
      ...this.props.selectedFilters.filter(f => f.id !== id),
      { ...filter, min: from, max: to },
    ];
    this.props.changeFiltering(filters);
  }
}

export default connect<IStateProps, IActionProps>(mapState, mapActions)(ProductFilters);

import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: loadPagesSuccess,
  execute: loadPages,
  failed: loadPagesFail,
} = makeCommunicationActionCreators<NS.ILoadPages, NS.ILoadPagesSuccess, NS.ILoadPagesFail>(
  'FLAT_PAGES:LOAD_PAGES', 'FLAT_PAGES:LOAD_PAGES_SUCCESS', 'FLAT_PAGES:LOAD_PAGES_FAIL',
);

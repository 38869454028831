import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppReduxState } from 'shared/types/app';
import { Input, Checkbox, Button } from 'shared/view/elements';
import { ICommunication } from 'shared/types/redux';

import { selectCommunication, selectSignInEdit } from '../../../redux/selectors';
import * as actions from '../../../redux/actions';
import './Signin.scss';
import { isSuccessedByState } from 'shared/helpers/redux';

interface IOwnProps {
  onToSignup?: () => void;
  onSuccess?: () => void;
  onForgot?: () => void;
}

interface IStateProps {
  signingIn: ICommunication;
  email: string;
  password: string;
  remember: boolean;
}

interface IActionProps {
  changeEmail: typeof actions.changeSignInEmail;
  changePassword: typeof actions.changeSignInPassword;
  changeRemember: typeof actions.changeSignInRemember;
  signIn: typeof actions.signIn;
}

type IProps = IStateProps & IActionProps & IOwnProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    ...selectSignInEdit(state),
    signingIn: selectCommunication(state, 'signingIn'),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    changeEmail: actions.changeSignInEmail,
    changePassword: actions.changeSignInPassword,
    changeRemember: actions.changeSignInRemember,
    signIn: actions.signIn,
  }, dispatch);
}

const b = block('sign-in');

class SignIn extends React.PureComponent<IProps, {}> {
  public componentWillReceiveProps(nextProps: IProps) {
    if (isSuccessedByState(this.props.signingIn, nextProps.signingIn)) {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  public render() {
    const { signingIn, onToSignup, remember, password, email } = this.props;
    const { changeEmail, changePassword, changeRemember, onForgot } = this.props;
    const errors = [signingIn.error].filter(e => Boolean(e));
    return (
      <div className={b()}>
        <h2 className={b('title')()}>Вход</h2>

        <form className={b('fields')()} onSubmit={this.onSubmit}>
          <div className={b('field')()}>
            <Input name="email" type="email" placeholder="Email" value={email} onChange={changeEmail}/>
          </div>
          <div className={b('field')()}>
            <Input placeholder="Пароль" name="password" type="password" value={password} onChange={changePassword} />
          </div>

          <div className={b('controls')()}>
            {errors && <p className={b('errors')()}>{errors}</p>}
            <div className={b('control')()}>
              <Checkbox label="Запомнить" checked={remember} onChange={changeRemember}/>
            </div>
            <div className={b('control')()}>
              <Button type="submit" theme="green" disabled={signingIn.isRequesting}>
                <span>Вход</span>
              </Button>
            </div>
            <button type="button" className={b('forgot')()} onClick={onForgot}>
              Забыли пароль?
            </button>
          </div>
        </form>
        <div className={b('footer')()}>
          <p className={b('no-acc-label')()}>Нет учетной записи?</p>
          <button onClick={onToSignup}  className={b('to-signup')()}>
            Зарегистрироваться
          </button>
        </div>
      </div>
    );
  }

  @bind
  private onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.signIn();
  }
}

export { SignIn };
export default connect(mapState, mapDispatch)(SignIn);

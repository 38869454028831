import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { ICategory, IProduct, IPaginated, TProductSorting, TAppliedProductFilter } from 'shared/types/models';
import { ICommunication } from 'shared/types/redux';
import { IAppReduxState } from 'shared/types/app';
import { selectors } from '../../../redux';

interface IOwnProps {
  type: 'products' | 'categories';
  itemWidth?: number;
  ProductsView?: React.ComponentType<{ items: ICategory['products']; loading: ICommunication; }>;
  CategoriesView?:
    React.ComponentType<{ itemWidth?: number; categories: IPaginated<ICategory>; loading: ICommunication; }>;
}

interface IStateProps {
  products: IPaginated<IProduct> & { sorting: TProductSorting; appliedFilters: TAppliedProductFilter[] };
  categories: IPaginated<ICategory>;
  loading: { products: ICommunication; categories: ICommunication };
}

type Props = IStateProps & IOwnProps;

const b = block('search-items');

function mapState(state: IAppReduxState, props: IOwnProps): IStateProps {
  return {
    categories: selectors.selectCategories(state),
    products: selectors.selectProducts(state),
    loading: selectors.selectLoading(state),
  };
}

class SearchItems extends React.PureComponent<Props> {
  public render() {
    const { products, ProductsView, categories, CategoriesView, type, loading, itemWidth } = this.props;
    return (
      <div className={b()}>
        {
          type === 'categories' && CategoriesView ? (
            <CategoriesView itemWidth={itemWidth} categories={categories} loading={loading.categories} />
          ) : null
        }
        {
          type === 'products' && ProductsView ? (
            <ProductsView items={products} loading={loading.products} />
          ) : null
        }
      </div>
    );
  }
}

export default connect<IStateProps, {}, IOwnProps>(mapState)(SearchItems);

import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): NS.IReduxState['data'] {
  switch (action.type) {
    case 'VIEW_CATEGORIES:LOAD_CATEGORIES_SUCCESS': {
      return { ...state, categories: action.payload };
    }
    case 'VIEW_CATEGORIES:LOAD_CATEGORY_SUCCESS': {
      return { ...state, category: action.payload };
    }
    case 'VIEW_CATEGORIES:CHANGE_FILTERING': {
      return {
        ...state,
        category: state.category && {
          ...state.category,
          products: {
            ...state.category.products,
            appliedFilters: action.payload,
          },
        },
      };
    }
    case 'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS': {
      return {
        ...state,
        category: state.category && {
          ...state.category,
          products: {
            ...state.category.products,
            sorting: action.payload.sort,
            page: action.payload.page + 1,
          },
        },
      };
    }
    case 'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS_SUCCESS': {
      return {
        ...state,
        category: state.category ? {
          ...state.category,
          products: action.payload,
        } : state.category,
      };
    }
    case 'VIEW_CATEGORIES:LOAD_PRODUCT': {
      return {
        ...state,
        product: null,
      };
    }
    case 'VIEW_CATEGORIES:LOAD_PRODUCT_SUCCESS': {
      return {
        ...state,
        product: action.payload,
      };
    }
    default: return state;
  }
}

import * as NS from '../namespace';
import initial from './initial';

export default function reducer(state: NS.IReduxState = initial, action: NS.Action): NS.IReduxState {
  switch (action.type) {
    case 'SHOPS:LOAD': {
      return { ...state, communication: { ...state.communication, loading: { isRequesting: true, error: '' } } };
    }
    case 'SHOPS:LOAD_FAIL': {
      return {
        ...state,
        communication: { ...state.communication, loading: { isRequesting: false, error: action.error } },
      };
    }
    case 'SHOPS:LOAD_SUCCESS': {
      return {
        ...state,
        data: { ...state.data, shops: action.payload },
        communication: { ...state.communication, loading: { isRequesting: false, error: '' } },
      };
    }
    default: return state;
  }
}

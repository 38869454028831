import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { Button, CounterInput } from 'shared/view/elements';
import { IProduct, IUser, IBasket } from 'shared/types/models';
import { isExists } from 'shared/model/product';
import { IAppReduxState } from 'shared/types/app';

import { actions, selectors } from '../../../redux';

import './AddToBasket.scss';
import { isSuccessedByState } from 'shared/helpers/redux';
import { ICommunication } from 'shared/types/redux';
import { ClipLoader } from 'react-spinners';

interface IOwnProps {
  product: IProduct;
  user: IUser | null;
}

interface IState {
  count: number;
  adding: boolean;
}

interface IStateProps {
  basket: IBasket;
  saving: ICommunication;
}

interface IActionProps {
  addToBasket: typeof actions.addToBasket;
  saveBasket: typeof actions.saveBasket;
  changeItemCount: typeof actions.changeBasketCount;
}

type Props = IOwnProps & IStateProps & IActionProps;

const b = block('add-to-basket');

function mapState(state: IAppReduxState): IStateProps {
  return {
    basket: selectors.selectItems(state),
    saving: selectors.selectCommunication(state, 'saving'),
  };
}

function mapActions(dispatch: Dispatch<IAppReduxState>) {
  return bindActionCreators({
    addToBasket: actions.addToBasket,
    saveBasket: actions.saveBasket,
    changeItemCount: actions.changeBasketCount,
  }, dispatch);
}

class AddToBasket extends React.PureComponent<Props, IState> {
  public state: IState = {
    count: this.getCurrentBasketCount(this.props.basket),
    adding: false,
  };
  private btn: HTMLDivElement | null = null;
  private timeout: number | null = null;

  public get isAdded() {
    const { product, basket } = this.props;
    return Boolean(basket.items.find(item => item.id === product.id));
  }

  public getCurrentBasketCount(basket: IBasket) {
    const { product } = this.props;
    const productInBasket = basket.items.find(item => item.id === product.id);
    return productInBasket ? productInBasket.basketCount : 1;
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (isSuccessedByState(this.props.saving, nextProps.saving) && this.state.adding) {
      this.setState({ adding: false });
    }

    if (this.props.basket !== nextProps.basket) {
      this.setState({ count: this.getCurrentBasketCount(nextProps.basket) });
    }
  }

  public componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  public render() {
    const { product, saving } = this.props;
    const { count } = this.state;
    const added = this.isAdded;

    return (
      <div className={b()}>
        <div
          className={b('count')()}
          style={{ height: `${this.btn ? this.btn.clientHeight : 'auto'}px` }}
        >
          <CounterInput
            value={count}
            min={added ? 1 : 0}
            max={product.count}
            disabled={!isExists(product) || saving.isRequesting}
            onValChange={this.onCountChange}
          />
        </div>

        <div className={b('submit')()} ref={this.onBtnRef}>
          <Button
            theme={added ? 'light' : 'green'}
            disabled={!isExists(product) || !count || added}
            onClick={this.onSubmit}
          >
            <span className={b('btn-label')()}>
              {added ? ' Добавлено' : 'В корзину'}
              {this.state.adding ? (
                <div className={b('preloader')()}>
                  <ClipLoader color="#009846" size={14} loading={saving.isRequesting} />
                </div>
                ) : null}
            </span>
          </Button>
        </div>
      </div>
    );
  }

  @bind
  private onBtnRef(ref: HTMLDivElement | null) {
    this.btn = ref;
    if (ref) { this.forceUpdate(); }
  }

  @bind
  private onCountChange(count: number) {
    this.setState(
      { count },
      () => {
        if (!this.isAdded) { return; }

        const { product, basket, user, changeItemCount } = this.props;
        const productInBasket = basket.items.find(item => item.id === product.id);
        this.setState({ adding: true });
        productInBasket && changeItemCount(productInBasket, this.state.count, user);
        this.props.saveBasket({ user, cleanLocal: false });
      },
    );
  }

  @bind
  private onSubmit() {
    this.props.addToBasket(this.props.product, this.state.count);
    this.props.saveBasket({ user: this.props.user, cleanLocal: false });
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(AddToBasket);

import * as React from 'react';
import * as block from 'bem-cn';
import InlineSvg from 'svg-inline-react';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, matchPath, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// tslint:disable:no-duplicate-imports
import * as RouterDom from 'react-router-dom';
// tslint:enable:no-duplicate-imports
import { bind } from 'decko';

import { SearchInput } from 'features/searchItems';
import * as auth from 'features/auth';
import * as viewNews from 'features/viewNews';
import * as viewCategories from 'features/viewCategories';
import * as basket from 'features/basket';
import * as flatPages from 'features/flatPages';

import { RowsLayout } from 'shared/view/elements';
import { Header, Footer } from 'shared/view/components';
import { ICategory, IProduct, INews, IUser, IFlatPage, IBanner } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { findCategoryById } from 'shared/model/product';
import { range } from 'shared/types/func';
import { repeat } from 'shared/helpers/funtools';
import { isSuccessedByState } from 'shared/helpers/redux';
import { ICommunication } from 'shared/types/redux';

import { compileCategoriesRoute } from '../routes';
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs';

import UserIcon from './img/user.svg';
import TruckIcon from './img/truck.svg';
import SettingsIcon from './img/settings.svg';
import BasketIcon from './img/cart.svg';
import './BaseLayout.scss';

interface IOwnProps extends RouteComponentProps<any> {
  children: React.ReactNode;
}

interface IStateProps {
  categories: ICategory[];
  basketItems: IProduct[];
  product: IProduct | null;
  detailedNews: INews | null;
  userChecking: ICommunication;
  user: IUser | null;
  flatPages: IFlatPage[];
  menuFlatPages: IFlatPage[];
  banners: IBanner[];
}

interface IActionProps {
  loadBasket: typeof basket.actions.loadBasket;
  checkUser: typeof auth.actions.checkUser;
  saveBasket: typeof basket.actions.saveBasket;
  loadPages: typeof flatPages.actions.loadPages;
  startFinishingOfRestorePassword: typeof auth.actions.startFinishingOfRestoring;
  loadCategories: typeof viewCategories.actions.loadCategories;
}

function mapActions(dispatch: Dispatch<any>) {
  return bindActionCreators({
    loadBasket: basket.actions.loadBasket,
    saveBasket: basket.actions.saveBasket,
    checkUser: auth.actions.checkUser,
    loadPages: flatPages.actions.loadPages,
    startFinishingOfRestorePassword: auth.actions.startFinishingOfRestoring,
    loadCategories: viewCategories.actions.loadCategories,
  }, dispatch);
}

type Props = IOwnProps & IStateProps & IActionProps;

const b = block('base-layout');

function MenuItem(
  { icon, children, type = '', onClick }: { onClick?: () => void, type?: string; icon: string, children: any},
) {
  return (
    <div className={b('menu-item')()} onClick={onClick}>
      <InlineSvg className={b('menu-item-icon', { type })()} src={icon} element="div" />
      <div className={b('menu-item-content')()}>
        {children}
      </div>
    </div>
  );
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    categories: viewCategories.selectors.selectCategories(state),
    basketItems: basket.selectors.selectItems(state).items,
    product: viewCategories.selectors.selectProduct(state),
    user: auth.selectors.selectUser(state),
    userChecking: auth.selectors.selectUserChecking(state),
    detailedNews: viewNews.selectors.selectDetailedNews(state),
    flatPages: flatPages.selectors.selectPages(state),
    menuFlatPages: flatPages.selectors.selectMenuFlatPages(state),
    banners: state.home.data.banners,
  };
}

const { IsAuthorized, Auth } = auth;
const { CategoriesList, ProductsFilters } = viewCategories;

class BaseLayout extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.loadPages();
    this.props.checkUser();
    this.checkRouteQuery();
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (isSuccessedByState(this.props.userChecking, nextProps.userChecking)) {
      this.props.loadBasket(nextProps.user);
    }
  }

  public render() {
    const { children, basketItems, menuFlatPages: menu, location, banners } = this.props;
    const leftBanner = banners.find(banner => banner.position === 'left');
    const header = (
      <Header
        underPhoneContent={<SearchInput onSubmit={this.onSearchSubmit} />}
        firstMenuPart={
          <div className={b('header-menu')()}>
            <MenuItem icon={UserIcon} type="user">
              <div className={b('auth-item')()}>
                <Auth onSuccess={this.onAuthorized} onUserClick={this.onToSettings} />
              </div>
            </MenuItem>
            <MenuItem icon={BasketIcon} onClick={this.onBasketClick}>
              Корзина ({basketItems.length})
            </MenuItem>
          </div>}
        secondMenuPart={
          <IsAuthorized>
            <div className={b('header-menu', { type: 'second' })()}>
              <MenuItem icon={TruckIcon} onClick={this.onToOrders}>Заказы</MenuItem>
              <MenuItem icon={SettingsIcon} onClick={this.onToSettings}>Настройка</MenuItem>
            </div>
          </IsAuthorized>
        }
      />
    );

    return (
      <RowsLayout
        footerContent={<Footer />}
        headerContent={header}
      >
        <div className={b()}>
          <nav className={b('nav')()}>
            <Link className={b('nav-link')()} to="/">Главная</Link>
            <Link className={b('nav-link')()} to="/catalog">Каталог</Link>
            <Link className={b('nav-link')()} to="/news">Новости</Link>
            <Link className={b('nav-link')()} to="/shops">Магазины</Link>
            {menu.map(page =>
              <Link key={page.slug} className={b('nav-link')()} to={`/${page.slug}`}>{page.title}</Link>)}
          </nav>
          <div className={b('crumbs')()}><BreadCrumbs getName={this.getCrumbName}/></div>
          <div className={b('content')()}>
            <div className={b('left')()}>
              <CategoriesList
                expandable
                defaultExpanded={!matchPath(location.pathname, { path: '/catalog/*' })}
                onSelect={this.onCategorySelect}
                view="recursive"
              />
              <Switch>
                <Route path="/catalog/*/product">{null}</Route>
                <Route path="/catalog/:id" component={CatalogFilters} />
              </Switch>
              <div className={b('banner')()}>
                {leftBanner &&
                  <a href={leftBanner.url}>
                    <img className={b('banner-img')()} src={leftBanner.image} />
                  </a>
                }
              </div>
            </div>
            <div className={b('center')()}>{children}</div>
          </div>
        </div>
      </RowsLayout>
    );
  }

  @bind
  private onSearchSubmit(query: string) {
    this.props.history.push(`/search`);
  }

  @bind
  private onToOrders() {
    this.props.history.push('/personal?view=history');
  }

  @bind
  private onToSettings() {
    this.props.history.push('/personal?view=settings');
  }

  @bind
  private getCrumbName(match: RouterDom.match<any>): string {
    const { categories } = this.props;
    const paths = [
      '/', '/news', '/news/:id', '/catalog', '/basket',
      '/personal', '/news', '/shops', '/search',
      ...range(1, 1, 8).map(i => '/catalog' + repeat(i, () => '/:id').reduce((r, p) => r + p)),
      '/:flatpage',
    ];
    const matched = paths.reduce(
      (prev, path) => {
        if (prev) { return prev; }

        const matchRes = matchPath(match.url, { path, exact: true });
        return matchRes ? (matchRes.params as any).id || (matchRes.params as any).flatpage || match.url : prev;
      },
      '',
    );

    const names: { [key: string]: string } = {
      '/': 'Главная',
      '/catalog': 'Каталог',
      '/basket': 'Корзина',
      '/personal': 'Личный кабинет',
      '/news': 'Новости',
      '/shops': 'Магазины',
      '/search': 'Поиск',
    };
    const ignoredNames = ['product'];
    const category = findCategoryById(matched, categories);
    const news = this.props.detailedNews && this.props.detailedNews.id === matched && this.props.detailedNews;
    const product = this.props.product && this.props.product.id === matched && this.props.product;
    const isIgnored = ignoredNames.includes(matched);
    const flatPage = this.props.flatPages.find(p => p.slug === matched);

    if (isIgnored) { return ''; }

    if (product) { return product.name; }
    if (news) { return news.title; }
    if (flatPage) { return flatPage.title; }
    if (category) { return category.title; }

    return names[matched] || 'unknown';
  }

  @bind
  private onBasketClick() {
    this.props.history.push('/basket');
  }

  @bind
  private onCategorySelect(parents: ICategory[], item: ICategory) {
    const path = compileCategoriesRoute(parents, item);
    this.props.history.push(path);
  }

  @bind
  private onAuthorized() {
    if (this.props.basketItems.length) {
      this.props.saveBasket({ user: this.props.user, cleanLocal: true });
    } else {
      this.props.loadBasket(this.props.user);
    }

    this.props.loadCategories();
  }

  private checkRouteQuery() {
    // perform correct dispatching to other features
    const { search: query } = this.props.location;
    const params =  new URLSearchParams(query);
    const action: string = params.get('action') || '';

    if (action === 'password_restore') {
      const token = params.get('token');

      if (token) {
        this.props.startFinishingOfRestorePassword(token);
      } else {
        console.error('Action password_restore set, but no token found');
      }
    }

    this.props.history.push(this.props.location.pathname);
  }
}

function CatalogFilters() {
  return (
    <div className={b('catalog-filters')()}>
      <ProductsFilters />
    </div>
  );
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(BaseLayout);

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { bind } from 'decko';

import { IAppReduxState } from 'shared/types/app';
import { Input, Button } from 'shared/view/elements';
import { ICommunication } from 'shared/types/redux';

import { actions, selectors } from '../../../redux';
import './Signup.scss';
import { isSuccessedByState } from 'shared/helpers/redux';

interface IOwnProps {
  theme?: any;
  onToSignin?: () => void;
  onSuccess?: () => void;
}

interface IStateProps {
  signingUp: ICommunication;
  name: string;
  email: string;
  password: string;
}

interface IActionProps {
  changeName: typeof actions.changeSignUpName;
  changeEmail: typeof actions.changeSignUpEmail;
  changePassword: typeof actions.changeSignUpPassword;
  signUp: typeof actions.signUp;
}

type IProps = IStateProps & IActionProps & IOwnProps;

interface IState {
  step: 'data' | 'confirm';
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    signingUp: selectors.selectCommunication(state, 'signingUp'),
    ...selectors.selectSignUpEdit(state),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    changeName: actions.changeSignUpName,
    changeEmail: actions.changeSignUpEmail,
    changePassword: actions.changeSignUpPassword,
    signUp: actions.signUp,
  }, dispatch);
}

const b = block('sign-up');

class SignUp extends React.PureComponent<IProps, IState> {
  public state: IState = { step: 'data' };

  public componentWillReceiveProps(nextProps: IProps) {
    if (isSuccessedByState(this.props.signingUp, nextProps.signingUp)) {
      this.setState({ step: 'confirm' });
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  public render() {
    const { step } = this.state;
    const { onToSignin, changeEmail, changeName, changePassword, signingUp } = this.props;
    const { name, email, password } = this.props;
    return (
      <div className={b()}>
        {step === 'data' ? (
          <>
            <form className={b('content')()} onSubmit={this.onSubmit}>
              <h2 className={b('title')()}>Регистрация</h2>
              <div className={b('field')()}>
                <Input name="username" placeholder="Имя" value={name} onChange={changeName} />
              </div>
              <div className={b('field')()}>
                <Input type="email" name="email" placeholder="Email" value={email} onChange={changeEmail} />
              </div>
              <div className={b('field')()}>
                <Input
                  name="password"
                  type="password"
                  placeholder="Пароль"
                  value={password}
                  onChange={changePassword}
                />
              </div>

              <p className={b('error')()}>{signingUp.error}</p>

              <div className={b('submit')()}>
                <Button theme="green" disabled={signingUp.isRequesting}>
                  <span>Подтвердить</span>
                </Button>
              </div>

              <div className={b('footer')()}>
                <button className={b('to-signin')()} onClick={onToSignin}>
                  Войти через существующий аккаунт
                </button>
              </div>
            </form>
          </>
        ) : null}

        {step === 'confirm' ? (
          <>
            <p className={b('confirmation')()}>
              Пожалуйста, подтвердите регистрацию, передя по ссылке, отправленной Вам на почту.
              После этого вы сможете авторизоваться.
            </p>
            <Button onClick={onToSignin}>
              <span>Войти</span>
            </Button>
          </>
        ) : null
        }
      </div>
    );
  }

  @bind
  private onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.signUp();
  }
}

export { SignUp };
export default connect(mapState, mapDispatch)(SignUp);

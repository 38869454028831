import * as React from 'react';
import * as block from 'bem-cn';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OrderBasket from 'modules/shared/OrderBasket/OrderBasket';

import { Title } from 'shared/view/elements';
import './Basket.scss';

type Props = RouteComponentProps<void>;

const b = block('basket-layout');

class BasketLayout extends React.PureComponent<Props> {
  public render() {

    return (
      <div className={b()}>
        <Title text="Корзина" />
        <div className={b('list')()}>
          <OrderBasket />
        </div>

        <Helmet>
          <title>"Папирус" - Корзина</title>
        </Helmet>
      </div>
    );
  }
}

export default BasketLayout;

import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import { initial } from '../initial';

export const communicationReducer = combineReducers<NS.IReduxState['communication']>({
  deliveryPointsLoading: makeCommunicationReducer<
    NS.ILoadDeliveryPoints, NS.ILoadDeliveryPointsSuccess, NS.ILoadDeliveryPointsFail
  >(
    'ORDERS:LOAD_DELIVERY_POINTS',
    'ORDERS:LOAD_DELIVERY_POINTS_SUCCESS',
    'ORDERS:LOAD_DELIVERY_POINTS_FAIL',
    initial.communication.deliveryPointsLoading,
  ),
  creatingOrder: makeCommunicationReducer<
    NS.ICreateOrder, NS.ICreateOrderSuccess, NS.ICreateOrderFail
  >(
    'ORDERS:CREATE', 'ORDERS:CREATE_SUCCESS', 'ORDERS:CREATE_FAIL', initial.communication.creatingOrder,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClipLoader } from 'react-spinners';
import InlineSvg from 'svg-inline-react';
import Carousel from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IProduct, IUser } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { Title, Photo } from 'shared/view/elements';
import { isExists } from 'shared/model/product';
import { ICommunication } from 'shared/types/redux';
import { sortByLetter, repeat } from 'shared/helpers/funtools';

import { actions, selectors } from '../../../redux';
import * as ArrowIcon from './img/arrow.svg';
import * as StubPhoto from './img/stub.png';
import './ProductDetails.scss';

interface IOwnProps {
  id: string;
  user: IUser | null;
  Extra?: React.ComponentType<{ user: IUser | null; product: IProduct; }>;
}

interface IStateProps {
  product: IProduct | null;
  loading: ICommunication;
}

interface IActionProps {
  load: typeof actions.loadProduct;
}

interface IState {
  image: string | null;
}

type Props = IOwnProps & IStateProps & IActionProps;

const b = block('product-details');

function mapState(state: IAppReduxState): IStateProps {
  return {
    product: selectors.selectProduct(state),
    loading: selectors.selectProductLoading(state),
  };
}

function mapActions(dispatch: any) {
  return bindActionCreators({
    load: actions.loadProduct,
  }, dispatch);
}

class ProductDetails extends React.PureComponent<Props, IState> {
  public state: IState = { image: null };
  public componentDidMount() {
    this.props.load(this.props.id);
  }

  public render() {
    const { product, Extra, user, loading } = this.props;
    const properties = product ? product.properties.concat([
      { id: '100000', name: 'Страна', value: String(product.country) },
      { id: '100001', name: 'В коробке (шт)', value: String(product.inBoxCount) },
      { id: '100002', name: 'В упаковке (шт)', value: String(product.inPackCount) },
      { id: '100003', name: 'Объем коробки', value: String(product.boxVolume) },
      { id: '100004', name: 'Код', value: String(product.vendorCode) },
    ]).sort(sortByLetter(a => a.name)) : [];
    const carouselSettings = { verticalSwiping: true } as any;
    const images = product ? product.images
      .concat(product.images.length < 3 ? repeat(3 - product.images.length, _ => StubPhoto) : []) : [];
    const isNoAdditionalImages = images
      .filter(i => i === StubPhoto).length >= 2;

    return (
      <div className={b()}>
        {!product && !loading.isRequesting ? <p>Продукт не найден</p> : null}
        {loading.isRequesting ? (
          <div className={b('preloader')()}>
            <ClipLoader size={60} color="#009846" />
          </div>
         ) : null}
        {product && !loading.isRequesting ? (
          <div className={b('content')()}>
            <Title text={product.name} />

            <div className={b('data')()}>
              <div className={b('image')()}>
                <Photo src={this.state.image || product.image} />
              </div>

              {
                !isNoAdditionalImages ? (
                  <div className={b('images')()}>
                    <Carousel
                      {...carouselSettings}
                      swipeToSlide
                      vertical
                      dots={false}
                      arrows
                      slidesToShow={2}
                      infinite
                      nextArrow={<Arrow type="bottom" />}
                      prevArrow={<Arrow type="top" />}
                    >
                      {images.map((image: string, i: number) =>
                        <div key={i} className={b('add-img-wrapper')()}>
                          <img
                            onClick={this.onImageSelect.bind(this, image)}
                            src={image}
                            className={b('add-img')()}
                          />
                        </div>,
                      )}
                    </Carousel>
                  </div>
                ) : null
              }
              <div className={b('info')()}>
                <p className={b('label')()}>Цена за шт.</p>
                <p className={b('price')()}>{product.price.toFixed(2)} руб</p>
                <p className={b('label')()}>{isExists(product) ? 'В наличии' : 'Нет на складе'}</p>
                {
                  Extra ? (
                    <div className={b('extra')()}>
                      <Extra user={user} product={product} />
                    </div>
                  ) : null
                }
              </div>
            </div>

            <div className={b('properties')()}>
              <div className={b('column', { hidden: !properties.length })()}>
                <div className={b('row')()}>Характеристики:</div>
                {properties.map(prop => (
                  <div className={b('row')()} key={prop.id}>
                    <div className={b('prop-label')()}>{prop.name}</div>
                    <div className={b('prop-value')()}>{prop.value}</div>
                  </div>
                ))}
              </div>
              <div className={b('column', { fullwidth: !properties.length })()}>
                <div className={b('row')()}>Описание:</div>
                <div className={b('row')()}>
                  <p className={b('descr')()}>{product.description || 'Нет описания'}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private onImageSelect(image: string) {
    if (image === StubPhoto) { return; }
    this.setState({ image });
  }
}

function Arrow({ type, onClick }: { type: 'top' | 'bottom',  onClick?: any }) {
  return (
    <div onClick={onClick}  className={b('slider-arrow', { type })()}>
      <InlineSvg className={b('slider-arrow-icon')()}  element="div" src={ArrowIcon} />
    </div>
  );
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(ProductDetails);

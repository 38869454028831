import { takeLatest } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { IDependencies } from 'shared/types/app';
import * as NS from '../namespace';
import * as actions from './actions';
import { requestSaga } from 'shared/helpers/redux';

const loadBannersType: NS.ILoadBanners['type'] = 'HOME:LOAD_BANNERS';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    yield [
      takeLatest(loadBannersType, loadBannersSaga, deps),
    ];
  };
}

function* loadBannersSaga(deps: IDependencies) {
  yield* requestSaga(
    () => deps.api.loadBanners(),
    actions.loadBannersSuccess,
    actions.loadBannersFail,
  );
}

export default getSaga;

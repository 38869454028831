import {
  IServerGroup, IServerProduct, IServerUser,
  IServerBasket, IServerNews, IServerShop, IServerPage,
  IServerProfile, IServerProductFilter, IServerDeliveryPoint, IServerOrder,
} from '../types';
import {
  ICategory, IProduct, IUser, IBasket, IBasketItem,
  INews, IShop, IFlatPage, IPaginated, TProductSorting,
  TProductFilter, TAppliedProductFilter, IDeliveryPoint, ICreatedOrder,
} from 'shared/types/models';

export function convertGroupToCategory(
  group: IServerGroup,
  products: IPaginated<IProduct> & { sorting: TProductSorting; appliedFilters: TAppliedProductFilter[]; },
  filters: TProductFilter[],
  maxPrice: number,
): ICategory {
  const host = process.env.NODE_ENV === 'development' ? __HOST__ : '';
  return {
    id: String(group.id),
    categories: (group.children || []).map(
      category => convertGroupToCategory(
        category,
        { items: [], page: 0, size: 0, total: 0, sorting: '-name', appliedFilters: [] }, [],
        maxPrice,
      ),
    ),
    title: group.name,
    products,
    image: group.image ? `${host}${group.image}` : '',
    filters: [
      { id: '0', type: 'range', name: 'Цена', min: 1, max: Math.floor(maxPrice) },
      ...filters,
    ],
  };
}

export function convertProductToItem(product: IServerProduct): IProduct {
  const host = process.env.NODE_ENV === 'development' ? __HOST__ : '';
  const mainImage = product.image ? `${host}${product.image}` : void 0;
  return {
    count: product.in_stock,
    id: String(product.id),
    images: (product.additional_images || [])
      .map<string>(i => `${host}${i.image}`)
      .concat([
        mainImage || '',
      ])
      .filter((i: string) => Boolean(i)),
    name: product.name || '',
    price: product.price || 0,
    vendorCode: product.vendor_code,
    description: product.description || '',
    image: mainImage,
    inBoxCount: product.box_quantity || 0,
    inPackCount: product.pack_quantity || 0,
    boxVolume: product.box_vol || 0,
    country: product.country,
    categoryId: product.group_id !== void 0 ? String(product.group_id) : null,
    properties: (product.param_values || []).map(pv => ({
      id: String(pv.id),
      name: pv.param_type.name,
      value: pv.name,
    })),
  };
}

export function convertServerUser(user: IServerUser, profile: IServerProfile | null): IUser {
  return {
    address: profile ? profile.address : '',
    phone: profile ? profile.phone : '',
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
  };
}

export function convertServerBasket(basket: IServerBasket): IBasket {
  return {
    items: basket.items.map<IBasketItem>(item => ({
      basketCount: item.quantity,
      basketItemId: String(item.id),
      ...convertProductToItem(item.product),
    })),
    summaryPrice: basket.price,
  };
}

export function convertServerNews(news: IServerNews): INews {
  return {
    id: String(news.id),
    title: news.title,
    date: new Date(news.date).getTime(),
    image: news.title_image || 'https://img.kavtorg.ru/content/logo/size300/543f110d35eed5ffc718a70a2cef4d6c.png',
    shortDescription: news.short_text,
    content: news.text,
  };
}

export function convertServerShop(shop: IServerShop): IShop {
  return {
    city: shop.city.name,
    fax: shop.fax,
    id: String(shop.id),
    mail: shop.mail,
    name: shop.name,
    phone: shop.phone,
    workingTime: shop.work_time,
    lat: shop.latitude,
    lng: shop.longitude,
    address: shop.address,
    descr: shop.description || '',
  };
}

export function convertServerPage(page: IServerPage): IFlatPage {
  return {
    content: page.html,
    slug: page.url,
    title: page.title,
    isInMenu: page.is_menu,
  };
}

export function convertServerProductFilter(filter: IServerProductFilter): TProductFilter {
  return {
    id: String(filter.id),
    type: 'choice',
    name: filter.name,
    choices: filter.param_values.map(pv => ({
      id: String(pv.id),
      choice: pv.name,
    })),
  };
}

export function convertServerDeliveryPoint(point: IServerDeliveryPoint): IDeliveryPoint {
  return {
    id: String(point.id),
    address: point.address,
    name: point.name,
    workTime: point.work_time,
  };
}

export function convertServerOrder(
  { id, full_name, point_delivery, type_delivery, status, phone, price, items, created_at }: IServerOrder,
): ICreatedOrder {
  const host = process.env.NODE_ENV === 'development' ? __HOST__ : '';
  return {
    deliveryPoint: point_delivery ? convertServerDeliveryPoint(point_delivery) : null,
    deliveryType: type_delivery === 'pickup' ? 'own' : 'deliver',
    fullName: full_name,
    id: String(id),
    status,
    summary: price,
    createdAt: created_at ? new Date(created_at).getTime() : 0,
    phone,
    items: items.map<IBasketItem>(item => ({
      basketCount: item.quantity,
      id: String(item.product.id),
      basketItemId: String(item.id),
      categoryId: item.product.group_id !== void 0 ? String(item.product.group_id) : null,
      count: item.product.in_stock,
      description: item.product.description,
      images: [],
      image: item.product.image ? `${host}${item.product.image}` : void 0,
      name: item.product.name,
      price: item.price || 0,
      properties: [],
      inBoxCount: item.product.box_quantity,
      inPackCount: item.product.pack_quantity,
      boxVolume: item.product.box_vol,
      country: item.product.country,
      vendorCode: item.product.vendor_code,
    })),
  };
}

import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.searchItems;
}

export function selectProducts(state: IAppReduxState) {
  return getFeatureState(state).data.products;
}

export function selectCategories(state: IAppReduxState) {
  return getFeatureState(state).data.categories;
}

export function selectLoading(state: IAppReduxState) {
  return {
    products: getFeatureState(state).communication.loadingProducts,
    categories: getFeatureState(state).communication.loadingCategories,
  };
}

export function selectCount(state: IAppReduxState) {
  const data = getFeatureState(state).data;

  return {
    products: data.products.total,
    categories: data.categories.total,
  };
}

export function selectQuery(state: IAppReduxState): string {
  return getFeatureState(state).edit.query;
}

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import InlineSvg from 'svg-inline-react';
import { ClipLoader } from 'react-spinners';

import { IAppReduxState } from 'shared/types/app';
import { IBasket, IBasketItem, IUser } from 'shared/types/models';
import { Photo, CounterInput, Modal, Button, Title } from 'shared/view/elements';

import { selectors, actions } from '../../../redux';
import * as CloseIcon from './img/close.svg';
import './BasketItems.scss';

interface IOwnProps {
  user: IUser | null;
  onGetItemLink: (item: IBasketItem) => string;
}

interface IStateProps {
  basket: IBasket;
  loading: boolean;
}

interface IActionProps {
  deleteItem: typeof actions.deleteItem;
  changeBasketCount: typeof actions.changeBasketCount;
  saveBasket: typeof actions.saveBasket;
  clearBasket: typeof actions.clearBasket;
}

type IProps = IStateProps & IActionProps & IOwnProps;

interface IState {
  isDeleting: boolean;
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    basket: selectors.selectItems(state),
    loading: selectors.selectLoading(state),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    deleteItem: actions.deleteItem,
    changeBasketCount: actions.changeBasketCount,
    saveBasket: actions.saveBasket,
    clearBasket: actions.clearBasket,
  }, dispatch);
}

const b = block('basket-items');

class BasketItems extends React.PureComponent<IProps, IState> {
  public state: IState = { isDeleting: false };

  public render() {
    const { basket, loading, onGetItemLink } = this.props;

    return (
      <div className={b()}>
        <BasketItemsGrid
          onGetItemLink={onGetItemLink}
          items={basket.items}
          loading={loading}
          price={basket.summaryPrice}
          onCountChange={this.onCountChange}
          onDeleteItem={this.onDeleteItem}
          onClearAll={this.onClearAllRequested}
        />
        <Modal isOpen={this.state.isDeleting} onRequestClose={this.onClearAllAborted}>
          <div className={b('delete-modal')()}>
            <Title text="Очистить корзину" theme="regular" />
            <p className={b('delete-modal-text')()}>
              Вы уверены, что хотите полностью очистить корзину?
            </p>
            <div className={b('delete-modal-controls')()}>
              <Button theme="green" onClick={this.onClearAllSubmit}><span>Да</span></Button>
              <Button onClick={this.onClearAllAborted}><span>Отменить</span></Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  @bind
  private onDeleteItem(item: IBasketItem) {
    this.props.deleteItem({ item, user: this.props.user });
  }

  @bind
  private onCountChange(item: IBasketItem, value: number) {
    this.props.changeBasketCount(item, value, this.props.user);
    this.props.saveBasket({ user: this.props.user, cleanLocal: false });
  }

  @bind
  private onClearAllRequested() {
    if (this.props.basket.items.length > 0) {
      this.setState({ isDeleting: true });
    }
  }

  @bind
  private onClearAllAborted() {
    this.setState({ isDeleting: false });
  }

  @bind
  private onClearAllSubmit() {
    this.setState({ isDeleting: false });
    this.props.clearBasket(this.props.user);
  }
}

interface IGridProps {
  items: IBasketItem[];
  price: number;
  loading: boolean;
  cropped?: boolean;
  onGetItemLink: (item: IBasketItem) => string;
  onDeleteItem?: (item: IBasketItem) => void;
  onCountChange?: (item: IBasketItem, val: number) => void;
  onClearAll?: () => void;
}

export function BasketItemsGrid(
  {
    items, price, loading = false, onCountChange,
    onDeleteItem, cropped = false, onGetItemLink,
    onClearAll,
  }: IGridProps,
) {
  return (
    <table className={b('items', { cropped })()}>
      <tbody>
        <tr>
          <th className={b('header-cell')()}>№</th>
          <th className={b('header-cell')()}>Код</th>
          <th className={b('header-cell')()}>Фото</th>
          <th className={b('header-cell')()}>Наименование</th>
          <th className={b('header-cell')()}>Цена</th>
          <th className={b('header-cell')()}>Цена со скидкой</th>
          <th className={b('header-cell', { type: 'count' })()}>Кол-во</th>
          <th className={b('header-cell')()}>Стоимость</th>
          {
            onDeleteItem ? (
              <th className={b('header-cell')()}>
                <InlineSvg
                  className={b('delete-icon')()}
                  element="button"
                  onClick={onClearAll}
                  src={CloseIcon}
                />
              </th>
            ) : null
          }
        </tr>

        {items.map((item, i) => (
          <tr key={item.id}>
            <th className={b('cell')()}>{i + 1}</th>
            <th className={b('cell')()}>{item.vendorCode}</th>
            <th className={b('cell', { type: 'image' })()}>
              <div className={b('photo')()}><Photo src={item.image} /></div>
            </th>
            <th className={b('cell')()}><Link to={onGetItemLink(item)}>{item.name}</Link></th>
            <th className={b('cell')()}>{item.price.toFixed(2)}</th>
            <th className={b('cell')()}>{item.price.toFixed(2)}</th>
            <th className={b('cell')()}>
              {
                onCountChange ? (
                  <CounterInput
                    onValChange={onCountChange.bind(this, item)}
                    min={1}
                    max={item.count}
                    value={item.basketCount}
                    disabled={loading}
                  />
                ) : item.basketCount
              }
            </th>
            <th className={b('cell')()}>{(item.price * item.basketCount).toFixed(2)}</th>

            {
              onDeleteItem ? (
                <th className={b('cell')()}>
                  <button className={b('delete')()} onClick={onDeleteItem.bind(this, item)}>
                    <InlineSvg className={b('delete-icon')()} element="div" src={CloseIcon} />
                  </button>
                </th>
              ) : null
            }
          </tr>
        ))}

        <tr>
          <td colSpan={7} className={b('summary-title-cell')()}>Сумма:</td>
          <td className={b('summary-price-cell')()}>{price.toFixed(2)} руб</td>
          {
            onDeleteItem ? (
              <td className={b('cell', { type: 'preloader' })()}>
                <ClipLoader
                  className={b('preloader')()}
                  color="#009846"
                  size={14}
                  loading={loading}
                />
              </td>
            ) : null
          }
        </tr>
      </tbody>
    </table>
  );
}

export { BasketItems };
export default connect(mapState, mapDispatch)(BasketItems);

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppReduxState } from 'shared/types/app';

import { actions, selectors } from './../../../redux';
import { chunkBy } from 'shared/helpers';
import { INews } from 'shared/types/models';
import './NewsList.scss';
import { Photo } from 'shared/view/elements';

interface IOwnProps {
  view?: 'list' | 'short-grid';
  onNewsSelected?: (news: INews) => void;
}

interface IStateProps {
  items: INews[];
}

interface IActionProps {
  loadNews: typeof actions.loadNews;
}

type IProps = IStateProps & IActionProps & IOwnProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    items: selectors.selectNews(state),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    loadNews: actions.loadNews,
  }, dispatch);
}

const b = block('news-list');

class NewsList extends React.PureComponent<IProps> {
  public componentDidMount() {
    this.props.loadNews({ limit: this.props.view === 'short-grid' ? 4 : 1000 });
  }

  public render() {
    const { items, view = 'short-grid' } = this.props;
    let ViewComponent: React.ComponentType<IViewProps> | null = null;

    if (view === 'list') {
      ViewComponent = ListView;
    }

    if (view === 'short-grid') {
      ViewComponent = ShortGridView;
    }

    return (
      <div className={b({ view })()}>
        {!items.length ? <p className={b('empty')()}>Нет новостей</p> : null}
        {ViewComponent && <ViewComponent items={items} onSelect={this.onNewsSelected} />}
      </div>
    );
  }

  @bind
  private onNewsSelected(item: INews) {
    this.props.onNewsSelected && this.props.onNewsSelected(item);
  }
}

interface IViewProps {
  items: INews[];
  onSelect?: (item: INews) => void;
}

function ListView({ items, onSelect }: IViewProps): React.ReactElement<any> {
  return (
    <>
      {items.map(item => (
        <article className={b('item')()} key={item.id}>
          <div className={b('item-img')()}><Photo src={item.image} /></div>
          <div className={b('item-content')()}>
            <h3 className={b('item-title')()} onClick={onSelect && onSelect.bind(this, item)}>{item.title}</h3>
            <p className={b('item-date')()}>{formatDate(new Date(item.date))}</p>
            <p className={b('item-descr')()}>{item.shortDescription}</p>
          </div>
        </article>
      ))}
    </>
  );
}

function ShortGridView({ items, onSelect }: IViewProps): React.ReactElement<any> {
  const rows = chunkBy(items, chunk => chunk.length >= 4).slice(0, 1);
  return (
    <>
      {rows.map((row, i) => (
        <div className={b('row')()} key={i}>
          {row.map((item, _i) => (
            <article className={b('item')()} key={`${i}-${item.id}`} onClick={onSelect && onSelect.bind(this, item)}>
              <div className={b('item-img')()}><img src={item.image} /></div>
              <p className={b('item-date')()}>{formatDate(new Date(item.date))}</p>
              <p className={b('item-descr')()}>{item.shortDescription}</p>
            </article>))}
        </div>
      ))}
    </>
  );
}

function formatDate(date: Date) {
  const pad = (a: number) => String(a).padStart(2, '0');
  return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`;
}

export { NewsList };
export default connect(mapState, mapDispatch)(NewsList);

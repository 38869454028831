import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): NS.IReduxState['data'] {
  switch (action.type) {
    case 'ORDERS:LOAD_DELIVERY_POINTS_SUCCESS': {
      return {
        ...state,
        deliveryPoints: action.payload,
      };
    }
    case 'ORDERS:CREATE_SUCCESS': {
      return {
        ...state,
        createdOrder: action.payload,
      };
    }
    case 'ORDERS:LOAD_ORDER_HISTORY_SUCCESS': {
      return {
        ...state,
        ordersHistory: action.payload,
      };
    }
    default: return state;
  }
}

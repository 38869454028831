import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import InlineSvg from 'svg-inline-react';
import * as Mark from './img/mark.svg';
import './Checkbox.scss';

interface IProps {
  checked: boolean;
  label: string;
  onChange?: (val: boolean) => void;
}

const b = block('checkbox');

class Checkbox extends React.PureComponent<IProps, {}> {
  public render() {
    const { label, checked } = this.props;

    return (
      <div className={b()}>
        <div className={b('rectangle', { checked })()} onClick={this.onClick}>
          {checked ? <InlineSvg src={Mark} element="div" className={b('mark')()} /> : null}
        </div>
        <label className={b('label')()} onClick={this.onClick}>{label}</label>
      </div>
    );
  }

  @bind
  private onClick() {
    this.props.onChange && this.props.onChange(!this.props.checked);
  }
}

export default Checkbox;

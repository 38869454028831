import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: loadCategoriesSuccess,
  execute: loadCategories,
  failed: loadCategoriesFail,
} = makeCommunicationActionCreators<
  NS.ILoadCategories, NS.ILoadCategoriesSuccess, NS.ILoadCategoriesFail
>(
  'VIEW_CATEGORIES:LOAD_CATEGORIES',
  'VIEW_CATEGORIES:LOAD_CATEGORIES_SUCCESS',
  'VIEW_CATEGORIES:LOAD_CATEGORIES_FAIL',
);

export const {
  completed: loadCategorySuccess,
  execute: loadCategory,
  failed: loadCategoryFail,
} = makeCommunicationActionCreators<
  NS.ILoadCategory, NS.ILoadCategorySuccess, NS.ILoadCategoryFail
>(
  'VIEW_CATEGORIES:LOAD_CATEGORY',
  'VIEW_CATEGORIES:LOAD_CATEGORY_SUCCESS',
  'VIEW_CATEGORIES:LOAD_CATEGORY_FAIL',
);

export const {
  completed: loadCategoryProductsSuccess,
  execute: loadCategoryProducts,
  failed: loadCategoryProductsFail,
} = makeCommunicationActionCreators<
  NS.ILoadCategoryProducts, NS.ILoadCategoryProductsSuccess, NS.ILoadCategoryProductsFail
>(
  'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS',
  'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS_SUCCESS',
  'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS_FAIL',
);

export const {
  completed: loadProductSuccess,
  execute: loadProduct,
  failed: loadProductFail,
} = makeCommunicationActionCreators<
  NS.ILoadProduct, NS.ILoadProductSuccess, NS.ILoadProductFail
>(
  'VIEW_CATEGORIES:LOAD_PRODUCT',
  'VIEW_CATEGORIES:LOAD_PRODUCT_SUCCESS',
  'VIEW_CATEGORIES:LOAD_PRODUCT_FAIL',
);

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import InlineSvg from 'svg-inline-react';
import * as Arrow from './img/arrow.svg';
import './Expander.scss';

interface IProps {
  title: string;
}

interface IState {
  opened: boolean;
}

const b = block('expander');

class Expander extends React.PureComponent<IProps, IState> {
  public state: IState = { opened: false };
  public render() {
    const { opened } = this.state;
    return (
      <div className={b()}>
        <div className={b('header', { opened })()} onClick={this.toggle}>
          <InlineSvg className={b('arrow')()} element="div" src={Arrow} />
          <p className={b('title')()}>{this.props.title}</p>
        </div>

        {opened ? (
          <div className={b('content')()}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }

  @bind
  private toggle() {
    this.setState({ opened: !this.state.opened });
  }
}

export default Expander;

import * as NS from '../../namespace';
import initial from '../initial';
import { combineReducers } from 'redux';
import { ReducersMap } from 'shared/types/redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

const reducer = combineReducers({
  loadingProducts: makeCommunicationReducer<NS.ISearchProducts, NS.ISearchProductsSuccess, NS.ISearchProductsFail>(
    'SEARCH:EXECUTE_PRODUCTS', 'SEARCH:EXECUTE_PRODUCTS_SUCCESS', 'SEARCH:EXECUTE_PRODUCTS_FAIL',
    initial.communication.loadingProducts,
  ),
  loadingCategories: makeCommunicationReducer<
    NS.ISearchCategories, NS.ISearchCategoriesSuccess, NS.ISearchCategoriesFail
  >(
    'SEARCH:EXECUTE_CATEGORIES', 'SEARCH:EXECUTE_CATEGORIES_SUCCESS', 'SEARCH:EXECUTE_CATEGORIES_FAIL',
    initial.communication.loadingCategories,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

export default reducer;

import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: saveBasketSuccess,
  execute: saveBasket,
  failed: saveBasketFail,
} = makeCommunicationActionCreators<
  NS.ISaveBasket, NS.ISaveBasketSuccess, NS.ISaveBasketFail
>(
  'BASKET:SAVE', 'BASKET:SAVE_SUCCESS', 'BASKET:SAVE_FAIL',
);

export const {
  completed: loadBasketSuccess,
  execute: loadBasket,
  failed: loadBasketFail,
} = makeCommunicationActionCreators<
  NS.ILoadBasket, NS.ILoadBasketSuccess, NS.ILoadBasketFail
>(
  'BASKET:LOAD', 'BASKET:LOAD_SUCCESS', 'BASKET:LOAD_FAIL',
);

export const {
  completed: deleteItemSuccess,
  execute: deleteItem,
  failed: deleteItemFail,
} = makeCommunicationActionCreators<
  NS.IDeleteBasketItem, NS.IDeleteBasketItemSuccess, NS.IDeleteBasketItemFail
>(
  'BASKET:DELETE_ITEM', 'BASKET:DELETE_ITEM_SUCCESS', 'BASKET:DELETE_ITEM_FAIL',
);

import * as React from 'react';
import * as block from 'bem-cn';
import { Link } from 'react-router-dom';
import InlineSvg from 'svg-inline-react';
import * as VkIcon from './img/vk.svg';
import './Footer.scss';

interface IProps {
  a?: any;
}

class Footer extends React.PureComponent<IProps, {}> {
  public render() {
    const b = block('footer');
    return (
      <div className={b()}>
        <div className={b('content')()}>
          <section className={b('section')()}>
            <h3 className={b('title')()}>Контакты</h3>
            <p className={b('contact')()}><b>Адреса офисов</b></p>
            <p className={b('contact')()}>г. Томск, ул. Мичурина, 47</p>
            <p className={b('contact')()}>г. Томск, пр. Ленина, 97а</p>
            <br />
            <p className={b('contact')()}>Телефон: <a href="tel: 83822900790">(3822) 90-07-90</a> (Общий)</p>
            <p className={b('contact')()}>Телефон: <a href="tel: 83822907107">(3822) 907-107</a> (Корпоративный отдел)</p>
            <p className={b('contact')()}>
              E-mail: <a href="mailto:corporate@papirus.tom.ru">corporate@papirus.tom.ru</a>
            </p>
          </section>

          <section className={b('section')()}>
            <h3 className={b('title')()}>Клиентам</h3>
            <Link to="/shops" className={b('link')()}>Сеть магазинов "Канцелярский Мир"</Link>
            <Link to="/corporate" className={b('link')()}>Оптовый отдел</Link>
            <Link to="/catalog" className={b('link')()}>Каталог</Link>
            <Link to="/certificates" className={b('link')()}>Подарочные сертификаты</Link>
            <Link to="/discount" className={b('link')()}>Карты постоянного покупателя</Link>
          </section>

          <section className={b('section')()}>
            <h3 className={b('title')()}>О компании</h3>
            <Link to="/suppliers" className={b('link')()}>Поставщикам</Link>
            <Link to="/about" className={b('link')()}>О компании</Link>
            <Link to="/news" className={b('link')()}>Новости</Link>
            <div className={b('socials')()}>
              <a
                href="https://vk.com/kancmir_tomsk"
                target="_blank"
                className={b('social')()}
              >
                <InlineSvg element="div" className={b('social-icon')()} src={VkIcon} />
              </a>
            </div>
          </section>
        </div>

         <section className={b('copyright')()}>
            © {new Date().getFullYear()} ООО “Папирус”. Все права защищены
         </section>
      </div>
    );
  }
}

export default Footer;

import { takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import * as actions from '../actions';
import { IDependencies, IAppReduxState } from 'shared/types/app';
import * as NS from '../../namespace';
import { requestSaga } from 'shared/helpers/redux';
import { selectCategory } from '../selectors';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadAllCatsType: NS.ILoadCategories['type'] = 'VIEW_CATEGORIES:LOAD_CATEGORIES';
    const loadCategoryType: NS.ILoadCategory['type'] = 'VIEW_CATEGORIES:LOAD_CATEGORY';
    const loadProductsType: NS.ILoadCategoryProducts['type'] = 'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS';
    const loadProductType: NS.ILoadProduct['type'] = 'VIEW_CATEGORIES:LOAD_PRODUCT';
    yield [
      takeLatest(loadAllCatsType, loadAllCategories, deps),
      takeLatest(loadCategoryType, loadCategory, deps),
      takeLatest(loadProductsType, loadCategoryProducts, deps),
      takeLatest(loadProductType, loadProductSaga, deps),
    ];
  };
}

function* loadAllCategories({ api }: IDependencies) {
  yield* requestSaga(api.loadCategories, actions.loadCategoriesSuccess, actions.loadCategoriesFail);
}

function* loadCategory({ api }: IDependencies, { payload: id }: NS.ILoadCategory) {
  yield* requestSaga(() => api.loadCategory(id) , actions.loadCategorySuccess, actions.loadCategoryFail);
}

function* loadCategoryProducts({ api }: IDependencies, { payload: { page , size, sort } }: NS.ILoadCategoryProducts) {
  const state: IAppReduxState = yield select();
  const category = selectCategory(state);

  if (!category) { return; }

  const reqLimit = size;
  const reqPage = page;
  const filtering = category.products.appliedFilters;

  yield* requestSaga<{ data: any }>(
    () => api.loadProducts(category.id, reqLimit, reqPage * reqLimit, sort, filtering),
    res => actions.loadCategoryProductsSuccess(res.data) as any,
    actions.loadCategoryFail,
  );
}

function* loadProductSaga({ api }: IDependencies, { payload: productId }: NS.ILoadProduct) {
  yield* requestSaga(
    () => api.loadProduct(productId),
    actions.loadProductSuccess,
    actions.loadProductFail,
  );
}

export { getSaga };

import * as NS from '../../namespace';
import { initial } from '../initial';

function viewReducer(state: NS.IReduxState['view'] = initial.view, action: NS.Action): NS.IReduxState['view'] {
  switch (action.type) {
    case 'AUTH:CLOSE_MODAL': {
      return { ...state, isModalOpened: false };
    }
    case 'AUTH:OPEN_MODAL': {
      return { ...state, isModalOpened: true };
    }
    case 'AUTH:START_FINISHING_RESTORING': {
      return { ...state, isModalOpened: true };
    }
    default:
      return state;
  }
}

export default viewReducer;

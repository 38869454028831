import * as React from 'react';
import * as block from 'bem-cn';
import { head } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ClipLoader } from 'react-spinners';
import { bind } from 'decko';
import { IShop } from 'shared/types/models';

import { IAppReduxState } from 'shared/types/app';
import { ICommunication } from 'shared/types/redux';
import { sortByLetter } from 'shared/helpers/funtools';

import { selectors, actions } from '../../../redux';
import ShopDetails from '../../components/ShopDetails/ShopDetails';
import './FullShopsView.scss';

const b = block('full-shops-view');

interface IStateProps {
  shops: IShop[];
  loading: ICommunication;
}

interface IActionProps {
  load: typeof actions.loadShops;
}

type Props = IStateProps & IActionProps;

interface IState {
  selected: IShop | null;
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    shops: selectors.selectShops(state),
    loading: selectors.selectLoading(state),
  };
}

function mapActions(dispatch: Dispatch<any>) {
  return bindActionCreators({ load: actions.loadShops }, dispatch);
}

class FullShopsView extends React.PureComponent<Props, IState> {
  public state: IState = { selected: null };
  private mapRef: HTMLDivElement | null = null;
  private map: ymaps.Map | null = null;

  public componentDidMount() {
    this.props.load();
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (!this.map) { return; }

    if (this.props.shops.length !== nextProps.shops.length) {
      this.map.setCenter(this.getCenter());
      this.placeShopsOnMap();
    }
  }

  public render() {
    const { shops, loading } = this.props;
    const { selected } = this.state;
    const chunks =
      shops.reduce<{[key: string]: IShop[]}>(
        (res, item) => {
          res[item.city] = (res[item.city] || []).concat([item]);
          return res;
        },
        {},
      );

    return (
      <div className={b()}>
        {
          !shops.length && loading.isRequesting ? (
            <ClipLoader className={b('preloader')()} size={120} color="#388e3c" />
          ) : null
        }

        {
          shops.length ? (
            <div ref={this.onMapRef} id="map" style={{ width: '100%', height: '400px' }} />
          ) : null
        }

        <div className={b('cities')()}>
          {Object.keys(chunks).map(key => (
            <section className={b('city')()} key={key}>
              <h3 className={b('city-title')()}>{key}</h3>
              <div className={b('city-content')()}>
                {chunks[key].sort(sortByLetter(o => o.name)).map(shop => (
                  <button
                    key={shop.id}
                    onClick={this.onSelect.bind(this, shop, { centerize: true })}
                    title={shop.name}
                    className={b('shop')()}
                  >
                    {shop.name}
                  </button>
                ))}
              </div>
            </section>
          ))}
        </div>

        {
          selected ? (
            <section className={b('detailed')()}>
              <ShopDetails shop={selected} />
            </section>
          ) : null
        }
      </div>
    );
  }

  @bind
  private onMapRef(ref: HTMLDivElement | null) {
    this.mapRef = ref;
    if (ref) {
      ymaps.ready(this.initYandexMap);
    }
  }

  @bind
  private initYandexMap() {
    if (!this.mapRef) { return; }

    this.map = new ymaps.Map(
      this.mapRef, { center: this.getCenter(), zoom: 15 },
    );
    this.placeShopsOnMap();
  }

  @bind
  private getCenter() {
    const shop = head(this.props.shops);
    const defaultCenter: [number, number] = [55.76, 37.64];
    const center: [number, number] | null = shop ? [shop.lat, shop.lng] : defaultCenter;

    return center;
  }

  @bind
  private placeShopsOnMap() {
    if (!this.map) { return; }

    this.props.shops.map(shop => {
      const placemark = new ymaps.Placemark([shop.lat, shop.lng], {
        hintContent: shop.name,
        balloonContent: `<b>${shop.name}</b><br />${shop.address}<br />${shop.workingTime}`,
      });
      placemark.events.add('click', this.onSelect.bind(this, shop, { centerize: false }));
      this.map!.geoObjects.add(placemark);
    });
  }

  @bind
  private onSelect(shop: IShop, { centerize }: { centerize?: boolean }) {
    if (centerize && this.map) {
      this.map.setCenter([shop.lat, shop.lng], 15, { duration: 1000 });
    }

    this.setState({ selected: shop });
  }
}

export default connect<IStateProps, IActionProps>(mapState, mapActions)(FullShopsView);

import * as NS from '../../namespace';
import { IUser } from 'shared/types/models';
import { validateNotEmptyString, validatePhone, validateCount } from 'shared/helpers';

export function changeSignUpName(value: string): NS.IChangeSignUpName {
  return { type: 'AUTH:CHANGE_SIGNUP_NAME', payload: value };
}

export function changeSignUpEmail(value: string): NS.IChangeSignUpEmail {
  return { type: 'AUTH:CHANGE_SIGNUP_EMAIL', payload: value };
}

export function changeSignUpPassword(value: string): NS.IChangeSignUpPassword {
  return { type: 'AUTH:CHANGE_SIGNUP_PASSWORD', payload: value };
}

export function changeSignInName(value: string): NS.IChangeSignInName {
  return { type: 'AUTH:CHANGE_SIGNIN_NAME', payload: value };
}

export function changeSignInEmail(value: string): NS.IChangeSignInEmail {
  return { type: 'AUTH:CHANGE_SIGNIN_EMAIL', payload: value };
}

export function changeSignInPassword(value: string): NS.IChangeSignInPassword {
  return { type: 'AUTH:CHANGE_SIGNIN_PASSWORD', payload: value };
}

export function changeSignInRemember(value: boolean): NS.IChangeSignInRememberMe {
  return { type: 'AUTH:CHANGE_SIGNIN_REMEMBER_ME', payload: value };
}

export function editUser(user: IUser): NS.IEditUser {
  return { type: 'AUTH:EDIT_USER', payload: user };
}

export function changeUserFirstName(name: string): NS.IChangeFirstName {
  const error = validateNotEmptyString(name) || validateCount(name, 55);
  return { type: 'AUTH:EDIT_FIRST_NAME', payload: { error, value: name } };
}

export function changeUserLastName(name: string): NS.IChangeLastName {
  const error = validateNotEmptyString(name) || validateCount(name, 55);
  return { type: 'AUTH:EDIT_LAST_NAME', payload: { value: name, error } };
}

export function changeUserPhone(phone: string): NS.IChangePhone {
  const cleared = phone.replace(/\s/g, '').replace('+', '');
  const error = validateNotEmptyString(cleared) || validatePhone(cleared, '+7');
  return { type: 'AUTH:EDIT_PHONE', payload: { value: cleared, error } };
}

export function changeUserAddress(address: string): NS.IChangeAddress {
  return { type: 'AUTH:EDIT_ADDRESS', payload: { value: address, error: '' } };
}

export function changeRestoreEmail(email: string): NS.IChangeRestoreEmail {
  return { type: 'AUTH:EDIT_RESTORE_EMAIL', payload: { value: email, error: '' } };
}

export function changeRestorePasword(pass: string): NS.IChangeRestorePassword {
  return { type: 'AUTH:EDIT_RESTORE_PASSWORD', payload: { value: pass, error: '' } };
}

export function changeRestoreConfirmPassword(confirm: string): NS.IChangeRestoreConfirmPassword {
  return { type: 'AUTH:EDIT_RESTORE_CONFIRM_PASSWORD', payload: { value: confirm, error: '' } };
}

export function changeChangePasswordPassword(password: string): NS.IChangePasswordChangePass {
  return { type: 'AUTH:CHANGE_PASSWORD_CHANGE_PASS', payload: password };
}

export function changeChangePasswordConfirm(confirm: string): NS.IChangePasswordChangeConfirm {
  return { type: 'AUTH:CHANGE_PASSWORD_CHANGE_CONFIRM', payload: confirm };
}

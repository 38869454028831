import * as React from 'react';
import 'shared/view/styles/BaseStyles.scss';

class App extends React.Component {
  public render() {
    const { children } = this.props;

    return children;
  }
}

export default App;

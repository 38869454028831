import * as React from 'react';
import * as block from 'bem-cn';
import * as ReactPaginate from 'react-paginate';
import { bind } from 'decko';
import InlineSvg from 'svg-inline-react';
import * as ArrowIcon from './img/arrow.svg';
import Button from '../Button/Button';
import './Paginator.scss';

interface IProps {
  pages: number;
  page: number;
  size: number;
  sizes?: number[];
  onChange?: (page: number) => void;
  onSizeChange?: (size: number) => void;
}

const b = block('paginator');

class Paginator extends React.PureComponent<IProps, {}> {
  public render() {
    const { sizes = [30, 60, 90], page, pages, size } = this.props;
    return (
      <div className={b()}>
        <div className={b('wrapper')()}>
          <div className={b('prev-btn')()}>
            <Button disabled={page === 1} theme="green" onClick={this.onPrevClick}>
              <InlineSvg element="div" src={ArrowIcon} className={b('prev-btn-icon')()} />
            </Button>
          </div>

          <ReactPaginate
            pageCount={pages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            forcePage={page - 1}
            onPageChange={this.onPageChange}
            nextLabel=""
            previousLabel=""
            containerClassName={b('container')()}
            pageClassName={b('page')()}
            pageLinkClassName={b('page-link')()}
            breakClassName={b('page-link')()}
            nextClassName={b('next')()}
            previousClassName={b('prev')()}
            activeClassName={b('page', { active: true })()}
          />

          <div className={b('next-btn')()}>
            <Button disabled={page === pages} theme="green" onClick={this.onNextClick}>
              <InlineSvg element="div" src={ArrowIcon} className={b('next-btn-icon')()} />
            </Button>
          </div>
        </div>

        <div className={b('sizes')()}>
          <p className={b('sizes-label')()}>показывать по:</p>
          {sizes.map(s =>
            <div className={b('size', { active: s === size })()} key={s} onClick={this.onSizeSelect.bind(this, s)}>
              {s}
            </div>,
          )}
        </div>
      </div>
    );
  }

  @bind
  private onChange(page: number) {
    this.props.onChange && this.props.onChange(page);
  }

  @bind
  private onPageChange(data: { selected: number }) {
    this.onChange(data.selected + 1);
  }

  @bind
  private onNextClick() {
    this.onChange(this.props.page + 1);
  }

  @bind
  private onPrevClick() {
    this.onChange(this.props.page - 1);
  }

  @bind
  private onSizeSelect(size: number) {
    this.props.onSizeChange && this.props.onSizeChange(size);
  }
}

export default Paginator;

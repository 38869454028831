import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppReduxState } from 'shared/types/app';
import { Input } from 'shared/view/elements';
import { actions } from './../../../redux';

import './SearchInput.scss';

interface IOwnProps {
  onSubmit?: (query: string) => void;
  onChange?: (query: string) => void;
  type?: 'categories' | 'products';
}

interface IActionProps {
  searchProducts: typeof actions.searchProducts;
  changeQuery: typeof actions.changeQuery;
  searchCategories: typeof actions.searchCategories;
}

type IProps = IActionProps & IOwnProps;

function mapState(state: IAppReduxState) {
  return {};
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    searchProducts: actions.searchProducts,
    searchCategories: actions.searchCategories,
    changeQuery: actions.changeQuery,
  }, dispatch);
}

interface IState {
  query: string;
}

const b = block('block-name');

class SearchInput extends React.PureComponent<IProps, IState> {
  public state: IState = { query: '' };
  public render() {
    const { query } = this.state;
    return (
      <div className={b()}>
        <Input
          value={query}
          onKeyPress={this.onKeyPress}
          onChange={this.onQueryChange}
          icon="search"
          placeholder="Артикул или наименование"
        />
      </div>
    );
  }

  @bind
  private onKeyPress(e: React.KeyboardEvent<any>) {
    if (e.key === 'Enter') {
      this.submit();
    }
  }

  @bind
  private onQueryChange(value: string) {
    this.setState({ query: value });
    this.props.onChange && this.props.onChange(value);
  }

  @bind
  private submit() {
    const { query } = this.state;
    const { type, searchCategories, searchProducts, onSubmit, changeQuery } = this.props;

    changeQuery(query);

    if (!type || type === 'categories') {
      searchCategories({ query, page: 0, size: 30 });
    }

    if (!type || type === 'products') {
      searchProducts({ query, page: 0, size: 30, sort: 'name' });
    }

    onSubmit && onSubmit(query);
  }
}

export { SearchInput };
export default connect(mapState, mapDispatch)(SearchInput);

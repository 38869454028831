import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import {
  CategoriesList, CategoryDetails,
  selectors as viewCatsSelectors, ProductDetails,
} from 'features/viewCategories';
import { AddToBasket } from 'features/basket';
import * as auth from 'features/auth';

import { Title } from 'shared/view/elements';
import { ICategory, IProduct, IUser } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { findParents } from 'shared/model/product';

import { compileCategoriesRoute } from '../../../../shared/routes';
import './Catalog.scss';

interface IStateProps {
  categories: ICategory[];
  user: IUser | null;
}

type Props = RouteComponentProps<any> & IStateProps;

const b = block('catalog-layout');

function mapState(state: IAppReduxState): IStateProps {
  return {
    categories: viewCatsSelectors.selectCategories(state),
    user: auth.selectors.selectUser(state),
  };
}

// tslint:disable:jsx-no-lambda
class CatalogLayout extends React.PureComponent<Props> {
  public render() {
    const { match, user } = this.props;
    return (
      <div className={b()}>
        <Route
          exact
          path="/catalog"
          render={() => (
            <>
              <Title text="Каталог товаров" />
              <CategoriesList To={ToCategoryLink} view="grid" />
            </>)
          }
        />
        <Switch>
          <Route
            exact
            path={`${match.url}/product/:id`}
            render={props => <ProductDetails user={user} Extra={AddToBasket} id={props.match.params.id} />}
          />
          <Route
            exact
            path={`${match.url}/:id`}
            render={props => (
              <CategoryView
                {...props}
                user={user}
                onSelect={this.onNestedCategorySelect}
                onProductSelect={this.onProductSelect}
              />
            )}
          />
          <Route
            path={`${match.url}/:id`}
            render={props => (
              <CategoryVisitor
                {...props}
                user={user}
                onSelect={this.onNestedCategorySelect}
                onProductSelect={this.onProductSelect}
              />
            )}
          />
        </Switch>
      </div>
    );
  }

  @bind
  private onNestedCategorySelect(cat: ICategory) {
    const { categories } = this.props;
    const parents = findParents(categories, cat, a => a.id);
    const path = compileCategoriesRoute(parents, cat);
    this.props.history.push(path);
  }

  @bind
  private onProductSelect(product: IProduct) {
    this.props.history.push(`${this.props.location.pathname}/product/${product.id}`);
  }
}

function CategoryVisitor({ match, onSelect, onProductSelect, user }: RouteRendererProps): React.ReactElement<any> {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/product/:id`}
        render={props => <ProductDetails user={user} Extra={AddToBasket} id={props.match.params.id} />}
      />
      <Route
        exact
        path={`${match.url}/:id`}
        render={props => <CategoryView {...props} user={user} onSelect={onSelect} onProductSelect={onProductSelect} />}
      />
      <Route
        path={`${match.url}/:id`}
        render={props =>
          <CategoryVisitor {...props} user={user} onProductSelect={onProductSelect} onSelect={onSelect} />}
      />
    </Switch>
  );
}

type RouteRendererProps = RouteComponentProps<{ id: string }> &
  {
    onSelect?: (c: ICategory) => void,
    onProductSelect?: (p: IProduct) => void,
    user: IUser | null,
  };

function CategoryView({ match, onSelect, onProductSelect, user }: RouteRendererProps) {
  return (
    <CategoryDetails
      ExtraProductContent={AddToBasket}
      onCategorySelect={onSelect}
      onProductSelect={onProductSelect}
      user={user}
      id={match.params.id}
    />
  );
}

function ToCategoryLink({ parents, category }: { parents: ICategory[]; category: ICategory }) {
  const path = compileCategoriesRoute(parents, category);
  return (
    <Link className={b('category-link')()} to={path}>{category.title}</Link>
  );
}

export default connect<IStateProps, {}, {}>(mapState, () => ({}))(CatalogLayout);

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClipLoader } from 'react-spinners';

import { Modal } from 'shared/view/elements';
import { IUser } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { ICommunication } from 'shared/types/redux';

import { actions, selectors } from '../../../redux';
import Signin from '../Signin/Signin';
import Signup from '../Signup/Signup';
import Restore from '../Restore/Restore';
import './Auth.scss';

interface IState {
  view: 'signup' | 'signin' | 'restore';
}

interface IOwnProps {
  onSuccess?: () => void;
  onUserClick?: () => void;
}

interface IStateProps {
  user: IUser | null;
  checking: ICommunication;
  isOpened: boolean;
  token: string;
}

interface IActionProps {
  logout: typeof actions.logout;
  openModal: typeof actions.openModal;
  closeModal: typeof actions.closeModal;
}

type Props = IStateProps & IOwnProps & IActionProps;

const b = block('auth');

function mapState(state: IAppReduxState): IStateProps {
  return {
    user: selectors.selectUser(state),
    checking: selectors.selectCommunication(state, 'checking'),
    isOpened: selectors.selectIsOpenedModal(state),
    token: selectors.selectRestoreToken(state),
  };
}

function mapActions(dispatch: any) {
  return bindActionCreators({
    logout: actions.logout,
    openModal: actions.openModal,
    closeModal: actions.closeModal,
  }, dispatch);
}

class Auth extends React.PureComponent<Props, IState> {
  public state: IState = { view: 'signin' };

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.isOpened !== nextProps.isOpened && !nextProps.isOpened) {
      // wait for ending of modal animation and reset view then
      setTimeout(() => this.setState({ view: 'signin' }), 1000);
    }

    if (!this.props.token && nextProps.token) {
      this.setState({ view: 'restore' });
    }
  }

  public render() {
    const { user, logout, checking, onUserClick, isOpened } = this.props;
    const { openModal, closeModal, token } = this.props;
    const { view } = this.state;

    return (
      <>
        {checking.isRequesting ? (
          <div className={b('loader')()}>
            <ClipLoader size={30} color="#009846"  />
          </div>
        ) : null}
        {!user && !checking.isRequesting ? <div className={b('open')()} onClick={openModal}>Вход</div> : null}
        {user && !checking.isRequesting ? (
          <div className={b('user')()}>
            <p className={b('name')()} onClick={onUserClick}>{user.firstName + ' ' + user.lastName}</p>
            <button className={b('sign-out')()} onClick={logout}>Выйти</button>
          </div>
        ) : null}
        <Modal isOpen={isOpened} onRequestClose={closeModal} modalCls={b('modal')()}>
          {view === 'signin' ?
            <Signin
              onToSignup={this.to.bind(this, 'signup')}
              onForgot={this.to.bind(this, 'restore')}
              onSuccess={this.onSuccessSignin}
            /> : null}
          {view === 'signup' ?
            <Signup onToSignin={this.to.bind(this, 'signin')} /> : null}
          {view === 'restore' ?
            <Restore onSuccess={this.onSuccessSignin} token={token} onBack={this.to.bind(this, 'signin')} /> : null}
        </Modal>
      </>
    );
  }

  @bind
  private onSuccessSignin() {
    this.props.closeModal();
    this.props.onSuccess && this.props.onSuccess();
  }

  @bind
  private to(view: IState['view']) {
    this.setState({ view });
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(Auth);

import { append, update } from 'ramda';
import * as NS from '../../namespace';
import { initial } from '../initial';
import { IBasketItem, IBasket } from 'shared/types/models';
import { mkBasket } from 'shared/model/product';
import { sortByLetter } from 'shared/helpers/funtools';

export function editReducer(state: NS.IReduxState['edit'] = initial.edit, action: NS.Action): NS.IReduxState['edit'] {
  switch (action.type) {
    case 'BASKET:ADD': {
      const oldItems = state.basket.items;
      const index = oldItems.findIndex(i => i.id === action.payload.id);
      const isAlreadyAdded = index >= 0;
      const product = isAlreadyAdded ?
        { ...oldItems[index], basketCount: oldItems[index].basketCount + 1 } :
        { ...action.payload, order: oldItems.length + 1 };
      const items = isAlreadyAdded ? update<IBasketItem>(index, product, oldItems) : append(product, oldItems);
      const newBasket = mkBasket(items);
      return { ...state, basket: newBasket };
    }
    case 'BASKET:CLEAR': {
      return {
        ...state,
        basket: { ...initial.edit.basket },
      };
    }
    case 'BASKET:LOAD_SUCCESS': {
      return {
        ...state,
        basket: processBasket(action.payload),
      };
    }
    case 'BASKET:SAVE_SUCCESS': {
      return {...state, basket: processBasket(action.payload) };
    }
    case 'BASKET:DELETE_ITEM_SUCCESS': {
      return { ...state, basket: processBasket(action.payload) };
    }
    case 'AUTH:LOGOUT': {
      return { ...state, basket: { items: [], summaryPrice: 0 } };
    }
    case 'BASKET:CHANGE_ITEM_COUNT': {
      const basket = state.basket;
      const { count: newCount, item } = action.payload;
      const index = state.basket.items.findIndex(it => it.basketItemId === item.basketItemId);

      if (index < 0) { return state; }

      return {
        ...state,
        basket: {
          ...state.basket,
          items: update(index, { ...basket.items[index], basketCount: newCount }, basket.items),
        },
      };
    }
    default: return state;
  }
}

function processBasket(basket: IBasket): IBasket {
  return {
    ...basket,
    items: [...basket.items].sort(sortByLetter(i => i.name)),
  };
}

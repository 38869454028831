import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import './Radio.scss';

interface IProps<T extends string> extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  comment?: string;
  value?: T;
  subcomment?: string;
  onValChange?: (value: T) => void;
}

const b = block('radio-button');

class RadioButton<T extends string> extends React.PureComponent<IProps<T>> {
  public render() {
    const { label, comment, onValChange, subcomment, ...attrs } = this.props;
    return (
      <div className={b({ disabled: Boolean(attrs.disabled) })()}>
        <input type="radio" {...attrs} onChange={this.onChange} className={b('field')()} />
        <label htmlFor={attrs.id} className={b('label')()}>
          {label}
          <p className={b('comment')()}>
            {comment} <br />
            {subcomment}
          </p>
        </label>
      </div>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.disabled) { return; }

    const value = e.currentTarget.value;
    this.props.onValChange && this.props.onValChange(value as any);
  }
}

export default RadioButton;

import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { IDeliveryPoint, INewOrder, ICreatedOrder } from 'shared/types/models';
import { ICommunication } from 'shared/types/redux';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.orders;
}

export function selectOrderCreating(state: IAppReduxState): NS.IReduxState['edit']['order'] {
  return getFeatureState(state).edit.order;
}

export function selectDeliveryPoints(state: IAppReduxState): IDeliveryPoint[] {
  return getFeatureState(state).data.deliveryPoints;
}

export function selectCreatingOrder(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.creatingOrder;
}

export function selectNewOrder(state: IAppReduxState): INewOrder {
  const edit = selectOrderCreating(state);
  return {
    comment: edit.comment,
    deliveryPoint: edit.deliveryPoint,
    deliveryType: edit.delivery,
    paymentType: edit.payment,
    person: {
      fullName: edit.personFullName.value,
      phone: edit.personPhone.value,
    },
  };
}

export function selectCreatedOrder(state: IAppReduxState): ICreatedOrder | null {
  return getFeatureState(state).data.createdOrder;
}

export function selectIsHasFieldErrors(state: IAppReduxState): boolean {
  const edit = selectOrderCreating(state);
  const result = [edit.personFullName, edit.personPhone]
    .map(field => Boolean(field.error))
    .reduce((res, has) => res || has, false);

  return Boolean(result);
}

import { select, takeLatest, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IDependencies } from 'shared/types/app';
import { methodSaga, requestSaga } from 'shared/helpers/redux';
import { IUser } from 'shared/types/models';
import getErrorMsg from 'shared/helpers/getErrorMsg';

import * as NS from '../../namespace';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { IReduxField } from 'shared/types/redux';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const signupType: NS.ISignUp['type'] = 'AUTH:SIGN_UP';
    const signinType: NS.ISignIn['type'] = 'AUTH:SIGN_IN';
    const logoutType: NS.ILogout['type'] = 'AUTH:LOGOUT';
    const checkUserType: NS.ICheckUser['type'] = 'AUTH:CHECK_USER';
    const updateUserType: NS.IUpdateUser['type'] = 'AUTH:UPDATE_USER';
    const restorePasswordType: NS.IStartRestore['type'] = 'AUTH:START_RESTORE';
    const finishRestorePasswordtype: NS.IFinishRestore['type'] = 'AUTH:FINISH_RESTORE';
    const changePasswordType: NS.IChangePassword['type'] = 'AUTH:CHANGE_PASSWORD';

    yield [
      takeLatest(signupType, signupSaga, deps),
      takeLatest(signinType, signInSaga, deps),
      takeLatest(checkUserType, checkUserSaga, deps),
      takeLatest(logoutType, logoutSaga, deps),
      takeLatest(updateUserType, updateUserSaga, deps),
      takeLatest(restorePasswordType, restorePasswordSaga, deps),
      takeLatest(finishRestorePasswordtype, finishRestoreSaga, deps),
      takeLatest(changePasswordType, changePasswordSaga, deps),
    ];
  };
}

function* signupSaga(deps: IDependencies) {
  const data: NS.IReduxState['edit']['signUp'] = yield select(selectors.selectSignUpEdit);
  yield* methodSaga(
    () => deps.api.register(data.name, data.email, data.password),
    actions.signUpSuccess,
    actions.signUpFail,
  );
}

function* signInSaga(deps: IDependencies) {
  const data: NS.IReduxState['edit']['signIn'] = yield select(selectors.selectSignInEdit);

  try {
    const user: IUser = yield call(deps.api.login, data.email, data.password);
    yield put(actions.signInSuccess(user));
  } catch (e) {
    const error = getErrorMsg(e);
    yield put(actions.signInFail(error));
  }
}

function* checkUserSaga(deps: IDependencies) {
  try {
    const user: IUser = yield call(deps.api.checkUser);
    yield put(actions.checkUserSuccess(user));
  } catch (e) {
    const error = getErrorMsg(e);
    yield put(actions.checkUserFail(error));
  }
}

function* updateUserSaga(deps: IDependencies) {
  try {
    const isHasErrors = yield select(selectors.selectIsEditUserHasErrors);
    const user: IUser = yield select(selectors.selectEditedUser);

    if (isHasErrors) {
      yield put(actions.updateUserFail(''));
      return;
    }

    const newUser: IUser = yield call(deps.api.updateUserData, user);
    yield put(actions.updateUserSuccess(newUser));
  } catch (e) {
    const error = getErrorMsg(e);
    yield put(actions.updateUserFail(error));
  }
}

function* restorePasswordSaga(deps: IDependencies) {
  const restoreEmail: IReduxField<string> = yield select(selectors.selectRestoreEmail);
  yield* methodSaga(
    () => deps.api.restorePassword(restoreEmail.value),
    actions.startRestoreSuccess,
    actions.startRestoreFail,
  );
}

function* finishRestoreSaga(deps: IDependencies) {
  const token: string = yield select(selectors.selectRestoreToken);
  const pass: IReduxField<string> = yield select(selectors.selectRestorePassword);
  const confirm: IReduxField<string> = yield select(selectors.selectRestoreConfirm);

  yield* requestSaga(
    () => deps.api.finishRestorePassword(token, pass.value, confirm.value),
    actions.finishRestoreSuccess,
    actions.finishRestoreFail,
  );
}

function* changePasswordSaga(deps: IDependencies) {
  const data: { password: string; confirm: string; } = yield select(selectors.selectChangePasswordEdit);

  yield methodSaga(
    () => deps.api.changePassword(data.password, data.confirm),
    actions.changePasswordSuccess,
    actions.changePasswordFail,
  );
}

function* logoutSaga(deps: IDependencies) {
  yield call(deps.api.logout);
}

export { getSaga };

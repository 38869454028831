import * as React from 'react';
import * as block from 'bem-cn';
import './Title.scss';

interface IProps {
  text: string;
  fontSize?: number;
  theme?: 'main' | 'regular';
}

class Title extends React.PureComponent<IProps, {}> {
  public render() {
    const b = block('title');
    const { fontSize, theme = 'main' } = this.props;
    return (
      <h3 className={b({ theme })()} style={{ fontSize: fontSize && `${fontSize}rem` }}>
        {this.props.text}
      </h3>
    );
  }
}

export default Title;

import { takeLatest } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { IDependencies } from 'shared/types/app';
import { requestSaga } from 'shared/helpers/redux';

import * as NS from '../../namespace';
import * as actions from '../actions';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadPagesType: NS.ILoadPages['type'] = 'FLAT_PAGES:LOAD_PAGES';

    yield [
      takeLatest(loadPagesType, loadFlatpagesSaga, deps),
    ];
  };
}

function* loadFlatpagesSaga({ api }: IDependencies) {
  yield* requestSaga(() => api.loadFlatPages(), actions.loadPagesSuccess, actions.loadPagesFail);
}

export { getSaga };

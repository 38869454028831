import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { INews } from 'shared/types/models';
import { ICommunication } from 'shared/types/redux';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.viewNews;
}

export function selectNews(state: IAppReduxState): INews[] {
  return getFeatureState(state).data.news;
}

export function selectDetailedNews(state: IAppReduxState): INews | null {
  return getFeatureState(state).data.detailedNews;
}

export function selectLoading(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.detailsNewsLoading;
}

import * as React from 'react';
import { bind } from 'decko';
import { BulletList } from 'react-content-loader';

interface IProps {
  type: 'bullet';
}

class Preloader extends React.PureComponent<IProps, {}> {
  private wrapper: HTMLDivElement | null = null;
  public render() {
    const { type } = this.props;
    const map: Record<IProps['type'], any> = {
      bullet: () => <BulletList width={this.wrapper ? this.wrapper.clientWidth : 100} />,
    };
    return (
      <div ref={this.onWrapperRef}>
        {map[type]()}
      </div>
    );
  }

  @bind
  private onWrapperRef(ref: HTMLDivElement | null) {
    this.wrapper = ref;

    if (ref) { this.forceUpdate(); }
  }
}

export default Preloader;

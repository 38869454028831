import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import { initial } from '../initial';

const communicationReducer = combineReducers<NS.IReduxState['communication']>({
  signingUp: makeCommunicationReducer<NS.ISignUp, NS.ISignUpSuccess, NS.ISignUpFail>(
    'AUTH:SIGN_UP', 'AUTH:SIGN_UP_SUCCESS', 'AUTH:SIGN_UP_FAIL', initial.communication.signingUp,
  ),
  signingIn: makeCommunicationReducer<NS.ISignIn, NS.ISignInSuccess, NS.ISignInFail>(
    'AUTH:SIGN_IN', 'AUTH:SIGN_IN_SUCCESS', 'AUTH:SIGN_IN_FAIL', initial.communication.signingIn,
  ),
  checking: makeCommunicationReducer<NS.ICheckUser, NS.ICheckUserSuccess, NS.ICheckUserFail>(
    'AUTH:CHECK_USER', 'AUTH:CHECK_USER_SUCCESS', 'AUTH:CHECK_USER_FAIL', initial.communication.checking,
  ),
  updating: makeCommunicationReducer<NS.IUpdateUser, NS.IUpdateUserSuccess, NS.IUpdateUserFail>(
    'AUTH:UPDATE_USER', 'AUTH:UPDATE_USER_SUCCESS', 'AUTH:UPDATE_USER_FAIL', initial.communication.updating,
  ),
  restoreStarting: makeCommunicationReducer<NS.IStartRestore, NS.IStartRestoreSuccess, NS.IStartRestoreFail>(
    'AUTH:START_RESTORE', 'AUTH:START_RESTORE_SUCCESS', 'AUTH:START_RESTORE_FAIL',
    initial.communication.restoreStarting,
  ),
  restoreFinishing: makeCommunicationReducer<NS.IFinishRestore, NS.IFinishRestoreSuccess, NS.IFinishRestoreFail>(
    'AUTH:FINISH_RESTORE', 'AUTH:FINISH_RESTORE_SUCCESS', 'AUTH:FINISH_RESTORE_FAIL',
    initial.communication.restoreFinishing,
  ),
  passwordChanging: makeCommunicationReducer<NS.IChangePassword, NS.IChangePasswordSuccess, NS.IChangePasswordFail>(
    'AUTH:CHANGE_PASSWORD', 'AUTH:CHANGE_PASSWORD_SUCCESS', 'AUTH:CHANGE_PASSWORD_FAIL',
    initial.communication.passwordChanging,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

export default communicationReducer;

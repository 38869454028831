import * as React from 'react';
import { Route } from 'react-router-dom';
import { ROUTES_PREFIX } from 'core/constants';

import { Module, IReduxEntry } from 'shared/types/app';
import { reducer, saga } from './redux';
import { Layout } from './view/containers';

class HomeModule extends Module {
  public getRoutes() {
    return <Route exact key="main" path={`${ROUTES_PREFIX}/*`} component={Layout} />;
  }

  public getReduxEntry(): IReduxEntry {
    return {
      reducers: {
        home: reducer,
      },
      sagas: [saga],
    };
  }
}

export default HomeModule;

import * as React from 'react';
import * as block from 'bem-cn';
import * as ReactModal from 'react-modal';
import './Modal.scss';

interface IProps extends ReactModal.Props {
  fullWidth?: boolean;
  align?: 'flex-start' | 'center';
  modalCls?: string;
}

const styles: ReactModal.Styles = {
  overlay: {
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    overflow: 'hidden',
    zIndex: 1000,
  },
  content: {
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
  },
};

ReactModal.setAppElement('#root');

class Modal extends React.PureComponent<IProps> {
  private b = block('modal');

  public render() {
    const b = this.b;
    const { children, fullWidth, modalCls = '', style, align = 'center', ...modalProps } = this.props;

    return (
      <ReactModal
        {...modalProps}
        style={styles}
        className={b.mix(modalCls)()}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick
      >
        {children}
      </ReactModal>
    );
  }
}

export default Modal;

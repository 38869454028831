import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  IBasket, IUser, TDeliveryType, TPaymentType,
  IDeliveryPoint, INewOrder, ICreatedOrder,
} from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { Radio, Button, PhoneInput, Input } from 'shared/view/elements';
import { IReduxField, ICommunication } from 'shared/types/redux';
import { isSuccessedByState } from 'shared/helpers/redux';
import { selectors, actions } from '../../../redux';
import './CreateOrder.scss';

interface IOwnProps {
  basket: IBasket;
  user: IUser;
  onCreated?: (order: ICreatedOrder) => void;
}

interface IStateProps {
  submited: boolean;
  delivery: TDeliveryType;
  payment: TPaymentType;
  comment: string;
  personFullName: IReduxField<string>;
  personPhone: IReduxField<string>;
  deliveryPoints: IDeliveryPoint[];
  deliveryPoint: IDeliveryPoint | null;
  creating: ICommunication;
  order: INewOrder;
  createdOrder: ICreatedOrder | null;
}

interface IActionProps {
  startOrdering: typeof actions.startOrdering;
  changePayment: typeof actions.changePaymentType;
  changeDelivery: typeof actions.changeDeliveryType;
  changeComment: typeof actions.changeComment;
  changePhone: typeof actions.changePhone;
  changeFullName: typeof actions.changeFullName;
  loadDeliveryPoins: typeof actions.loadDeliveryPoints;
  changeDeliveryPoint: typeof actions.changeDeliveryPoint;
  createOrder: typeof actions.createOrder;
}

type IProps = IStateProps & IActionProps & IOwnProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    ...selectors.selectOrderCreating(state),
    deliveryPoints: selectors.selectDeliveryPoints(state),
    creating: selectors.selectCreatingOrder(state),
    order: selectors.selectNewOrder(state),
    createdOrder: selectors.selectCreatedOrder(state),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    startOrdering: actions.startOrdering,
    changePayment: actions.changePaymentType,
    changeDelivery: actions.changeDeliveryType,
    changeComment: actions.changeComment,
    changePhone: actions.changePhone,
    changeFullName: actions.changeFullName,
    loadDeliveryPoins: actions.loadDeliveryPoints,
    changeDeliveryPoint: actions.changeDeliveryPoint,
    createOrder: actions.createOrder,
  }, dispatch);
}

const b = block('create-order');

class CreateOrder extends React.PureComponent<IProps> {
  public componentDidMount() {
    this.props.startOrdering(this.props.user);
    this.props.loadDeliveryPoins();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (isSuccessedByState(this.props.creating, nextProps.creating)) {
      if (this.props.onCreated && nextProps.createdOrder) {
        this.props.onCreated(nextProps.createdOrder);
      }
    }
  }

  public render() {
    const { comment, delivery, payment, personPhone, personFullName, basket } = this.props;
    const { changeFullName, changeDelivery, changePayment, changePhone } = this.props;
    const { deliveryPoints, deliveryPoint, creating, submited } = this.props;

    return (
      <div className={b()}>
        <div className={b('personal-data')()}>
          <Section title="Персональные данные">
            <div className={b('field')()}>
              <label className={b('field-label')()} htmlFor="fullname">Ф.И.О:</label>
              <Input onChange={changeFullName} value={personFullName.value} name="fullname" />
              {submited && <p className={b('error')()}>{personFullName.error}</p>}
            </div>
            <div className={b('field')()}>
              <label className={b('field-label')()} htmlFor="fullname">Телефон:</label>
              <PhoneInput onChange={changePhone} phone={personPhone.value} />
              {submited && <p className={b('error')()}>{personPhone.error}</p>}
            </div>
          </Section>
        </div>
        <div className={b('first-row')()}>
          <Section title="Выбрать способ получения" topmargin leftmargin>
            <div className={b('radio-field')()}>
              <Radio<TDeliveryType>
                label="Самовывоз"
                name="delivery"
                id="own"
                value="own"
                onValChange={changeDelivery}
                checked={delivery === 'own'}
              />
            </div>
            <div className={b('radio-field')()}>
              <Radio<TDeliveryType>
                label="Доставка"
                name="delivery"
                id="deliv"
                value="deliver"
                comment="Условия уточняйте у менеджера"
                onValChange={changeDelivery}
                disabled={basket.summaryPrice < 1000}
                checked={delivery === 'deliver'}
              />
            </div>
          </Section>
          <Section title="Выбрать способ оплаты" topmargin leftmargin>
            <div className={b('radio-field')()}>
              <Radio<TPaymentType>
                label="Наличный расчет"
                comment="Наличный расчет при получении товара"
                name="payment"
                id="money"
                value="money"
                onValChange={changePayment}
                checked={payment === 'money'}
              />
            </div>
            <div className={b('radio-field')()}>
              <Radio<TPaymentType>
                label="Оплата картой"
                comment="Оплата пластиковой картой при получении товара"
                name="payment"
                id="card"
                value="card"
                onValChange={changePayment}
                checked={payment === 'card'}
              />
            </div>
            <div className={b('radio-field')()}>
              <Radio<TPaymentType>
                label="Онлайн оплата"
                comment="Оплата картой или электронными деньгами"
                name="payment"
                id="transaction"
                value="trans"
                onValChange={changePayment}
                checked={payment === 'trans'}
              />
            </div>
          </Section>
        </div>

        {delivery === 'own' ? (
          <Section topmargin title="Пункты самовывоза">
            <div className={b('delivery-points')()}>
              {deliveryPoints.map(point => (
                <div key={point.id} className={b('delivery-point')()}>
                  <Radio<string>
                    label={point.name}
                    comment={point.address}
                    subcomment={point.workTime ? `Время работы: ${point.workTime}` : void 0}
                    name="delivery-point"
                    id={`deliv-point-${point.id}`}
                    checked={Boolean(deliveryPoint && deliveryPoint.id === point.id)}
                    value={point.id}
                    onValChange={this.onDeliveryPointChange}
                  />
                </div>
              ))}
            </div>
          </Section>
        ) : null}

        <Section title="Комментарий к заказу" topmargin>
          <textarea
            onChange={this.onCommentChange}
            value={comment}
            className={b('comment')()}
            placeholder="Ваш комментарий..."
          />
        </Section>

        <div className={b('submit')()}>
          <Button inline theme="green" onClick={this.onSubmitOrder}>
            <span>Подтвердить заказ</span>
          </Button>
        </div>
        {creating.error && <p className={b('error', { type: 'summary' })()}>{creating.error}</p>}
      </div>

    );
  }

  @bind
  private onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.props.changeComment(e.currentTarget.value);
  }

  @bind
  private onDeliveryPointChange(id: string) {
    const point = this.props.deliveryPoints.find(p => p.id === id);

    if (point) {
      this.props.changeDeliveryPoint(point);
    }
  }

  @bind
  private onSubmitOrder() {
    const { user, basket, order } = this.props;
    this.props.createOrder({ user, basket, order });
  }
}

function Section(
  { title, children, leftmargin = false, topmargin = false }:
  { title: string; children?: JSX.Element | JSX.Element[], leftmargin?: boolean, topmargin?: boolean },
) {
  return (
    <section className={b('section', { leftmargin, topmargin })()}>
      <header className={b('section-header')()}>
        <h3 className={b('section-title')()}>{title}</h3>
      </header>
      <div className={b('section-content')()}>{children}</div>
    </section>
  );
}

export { CreateOrder };
export default connect(mapState, mapDispatch)(CreateOrder);

import * as NS from './../../namespace';

export function openModal(): NS.IOpenModal {
  return { type: 'AUTH:OPEN_MODAL' };
}

export function closeModal(): NS.ICloseModal {
  return { type: 'AUTH:CLOSE_MODAL' };
}

export function startFinishingOfRestoring(token: string): NS.IStartFinishingOfRestoring {
  return { type: 'AUTH:START_FINISHING_RESTORING', payload: token };
}

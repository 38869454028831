import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import InlineSvg from 'svg-inline-react';
import * as ArrowIcon from './img/arrow.svg';
import './CounterInput.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: number;
  min?: number;
  max?: number;
  onValChange?: (val: number) => void;
}

interface IState {
  value: number;
}

const b = block('counter-input');

class CounterInput extends React.PureComponent<IProps, IState> {
  public state: IState = { value: this.props.value };

  public componentWillReceiveProps(nextProps: IProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  public render() {
    const { onValChange: _, ...attrs } = this.props;
    const { value } = this.state;
    return (
      <div className={b()}>
        <input
          className={b('field')()}
          {...attrs}
          onChange={this.onChange}
          onBlur={this.onBlur}
          value={String(value)}
        />
        <div className={b('controls')()}>
          <InlineSvg src={ArrowIcon} element="button" className={b('control', { type: 'up' })()} onClick={this.inc} />
          <InlineSvg src={ArrowIcon} element="button" className={b('control', { type: 'down' })()} onClick={this.dec} />
        </div>
      </div>
    );
  }

  private callOnChange(nextVal: number) {
    const { min, max, onValChange, disabled } = this.props;
    const isMinSatisfied = (min !== void 0 && nextVal >= min) || (min === void 0);
    const isMaxSatisfied = (max !== void 0 && nextVal <= max) || (max === void 0);

    if (isMinSatisfied && isMaxSatisfied && onValChange && !disabled) {
      onValChange(nextVal);
    }
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const isDigit = (c: string) => !isNaN(+c);
    const isAllDigits = value.split('').every(isDigit);

    if (isAllDigits) {
      this.setState({ value: +value });
    }
  }

  @bind
  private onBlur() {
    const { value } = this.state;
    const { min, max } = this.props;
    let next = value;

    const isMinSatisfied = (min !== void 0 && value >= min) || (min === void 0);
    const isMaxSatisfied = (max !== void 0 && value <= max) || (max === void 0);

    if (!isMinSatisfied && min !== void 0) {
      next = min;
    } else if (!isMaxSatisfied && max !== void 0) {
      next = max;
    }

    this.setState({ value: next }, () => this.callOnChange(this.state.value));
  }

  @bind
  private dec() {
    this.callOnChange(this.props.value - 1);
  }

  @bind
  private inc() {
    this.callOnChange(this.props.value + 1);
  }
}

export default CounterInput;

import { IAppReduxState } from 'shared/types/app';
import { IShop } from 'shared/types/models';
import { sortByLetter } from 'shared/helpers/funtools';
import * as NS from '../namespace';

export function getState(state: IAppReduxState): NS.IReduxState {
  return state.shops;
}

export function selectShops(state: IAppReduxState): IShop[] {
  return getState(state).data.shops.sort(sortByLetter(o => o.city));
}

export function selectLoading(state: IAppReduxState) {
  return getState(state).communication.loading;
}

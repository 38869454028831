import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { Link } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { BasketItems, selectors as basketSelectors, actions as basketActions } from 'features/basket';
import { CreateOrder } from 'features/orders';
import * as viewCats from 'features/viewCategories';
import * as auth from 'features/auth';

import { Button } from 'shared/view/elements';
import { IAppReduxState } from 'shared/types/app';
import { findParents } from 'shared/model/product';
import { IBasket, IUser, ICreatedOrder, IBasketItem, ICategory } from 'shared/types/models';
import { compileProductRoute } from '../routes';
import './OrderBasket.scss';

interface IState {
  step: 'basket' | 'ordering' | 'ordered';
  order: ICreatedOrder | null;
}

interface IStateProps {
  basket: IBasket;
  user: IUser | null;
  waitingBasket: boolean;
  categories: ICategory[];
}

interface IActionProps {
  openAuth: typeof auth.actions.openModal;
  clearBasket: typeof basketActions.clearBasket;
}

type Props = IStateProps & IActionProps;

const b = block('order-basket');

function mapState(state: IAppReduxState): IStateProps {
  return {
    basket: basketSelectors.selectItems(state),
    user: auth.selectors.selectUser(state),
    categories: viewCats.selectors.selectCategories(state),
    waitingBasket: basketSelectors.selectLoading(state),
  };
}

function mapActions(dispatch: Dispatch<any>): IActionProps {
  return bindActionCreators({
    openAuth: auth.actions.openModal,
    clearBasket: basketActions.clearBasket,
  }, dispatch);
}

class OrderBasket extends React.PureComponent<Props, IState> {
  public state: IState = { step: 'basket', order: null };

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.user && !nextProps.user) {
      this.setState({ step: 'basket' });
    }
  }

  public render() {
    const { basket, user, waitingBasket } = this.props;
    const { step, order } = this.state;
    return (
      <div className={b()}>

        {
          step === 'basket' ? (
            <>
              <div className={b('basket')()}>
                <BasketItems user={user} onGetItemLink={this.getBasketItemLink} />
              </div>
              <div className={b('submit')()}>
                <Button inline disabled={!basket.items.length || waitingBasket} theme="green" onClick={this.onSubmit}>
                  <span>Оформить заказ</span>
                </Button>
              </div>
            </>
          ) : null
        }

        {
          step === 'ordering' && user ? (
            <>
              <div className={b('basket')()}>
                <BasketItems user={user} onGetItemLink={this.getBasketItemLink} />
              </div>
              <div className={b('create-order')()}>
                <CreateOrder basket={basket} user={user} onCreated={this.onOrderCreated} />
              </div>
            </>
          ) : null
        }

        {
          step === 'ordered' && order ? (
            <>
              <p className={b('order')()}>
                Заказ #{order.id} на имя {order.fullName} успешно оформлен и принят в обработку.
              </p>
              <Link to="/catalog" className={b('link')()}>Вернуться к каталогу</Link>
            </>
          ) : null
        }
      </div>
    );
  }

  @bind
  private getBasketItemLink(item: IBasketItem) {
    const parents = findParents(this.props.categories, item, a => a.categoryId || '');
    const path = compileProductRoute(parents, item);
    return path;
  }

  @bind
  private onSubmit() {
    if (!this.props.user) {
      this.props.openAuth();
    } else {
      this.setState({ step: 'ordering' });
    }
  }

  @bind
  private onOrderCreated(order: ICreatedOrder) {
    this.setState({ step: 'ordered', order }, () => window.scrollTo(0, 0));
    this.props.clearBasket(this.props.user);
  }
}

export default connect<IStateProps, IActionProps>(mapState, mapActions)(OrderBasket);

import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: loadNewsSuccess,
  execute: loadNews,
  failed: loadNewsFail,
} = makeCommunicationActionCreators<
  NS.ILoadNews, NS.ILoadNewsSuccess, NS.ILoadNewsFail
>(
  'VIEW_NEWS:LOAD_NEWS', 'VIEW_NEWS:LOAD_NEWS_SUCCESS', 'VIEW_NEWS:LOAD_NEWS_FAIL',
);

export const {
  completed: loadNewsDetailsSuccess,
  execute: loadNewsDetails,
  failed: loadNewsDetailsFail,
} = makeCommunicationActionCreators<
  NS.ILoadNewsDetails, NS.ILoadNewsDetailsSuccess, NS.ILoadNewsDetailsFail
>(
  'VIEW_NEWS:LOAD_NEWS_DETAILS', 'VIEW_NEWS:LOAD_NEWS_DETAILS_SUCCESS', 'VIEW_NEWS:LOAD_NEWS_DETAILS_FAIL',
);

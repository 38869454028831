import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';

const b = block('collapsible');

interface IState {
  opened: boolean;
}

class Collapsible extends React.PureComponent<{}, IState> {
  public static Panel = Panel;
  public static Content = Content;
  public state: IState = { opened: false };

  public render() {
    const children = React.Children.toArray(this.props.children);
    const { opened } = this.state;
    const panel = children[0];
    const content = children[1]; // TODO: check by types
    return (
      <div className={b({ opened })()}>
        <div className={b('panel')()} onClick={this.onToggle}>{panel}</div>
        {this.state.opened ? <div className={b('content')()}>{content}</div> : null}
      </div>
    );
  }

  @bind
  private onToggle() {
    this.setState({ opened: !this.state.opened });
  }
}

function Panel({ children }: { children?: React.ReactNode; }): any {
  return children;
}

function Content({ children }: { children?: React.ReactNode }): any {
  return children;
}

export default Collapsible;

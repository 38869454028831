import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { IBasket } from 'shared/types/models';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.basket;
}

export function selectItems(state: IAppReduxState): IBasket {
  return getFeatureState(state).edit.basket;
}

export function selectCommunication(state: IAppReduxState, key: keyof NS.IReduxState['communication']) {
  return getFeatureState(state).communication[key];
}

export function selectLoading(state: IAppReduxState): boolean {
  const st = getFeatureState(state);
  return Object
    .keys(st.communication)
    .reduce((res, comm: keyof NS.IReduxState['communication']) =>
      res || st.communication[comm].isRequesting, false);
}

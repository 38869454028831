import { takeLatest } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { IDependencies } from 'shared/types/app';

import * as NS from '../../namespace';
import * as actions from '../actions';
import { requestSaga } from 'shared/helpers/redux';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadNewsType: NS.ILoadNews['type'] = 'VIEW_NEWS:LOAD_NEWS';
    const loadNewsDetailsType: NS.ILoadNewsDetails['type'] = 'VIEW_NEWS:LOAD_NEWS_DETAILS';

    yield [
      takeLatest(loadNewsType, loadNewsSaga, deps),
      takeLatest(loadNewsDetailsType, loadNewsDetailsSaga, deps),
    ];
  };
}

function* loadNewsSaga({ api }: IDependencies, { payload: { limit }}: NS.ILoadNews) {
  yield* requestSaga(
    () => api.loadNews({ limit }),
    actions.loadNewsSuccess,
    actions.loadNewsFail,
  );
}

function* loadNewsDetailsSaga({ api }: IDependencies, { payload: { id }}: NS.ILoadNewsDetails) {
  yield* requestSaga(
    () => api.loadNewsDetails(id),
    actions.loadNewsDetailsSuccess,
    actions.loadNewsDetailsFail,
  );
}

export { getSaga };

export interface ICategory {
  id: string;
  title: string;
  categories: ICategory[];
  image: string;
  products: IPaginated<IProduct> & { sorting: TProductSorting; appliedFilters: TAppliedProductFilter[]; };
  filters: TProductFilter[];
}

export type TProductFilter = IChoiceProductFilter | IRangeProductFilter;

export interface IChoiceProductFilter {
  id: string;
  type: 'choice';
  name: string;
  choices: IProductFilterChoice[];
}

export interface IProductFilterChoice {
  id: string;
  choice: string;
}

export type TAppliedProductFilter = IProductFilterChoice | IRangeProductFilter;

export interface IRangeProductFilter {
  id: string;
  type: 'range';
  name: string;
  min: number;
  max: number;
}

export function isProductFilterChoice(choice: any): choice is IProductFilterChoice {
  return choice && choice.hasOwnProperty('choice') && choice.hasOwnProperty('id');
}

export function isRangeProductFilter(filter: any): filter is IRangeProductFilter {
  return filter && filter.hasOwnProperty('min') && filter.hasOwnProperty('max') && filter.hasOwnProperty('id');
}

export function isChoicesProductFilter(filter: any): filter is IChoiceProductFilter {
  return filter && filter.hasOwnProperty('choices') && filter.hasOwnProperty('id');
}

export type TProductSorting = '-name' | 'name' | '-price' | 'price' | '-quantity' | 'quantity';
export type TPaymentType = 'trans' | 'card' | 'money';
export type TDeliveryType = 'own' | 'deliver';

export interface INews {
  id: string;
  title: string;
  image: string;
  date: number;
  shortDescription: string;
  content: string;
}

export interface IProduct {
  id: string;
  order?: number;
  vendorCode: string;
  image?: string;
  images: string[];
  name: string;
  price: number;
  count: number;
  description: string;
  inBoxCount: number;
  inPackCount: number;
  boxVolume: number;
  country: string;
  categoryId: string | null;
  properties: Array<{ id: string; name: string; value: string; }>;
}

export interface ICreatedOrder {
  id: string;
  fullName: string;
  phone: string;
  deliveryPoint: IDeliveryPoint | null;
  summary: number;
  status: 'new' | 'processed' | 'collect' | 'ready' | 'delivery' | 'completed';
  deliveryType: TDeliveryType;
  items: IBasketItem[];
  createdAt: number;
}

export interface IPaginated<T> {
  page: number;
  total: number;
  size: number;
  items: T[];
}

export interface IUser {
  address: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface IBasketItem extends IProduct {
  basketItemId: string;
  basketCount: number;
}

export interface IBasket {
  items: IBasketItem[];
  summaryPrice: number;
}

export interface IShop {
  id: string;
  name: string;
  city: string;
  phone: string;
  fax: string;
  mail: string;
  address: string;
  workingTime: string;
  lat: number;
  lng: number;
  descr: string;
}

export interface IFlatPage {
  title: string;
  slug: string;
  content: string;
  isInMenu: boolean;
}

export interface IDeliveryPoint {
  id: string;
  name: string;
  address: string;
  workTime: string;
}

export interface INewOrder {
  deliveryPoint: IDeliveryPoint | null;
  deliveryType: TDeliveryType;
  paymentType: TPaymentType;
  comment: string;
  person: {
    fullName: string;
    phone: string;
  };
}

export function isProduct(value: any): value is IProduct {
  const props: Array<keyof IProduct> = [
    'count', 'count', 'description', 'id',
    'name', 'price', 'properties', 'vendorCode',
  ];
  return value && props.reduce((res, cur) => res && value.hasOwnProperty(cur), false);
}

export function isCategory(value: any): value is ICategory {
  const props: Array<keyof ICategory> = [
    'id', 'title',
  ];
  return value && props.reduce((res, cur) => res && value.hasOwnProperty(cur), false);
}

export interface ISlide {
  id: string;
  title: string;
  description: string;
  image: string;
  priority: number;
}

export interface IBanner {
  id: string;
  image: string;
  position: 'left';
  url: string;
}

import { takeLatest } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { IDependencies } from 'shared/types/app';
import * as NS from '../../namespace';
import * as actions from '../actions';
import { requestSaga } from 'shared/helpers/redux';

const loadProductsType: NS.ISearchProducts['type'] = 'SEARCH:EXECUTE_PRODUCTS';
const loadCategoriesType: NS.ISearchCategories['type'] = 'SEARCH:EXECUTE_CATEGORIES';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    yield [
      takeLatest(loadProductsType, searchProductsSaga, deps),
      takeLatest(loadCategoriesType, searchCategoriesSaga, deps),
    ];
  };
}

function* searchProductsSaga(
  { api }: IDependencies, { payload: { query, page, size, sort }}: NS.ISearchProducts,
) {
  yield* requestSaga(
    () => api.searchProducts(query, sort, size, page * size),
    actions.searchProductsSuccess,
    actions.searchProductsFail,
  );
}

function* searchCategoriesSaga({ api }: IDependencies, { payload: { query, size, page }}: NS.ISearchCategories) {
  yield* requestSaga(
    () => api.searchCategories(query, size, page * size),
    actions.searchCategoriesSuccess,
    actions.searchCategoriesFail,
  );
}

export { getSaga };

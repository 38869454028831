import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as React from 'react';
import * as block from 'bem-cn';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import InlineSvg from 'svg-inline-react';
import Carousel from 'react-slick';
import { bind } from 'decko';

import BaseLayout from 'modules/shared/BaseLayout/BaseLayout';
import { NewsList } from 'features/viewNews';
import { FlatPage } from 'features/flatPages';

import { Title } from 'shared/view/elements';
import { INews, ISlide } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';

import Catalog from '../Catalog/Catalog';
import Basket from '../Basket/Basket';
import Shops from '../Shops/Shops';
import PersonalOffice from '../PersonalOffice/PersonalOffice';
import News from '../News/News';
import Search from '../Search/Search';
import { actions } from '../../../redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as ArrowIcon from './img/arrow.svg';
import './Layout.scss';

type Props = RouteComponentProps<void> & typeof mappedActions & { banners: ISlide[] };

const mappedActions = {
  laodBanners: actions.loadBanners,
};

function mapState(state: IAppReduxState): { banners: ISlide[] } {
  return {
    banners: state.home.data.slides,
  };
}

function mapActions(dispatch: Dispatch<any>): typeof mappedActions {
  return bindActionCreators(mappedActions, dispatch);
}

const b = block('main-layout');

// tslint:disable:jsx-no-lambda
class HomeLayout extends React.PureComponent<Props> {
  public componentDidMount() {
    if (!this.props.banners.length) {
      this.props.laodBanners();
    }
  }

  public render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: b('slider-dots')(),
      autoplay: true,
    };
    const Fallback = () => <Redirect to="/"/>;

    return (
      <BaseLayout {...this.props}>
        <Switch>
          <Route exact path="/">
            <>
              <section className={b('slider')()}>
                <Carousel
                  nextArrow={<Arrow type="right" />}
                  prevArrow={<Arrow type="left" />}
                  {...settings}
                >
                  {[...this.props.banners].sort((a, _b) => a.priority - _b.priority).map(banner =>
                    <Slide
                      key={banner.id}
                      image={banner.image}
                      descr={banner.description}
                      title={banner.title}
                    />,
                  )}
                </Carousel>
              </section>

              <section className={b('news')()}>
                <Title text="Новости компании" />
                <NewsList onNewsSelected={this.onNewsSelected} />
              </section>
            </>
          </Route>

          <Route exact path="/basket" component={Basket} />
          <Route path="/catalog" component={Catalog} />
          <Route path="/personal" component={PersonalOffice} />
          <Route path="/news" component={News} />
          <Route path="/shops" component={Shops} />
          <Route path="/search" component={Search} />
          <Route
            exact
            path="/:flatpage"
            render={props => <FlatPage slug={props.match.params.flatpage} />}
          />
          <Route path="/*" render={Fallback} />
        </Switch>
      </BaseLayout>
    );
  }

  @bind
  private onNewsSelected(news: INews) {
    this.props.history.push('/news/' + news.id);
  }
}

function Arrow({ type, onClick }: { type: 'left' | 'right',  onClick?: any }) {
  return (
    <div onClick={onClick}  className={b('slider-arrow', { type })()}>
      <InlineSvg className={b('slider-arrow-icon')()}  element="div" src={ArrowIcon} />
    </div>
  );
}

function Slide({ image, title, descr }: { image: string; title: string; descr: string; }) {
  return (
    <div className={b('slide')()}>
      <div className={b('slide-descr')()}>
        <div className={b('slide-descr-content')()}>
          <h3 className={b('slide-title')()}>
            {/* Огромный выбор канцелярских товаров! */}
            {title}
          </h3>
          <p className={b('slide-text')()}>
            {descr}
            {/* Мы рады предложить вам канцелярские товары
            высокого качества по низким ценам! */}
          </p>
        </div>
      </div>
      <div className={b('slide-image')()}>
        <img className={b('slide-picture')()} src={image} />
      </div>
    </div>
  );
}

export default connect<{ banners: ISlide[]; }, typeof mappedActions, {}>(mapState, mapActions)(HomeLayout);

import * as namespace from './namespace';
import { actions, selectors, reducer, getSaga } from './redux';
import { IReduxEntry } from 'shared/types/app';
import ProductsList from './view/components/ProductsList/ProductsList';

export { namespace, selectors, actions, ProductsList };
export * from './view/containers';

export const reduxEntry: IReduxEntry = {
  reducers: { viewCategories: reducer },
  sagas: [getSaga],
};

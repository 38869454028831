import { INewOrder } from '../types/models';

export function validateOrder(order: INewOrder) {
  if (order.deliveryType === 'own' && !order.deliveryPoint) {
    return 'Необходимо выбрать точку самовывоза.';
  }

  if (!order.person.fullName) {
    return 'Ф.И.О обязательно';
  }

  if (!order.person.phone) {
    return 'Телефон обязателен';
  }
}

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Button } from 'shared/view/elements';
import { IAppReduxState } from 'shared/types/app';
import { ICommunication } from 'shared/types/redux';
import { actions, selectors } from '../../../redux';
import './ChangePassword.scss';
import { isSuccessedByState } from 'shared/helpers/redux';

interface IStateProps {
  password: string;
  confirm: string;
  loading: ICommunication;
}

interface IOwnProps {
  onSuccess?: () => void;
}

type IActionProps = typeof propsActions & IOwnProps;

const propsActions = {
  changePassword: actions.changeChangePasswordPassword,
  changeConfirm: actions.changeChangePasswordConfirm,
  submit: actions.changePassword,
};

type Props = IStateProps & IActionProps & IOwnProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    ...selectors.selectChangePasswordEdit(state),
    loading: selectors.selectCommunication(state, 'passwordChanging'),
  };
}

function mapActions(dispatch: Dispatch<any>) {
  return bindActionCreators(propsActions, dispatch);
}

const b = block('change-password');

class ChangePassword extends React.PureComponent<Props> {
  public componentWillReceiveProps(nextProps: Props) {
    if (isSuccessedByState(this.props.loading, nextProps.loading)) {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  public render() {
    const { changeConfirm, changePassword, password, confirm, loading } = this.props;
    const isDisabled = !password || !confirm || loading.isRequesting;

    return (
      <div className={b()}>
        <h2 className={b('title')()}>Смена пароля</h2>
        <form onSubmit={this.onSubmit}>
          <div className={b('field')()}>
            <Input
              type="password"
              name="password"
              placeholder="Новый пароль"
              onChange={changePassword}
              value={password}
            />
          </div>

          <div className={b('field')()}>
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Повторите новый пароль"
              onChange={changeConfirm}
              value={confirm}
            />
          </div>

          <p className={b('error')()}>{loading.error}</p>

          <div className={b('submit')()}>
            <Button disabled={isDisabled} type="submit" theme="green">
              <span>Подтвердить</span>
            </Button>
          </div>
        </form>
      </div>
    );
  }

  @bind
  private onSubmit(e: React.FormEvent<any>) {
    e.preventDefault();
    this.props.submit();
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(ChangePassword);

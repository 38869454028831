import { bind } from 'decko';
import {
  ICategory, IPaginated, IProduct, TProductSorting,
  IBasket, IUser, IBasketItem, INews, IShop, IFlatPage,
  TProductFilter, TAppliedProductFilter,
  IRangeProductFilter,
  IDeliveryPoint,
  INewOrder,
  ICreatedOrder,
  ISlide,
  IBanner,
} from 'shared/types/models';
import storage from './storage';
import HttpActions from './HttpActions';
import {
  IServerGroup, IServerProduct, IServerPaginated, IServerUser,
  IServerBasket, IServerResponse, IServerNews, IServerShop,
  IServerPage, IServerProfile, IServerProductFilter, IServerDeliveryPoint, ICreateOrderRequest, IServerOrder,
} from './types';
import {
  convertGroupToCategory, convertProductToItem,
  convertServerUser, convertServerBasket, convertServerNews,
  convertServerShop, convertServerPage, convertServerProductFilter, convertServerDeliveryPoint, convertServerOrder,
} from './converters';
import { remove } from 'ramda';
import { mkBasket } from 'shared/model/product';

class Api {
  private actions: HttpActions;

  constructor(public baseUrl: string, public version: string = '') {
    this.actions = new HttpActions(`${baseUrl}/${version}`);
  }

  @bind
  public async loadCategories(): Promise<ICategory[]> {
    const response = await this.actions.get<IServerGroup[]>('/groups/', { parent: 'null' });
    return response.data.map(
      category => convertGroupToCategory(
        category, {
          items: [],
          page: 0,
          size: 0,
          total: 0,
          sorting: '-name',
          appliedFilters: [],
        },
        [],
        0,
      ),
    );
  }

  @bind
  public async loadCategory(id: string): Promise<ICategory> {
    const response = await this.actions.get<IServerGroup>(`/groups/${id}/`);
    const products = await this.loadProducts(id, 30, 0, 'name', []);
    const filters = await this.loadCategoryFilters(id);
    const result: ICategory = {
      ...convertGroupToCategory(
        response.data,
        { ...products.data, appliedFilters: [] },
        filters,
        products.maxPrice,
      ),
    };

    return result;
  }

  @bind
  public async loadCategoryFilters(categoryId: string): Promise<TProductFilter[]> {
    const response = await this.actions.get<IServerProductFilter[]>(`/params-types/?group_id=${categoryId}`);
    return response.data.map(filter => convertServerProductFilter(filter));
  }

  @bind
  public async loadProducts(
    categoryId: string = '',
    limit: number,
    offset: number,
    ordering: TProductSorting,
    filters: TAppliedProductFilter[],
  ) {
    const priceFilter: IRangeProductFilter | void = filters.find((f: any) => Boolean(f.max) && Boolean(f.min)) as any;
    const params = {
      group_id: categoryId,
      limit,
      offset,
      ordering,
      max_price: priceFilter && priceFilter.max,
      min_price: priceFilter && priceFilter.min,
    };
    const paramValues = filters
      .filter((f: any) => Boolean((f as any).choice))
      .reduce((fs, f) => `${fs}${fs ? '&' : ''}param_values=${f.id}`, '');

    type Response = { price__max: number; price__min: number; } & IServerPaginated<IServerProduct>;
    const response = await this.actions.get<Response>(`/products/?${paramValues}`, params);
    const result: IPaginated<IProduct> & { sorting: TProductSorting; appliedFilters: TAppliedProductFilter[] } = {
      items: response.data.results.map(convertProductToItem),
      size: limit,
      page: Math.ceil(offset / limit) + 1,
      total: response.data.count,
      sorting: ordering,
      appliedFilters: filters,
    };

    return { data: result, maxPrice: response.data.price__max };
  }

  @bind
  public async saveBasket(user: IUser | null, basket: IBasket, cleanLocal = false): Promise<IBasket> {
    if (user) {
      const payload = basket.items.map(item => ({ product_id: item.id, quantity: item.basketCount }));
      const response = await this.actions.post<IServerResponse<IServerBasket>>('/basket/items/', payload);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      if (cleanLocal) {
        storage.set<IBasket>('basket', mkBasket([]));
      }

      return convertServerBasket(response.data.data);
    } else {
      const newBasket = mkBasket(basket.items);
      storage.set<IBasket>('basket', newBasket);
      return Promise.resolve(newBasket);
    }
  }

  @bind
  public async deleteBasketItem(user: IUser | null, item: IBasketItem): Promise<IBasket> {
    if (user) {
      const payload = [item.basketItemId];
      const response = await this.actions.del<IServerResponse<IServerBasket>>('/basket/items/', payload, {}, {});
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return convertServerBasket(response.data.data);
    }

    const basket = storage.get<IBasket, IBasket>('basket', { items: [], summaryPrice: 0 });
    const index = basket.items.findIndex(i => item.basketItemId === i.basketItemId);
    const newBasket: IBasket = mkBasket(remove(index, 1, basket.items));
    storage.set<IBasket>('basket', newBasket);

    return Promise.resolve(newBasket);
  }

  @bind
  public async loadBasket(user: IUser | null): Promise<IBasket> {
    if (user) {
      const response = await this.actions.get<IServerResponse<IServerBasket>>('/basket');

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      return convertServerBasket(response.data.data);
    } else {
      return storage.get<IBasket, IBasket>('basket', { items: [], summaryPrice: 0 });
    }
  }

  @bind
  public async register(name: string, email: string, password: string): Promise<void> {
    const result = await this.actions.post<IServerResponse<any>>('/registration/', { name, email, password });

    if (!result.data.success) {
      throw new Error(result.data.message);
    }
  }

  @bind
  public async login(email: string, password: string): Promise<IUser> {
    const loginResult = await this.actions.post<IServerResponse<IServerUser>>('/login/', { email, password });
    const profileResult = await this.actions.get<IServerResponse<IServerProfile>>('/profile/');

    if (!loginResult.data.success) {
      throw new Error(loginResult.data.message);
    }

    const profile: IServerProfile | null = profileResult.data.success ? profileResult.data.data : null;
    const user = convertServerUser(loginResult.data.data, profile);

    return user;
  }

  @bind
  public async logout(): Promise<void> {
    await this.actions.get('/logout/');
  }

  @bind
  public async loadProduct(id: string): Promise<IProduct | null> {
    const response = await this.actions.get<IServerProduct | null>(`/products/${id}`);

    if (response.status === 404) {
      throw new Error('Товар не найден');
    }

    return response.data && convertProductToItem(response.data);
  }

  @bind
  public async checkUser(): Promise<IUser | null> {
    const response = await this.actions.get<IServerResponse<IServerUser>>('/check');

    if (!response.data.success) {
      return null;
    } else {
      const profileResult = await this.actions.get<IServerResponse<IServerProfile>>('/profile/');
      const profile: IServerProfile | null = profileResult.data.success ? profileResult.data.data : null;
      return convertServerUser(response.data.data, profile);
    }
  }

  @bind
  public async loadNews({ limit }: { limit: number }): Promise<INews[]> {
    interface IResponse {
      count: number;
      page: number;
      results: IServerNews[];
    }
    const response = await this.actions.get<IResponse>('/news', { limit });
    return response.data.results.map(convertServerNews);
  }

  @bind
  public async loadNewsDetails(id: string): Promise<INews | null> {
    const response = await this.actions.get<IServerNews | { details: string }>(`/news/${id}/`);

    if ((response.data as any).id) {
      return convertServerNews(response.data as IServerNews);
    }

    return null;
  }

  @bind
  public async loadShops(): Promise<IShop[]> {
    const response = await this.actions.get<IServerShop[]>('/shops/');
    return response.data.map(convertServerShop);
  }

  @bind
  public async loadFlatPages(): Promise<IFlatPage[]> {
    const response = await this.actions.get<IServerPage[]>('/flat-pages/');
    return response.data.map(convertServerPage);
  }

  @bind
  public async updateUserData(user: IUser): Promise<IUser> {
    const reqData: IServerProfile = {
      address: user.address,
      first_name: user.firstName,
      last_name: user.lastName,
      phone: user.phone,
    };

    const response = await this.actions.put<IServerResponse<IServerProfile>>(
      '/profile/', reqData, {}, {},
    );
    return convertServerUser(
      {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        activity: null,
      },
      response.data.data,
    );
  }

  @bind
  public async loadDeliveryPoints(): Promise<IDeliveryPoint[]> {
    const response = await this.actions.get<IServerDeliveryPoint[]>('/point-deliveries/');
    return response.data.map(convertServerDeliveryPoint);
  }

  @bind
  public async searchProducts(
    query: string, ordering: TProductSorting, limit: number, offset: number,
  ): Promise<IPaginated<IProduct> & { sorting: TProductSorting }> {
    const response = await this.actions.get<IServerPaginated<IServerProduct>>(
      '/search/products/', { text: query, limit, offset },
    );
    return {
      items: response.data.results.map(p => convertProductToItem(p)),
      size: limit,
      page: Math.ceil(offset / limit) + 1,
      total: response.data.count,
      sorting: ordering,
    };
  }

  @bind
  public async searchCategories(query: string, limit: number, offset: number): Promise<IPaginated<ICategory>> {
    const response = await this.actions.get<IServerPaginated<IServerGroup>>(
      '/search/groups/', { text: query, offset, limit },
    );
    return {
      items: response.data.results.map(
        item => convertGroupToCategory(
          item,
          { appliedFilters: [], total: 0, sorting: '-name', size: 30, page: 1, items: [] },
          [],
          0,
        ),
      ),
      size: limit,
      page: Math.ceil(offset / limit) + 1,
      total: response.data.count,
    };
  }

  @bind
  public async createOrder(order: INewOrder): Promise<ICreatedOrder> {
    interface IResponse {
      code: number;
      success: boolean;
      message: string;
      data: IServerOrder;
    }

    const data: ICreateOrderRequest = {
      description: order.comment,
      full_name: order.person.fullName,
      phone: order.person.phone,
      type_delivery: order.deliveryType === 'own' ? 'pickup' : 'delivery',
    };

    if (order.deliveryType === 'own' && order.deliveryPoint) {
      data.point_delivery_id = +order.deliveryPoint.id;
    }

    const response = await this.actions.post<IResponse>('/place-order/', data);

    if (!response.data.success) {
      throw new Error(response.data.message || 'Не удалось создать заказ');
    }

    return convertServerOrder(response.data.data);
  }

  @bind
  public async loadOrdersHistory(): Promise<ICreatedOrder[]> {
    interface IResponse {
      code: number;
      success: boolean;
      message: string;
      data: IServerOrder[];
    }
    const response = await this.actions.get<IResponse>('/orders-history/');
    return response.data.data.map(order => convertServerOrder(order));
  }

  @bind
  public async loadBanners(): Promise<{ slides: ISlide[]; banners: any[] }> {
    type IResponse = Array<{ id: number; text: string; title: string; priority: number; image: string; }>;

    const response = await this.actions.get<IResponse>('/slides/');
    const extraBannersResponse = await this.actions.get<
      Array<{ id: number; image: string; position: string; url: string }>
    >('/banners/');
    const slides = response.data.map<ISlide>(s => ({
      id: String(s.id),
      description: s.text,
      title: s.title,
      image: s.image,
      priority: s.priority,
    }));
    const banners: any[] = extraBannersResponse.data.map<IBanner>(b => ({
      id: String(b.id),
      image: b.image,
      position: b.position as any,
      url: b.url,
    }));

    return { slides, banners };
  }

  @bind
  public async restorePassword(email: string) {
    await this.actions.post('/password/restore/', { email });
  }

  @bind
  public async finishRestorePassword(token: string, password: string, confirm: string): Promise<IUser> {
    const data = { token, password, password2: confirm };
    const resp = await this.actions.post<IServerResponse<IServerUser>>('/password/restore/confirm', data);

    if (!resp.data.success) {
      throw new Error(resp.data.message || 'Не удалось сменить пароль');
    }

    const profileResult = await this.actions.get<IServerResponse<IServerProfile>>('/profile/');
    const profile: IServerProfile | null = profileResult.data.success ? profileResult.data.data : null;
    const user = convertServerUser(resp.data.data, profile);

    return user;
  }

  @bind
  public async changePassword(password: string, confirm: string) {
    const data = { password, password2: confirm };
    const resp = await this.actions.post<IServerResponse<{}>>('/password/change', data);

    if (!resp.data.success) {
      throw new Error(resp.data.message || 'Не удалось сменить пароль');
    }
  }
}

export default Api;

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { IAppReduxState } from 'shared/types/app';
import { IBasketItem, ICreatedOrder } from 'shared/types/models';
import { Collapsible } from 'shared/view/elements';

import { selectors, actions } from '../../../redux';
import './OrdersHistory.scss';

interface IStateProps {
  orders: ICreatedOrder[];
}

interface IActionProps {
  load: typeof actions.loadOrdersHistory;
}

interface IOwnProps {
  ItemsView: React.ComponentType<{ items: IBasketItem[]; price: number; }>;
}

type Props = IStateProps & IOwnProps & IActionProps;

const b = block('orders-history');

function mapState(state: IAppReduxState): IStateProps {
  return {
    orders: selectors.getFeatureState(state).data.ordersHistory,
  };
}

function mapActions(dispatch: Dispatch<any>): IActionProps {
  return bindActionCreators({
    load: actions.loadOrdersHistory,
  }, dispatch);
}

class OrdersHistory extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.load();
  }

  public render() {
    const { ItemsView, orders } = this.props;
    const statuses: Record<ICreatedOrder['status'], string> = {
      new: 'Создан',
      collect: 'Сборка',
      completed: 'Завершен',
      delivery: 'Доставляется',
      processed: 'Обработан',
      ready: 'Готов',
    };

    return (
      <div className={b()}>
        <div className={b('row')()}>
          <div className={b('cell', { type: 'head' })()}>№</div>
          <div className={b('cell', { type: 'head' })()}>Дата</div>
          <div className={b('cell', { type: 'head' })()}>Сумма</div>
          <div className={b('cell', { type: 'head' })()}>Статус</div>
        </div>
        {!orders.length ? <p className={b('empty')()}>Вы не оформили ни одного заказа</p> : null}
        {
          orders.map(order =>
            <Collapsible key={order.id}>
              <Collapsible.Panel>
                <div className={b('row')()}>
                  <div className={b('cell')()}>{order.id}</div>
                  <div className={b('cell')()}>
                    {order.createdAt ? this.formatDate(order.createdAt) : '-'}
                  </div>
                  <div className={b('cell')()}>{order.summary.toFixed(2)} руб.</div>
                  <div className={b('cell')()}>{statuses[order.status]}</div>
                </div>
              </Collapsible.Panel>
              <Collapsible.Content>
                <div className={b('items')()}>
                  <ItemsView items={order.items} price={order.summary} />
                </div>
              </Collapsible.Content>
            </Collapsible>,
          )
        }
      </div>
    );
  }

  private formatDate(timestamp: number) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const res = `${day}.${month}.${year}`;

    return res;
  }
}

export default connect<IStateProps, IActionProps>(mapState, mapActions)(OrdersHistory);

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { bind } from 'decko';
import { bindActionCreators, Dispatch } from 'redux';
import InlineSvg from 'svg-inline-react';
import Masonry from 'react-masonry-component';
import * as actions from '../../../redux/actions';

import { IAppReduxState } from 'shared/types/app';
import { ICategory } from 'shared/types/models';
import { Photo, Preloader } from 'shared/view/elements';
import { ICommunication } from 'shared/types/redux';

import { selectors } from './../../../redux';
import * as ArrowIcon from './img/arrow.svg';

import './CategoriesList.scss';

type TOnSelect = (parents: ICategory[], value: ICategory) => void;

interface IOwnProps {
  view: 'recursive' | 'grid';
  onSelect?: TOnSelect;
  expandable?: boolean;
  defaultExpanded?: boolean;
  To?: React.ComponentType<{ parents: ICategory[]; category: ICategory; }>;
}

interface IStateProps {
  categories: ICategory[];
  loading: ICommunication;
}

interface IActionProps {
  loadCategories: typeof actions.loadCategories;
}

interface IState {
  expanded: boolean;
}

type IProps = IStateProps & IActionProps & IOwnProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    categories: selectors.selectCategories(state),
    loading: selectors.selectCategoriesLoading(state),
  };
}

function mapDispatch(dispatch: Dispatch<IAppReduxState>): IActionProps {
  return bindActionCreators({
    loadCategories: actions.loadCategories,
  }, dispatch);
}

const b = block('categories-list');
const MAX_LEVEL = 1;

class CategoriesList extends React.PureComponent<IProps, IState> {
  public state: IState = { expanded: Boolean(this.props.defaultExpanded) };
  private contentRef: HTMLElement | null = null;

  public componentWillReceiveProps(nextProps: IProps) {
    if (this.props.defaultExpanded !== nextProps.defaultExpanded) {
      this.setState({ expanded: Boolean(nextProps.defaultExpanded) });
    }
  }

  public componentDidMount() {
    if (this.props.view === 'grid') {
      window.addEventListener('resize', this.onResize);
    }

    this.props.loadCategories();
  }

  public componentWillUnmount() {
    if (this.props.view === 'grid') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  public render() {
    const { categories, view, onSelect, loading, To, expandable = false } = this.props;
    const { expanded } = this.state;

    const views: Record<IOwnProps['view'], any> = {
      recursive: () => (
        <section className={b({ view, expanded, expandable })()}>
          <h3 className={b('title')()} onClick={expandable ? this.onToggleExpanding : void 0}>
            Категории
            {expandable && <InlineSvg className={b('expand')()} element="div" src={ArrowIcon} />}
          </h3>

          {
            (expandable && expanded) || !expandable ? (
              <>
                <List parents={[]} items={categories} level={0} onSelect={onSelect} />
                <div className={b('preloader')()}>
                  {!categories.length && loading.isRequesting ? <Preloader type="bullet" /> : null}
                </div>
                {!categories.length && !loading.isRequesting ? <EmptyStub /> : null}
              </>
            ) : null
          }
        </section>
      ),
      grid: () => (
        <div className={b('grid-wrapper')()} ref={this.onContentRef}>
          <Grid
            To={To}
            categories={categories}
            loading={loading}
            onSelect={onSelect}
            width={this.contentRef ? this.contentRef.clientWidth / 3 : void 0}
          />
        </div>
      ),
    };
    return views[view]();
  }

  @bind
  private onResize() {
    this.forceUpdate();
  }

  @bind
  private onContentRef(ref: HTMLDivElement) {
    this.contentRef = ref;

    if (ref) { this.forceUpdate(); }
  }

  @bind
  private onToggleExpanding() {
    if (!this.props.expandable) { return; }

    this.setState({ expanded: !this.state.expanded });
  }
}

function List(
  { items, nested = false, level, onSelect, parents }:
  { onSelect?: TOnSelect; items: ICategory[], level: number, nested?: boolean; parents: ICategory[] },
) {
  return (
    <div className={b('list', { nested })()}>
      {items.map((c, i) =>
        <Category nested={nested} parents={parents} level={level} data={c} key={i} onSelect={onSelect} />)}
    </div>
  );
}

interface IGridProps {
  To?: React.ComponentType<{ parents: ICategory[]; category: ICategory; }>;
  loading: ICommunication;
  categories: ICategory[];
  onSelect?: TOnSelect;
  width?: number;
}

export function Grid({ loading, categories, onSelect, width, To }: IGridProps) {
  return (
    <section className={b({ view: 'grid' })()}>
      {loading.isRequesting ? <Preloader type="bullet" /> : null}
      {!categories.length && !loading.isRequesting ? <EmptyStub /> : null}
      <Masonry>
        {!loading.isRequesting ? categories.map((c, i) => (
          <article
            key={i}
            className={b('cat-item')()}
            style={{ width: width || 'auto' }}
          >
            <div className={b('cat-item-content')()}>
              <div className={b('cat-item-photo')()}><Photo src={c.image} /></div>
              <h3 className={b('cat-item-title')()} onClick={onSelect && onSelect.bind(this, [], c)}>
                {To ? <To category={c} parents={[]} /> : c.title}
              </h3>
              <div className={b('cat-item-children')()}>
                {c.categories.map(
                  (child, _i) =>
                  <p
                    key={`${_i}${i}`}
                    className={b('cat-item-child')()}
                    onClick={onSelect && onSelect.bind(this, [c], child)}
                  >
                   {To ? <To category={child} parents={[c]} /> : child.title}
                  </p>)
                }
              </div>
            </div>
          </article>
          )) : null}
        </Masonry>
    </section>
  );
}

// tslint:disable:max-classes-per-file
class Category extends React.PureComponent<
  { parents: ICategory[], data: ICategory, level: number, onSelect?: TOnSelect; nested?: boolean }
> {
  public render(): React.ReactElement<any> {
    const { data, level, parents, onSelect, nested = false } = this.props;
    return (
      <div className={b('category', { nested })()}>
        <div className={b('category-item')()} onClick={this.onClick}>
          {data.title}
          {!nested ? <InlineSvg src={ArrowIcon} element="div" className={b('arrow')()} /> : null}
        </div>
        {data.categories.length && level < MAX_LEVEL ? (
          <div className={b('category-children')()}>
            <List
              parents={parents.concat([data])}
              onSelect={onSelect}
              nested
              items={data.categories}
              level={level + 1}
            />
          </div>
        ) : null}
      </div>
    );
  }

  @bind
  private onClick() {
    this.props.onSelect && this.props.onSelect(this.props.parents, this.props.data);
  }
}

function EmptyStub() {
  return (
    <p className={b('empty')()}>Категории отсутствуют</p>
  );
}

export { CategoriesList };
export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapDispatch)(CategoriesList);

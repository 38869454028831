import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { IUser } from 'shared/types/models';
import { ICommunication } from 'shared/types/redux';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.auth;
}

export function selectCommunication(state: IAppReduxState, key: keyof NS.IReduxState['communication']) {
  return getFeatureState(state).communication[key];
}

export function selectSignUpEdit(state: IAppReduxState) {
  return getFeatureState(state).edit.signUp;
}

export function selectSignInEdit(state: IAppReduxState) {
  return getFeatureState(state).edit.signIn;
}

export function isAuthorized(state: IAppReduxState) {
  return Boolean(getFeatureState(state).data.user);
}

export function selectUser(state: IAppReduxState): IUser | null {
  return getFeatureState(state).data.user;
}

export function selectUserChecking(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.checking;
}

export function selectEditingProfile(state: IAppReduxState) {
  return getFeatureState(state).edit.profile;
}

export function selectIsPersonalDataSubmitted(state: IAppReduxState) {
  return getFeatureState(state).edit.profile.submitted;
}

export function selectIsEditUserHasErrors(state: IAppReduxState) {
  const editing = selectEditingProfile(state);
  return Boolean(editing.firstName.error || editing.lastName.error || editing.phone.error || editing.address.error);
}

export function selectEditedUser(state: IAppReduxState): IUser {
  const editing = selectEditingProfile(state);
  return {
    address: editing.address.value,
    email: getFeatureState(state).data.user!.email,
    firstName: editing.firstName.value,
    lastName: editing.lastName.value,
    phone: editing.phone.value,
  };
}

export function selectIsOpenedModal(state: IAppReduxState): boolean {
  return getFeatureState(state).view.isModalOpened;
}

export function selectRestoreEmail(state: IAppReduxState) {
  return getFeatureState(state).edit.restore.email;
}

export function selectRestoreToken(state: IAppReduxState): string {
  return getFeatureState(state).edit.restore.token;
}

export function selectRestorePassword(state: IAppReduxState) {
  return getFeatureState(state).edit.restore.password;
}

export function selectRestoreConfirm(state: IAppReduxState) {
  return getFeatureState(state).edit.restore.confirm;
}

export function selectChangePasswordEdit(state: IAppReduxState) {
  return getFeatureState(state).edit.changePassword;
}

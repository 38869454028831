import * as NS from '../../namespace';
import initial from '../initial';

export default function dataReducer(
  state: NS.IReduxState['data'] = initial.data, action: NS.Action,
): NS.IReduxState['data'] {
  switch (action.type) {
    case 'SEARCH:EXECUTE_PRODUCTS_SUCCESS': {
      return {
        ...state,
        products: { ...action.payload, appliedFilters: [] },
      };
    }
    case 'SEARCH:EXECUTE_CATEGORIES_SUCCESS': {
      return {
        ...state,
        categories: action.payload,
      };
    }
    default: return state;
  }
}

import * as React from 'react';
import * as block from 'bem-cn';
import Truncate from 'react-truncate';
import { IProduct, IUser } from 'shared/types/models';
import { Photo } from 'shared/view/elements';
import { isExists } from 'shared/model/product';
import './ProductItem.scss';

interface IProps {
  product: IProduct;
  user: IUser | null;
  view: 'row' | 'card';
  Extra?: React.ComponentType<{ product: IProduct; user: IUser | null; }>;
  onClick?: (product: IProduct) => void;
}

interface IViewProps {
  product: IProduct;
  user: IUser | null;
  Extra?: IProps['Extra'];
  onClick?: (product: IProduct) => void;
}

// tslint:disable:jsx-no-lambda
class ProductItem extends React.PureComponent<IProps, {}> {
  public render() {
    const { view, children: _, ...props } = this.props;
    const b = block('product-item');
    return (
      <article className={b({ view })()}>
        {view === 'card' ? <CardView {...props} /> : null}
        {view === 'row' ? <RowView {...props} /> : null}
      </article>
    );
  }
}

function RowView({ product, Extra, user, onClick }: IViewProps) {
  const b = block('product-item-row');
  return (
    <>
      <div className={b('image')()} onClick={() => onClick && onClick(product)}>
        <Photo preview={Boolean(product.image)} type="html" src={product.image} />
      </div>
      <div className={b('content')()}>
        <p className={b('text', { interactive: true })()} onClick={() => onClick && onClick(product)}>
          <Truncate lines={1}>{product.name}</Truncate>
        </p>

        <div className={b('data')()}>
          <div className={b('data-info')()}>
            <p className={b('text')()}>Код: {product.vendorCode}</p>
            <p className={b('text')()}>Страна: {product.country || '-'}</p>
            <p className={b('text')()}>{isExists(product) ? 'В наличии' : 'Нет в наличии'}</p>
          </div>
          <div className={b('data-quantity')()}>
            <p className={b('text')()}>В кор.: {product.inBoxCount}</p>
            <p className={b('text')()}>В упак.: {product.inPackCount}</p>
            <p className={b('text')()}>Объем кор.: {product.boxVolume}</p>
          </div>
        </div>
      </div>
      <div className={b('controls')()}>
        <p className={b('price')()}>{product.price.toFixed(2)} руб</p>
        <div className={b('extra-content')()}>
          {Extra && <Extra user={user} product={product} />}
        </div>
      </div>
    </>
  );
}

function CardView({ product, Extra, user, onClick }: IViewProps) {
  const b = block('product-item-card');
  return (
    <>
      <div className={b('photo')()} onClick={() => onClick && onClick(product)}>
        <Photo preview={Boolean(product.image)} position="top" type="html" src={product.image} />
      </div>

      <div className={b('content')()}>
        <p className={b('descr')()} onClick={() => onClick && onClick(product)}>
          <Truncate lines={2}>{product.name}</Truncate>
        </p>
        <p className={b('price')()}>{product.price.toFixed(2)} руб</p>
        <p className={b('text')()}>код: {product.vendorCode}</p>
        <p className={b('exists')()}>
          {isExists(product) ? 'В наличии' : 'Нет в наличии'}
        </p>

        <div className={b('extra-content')()}>
          {Extra && <Extra user={user} product={product} />}
        </div>
      </div>
    </>
  );
}

export default ProductItem;

import * as NS from '../namespace';

const initial: NS.IReduxState = {
  data: {
    banners: [],
    slides: [],
  },
};

export default initial;

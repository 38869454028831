import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';

import * as NS from '../namespace';
import * as actions from './actions';
import { requestSaga } from 'shared/helpers/redux';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadShopsType: NS.ILoadShops['type'] = 'SHOPS:LOAD';

    yield [
      takeLatest(loadShopsType, loadShopsSaga, deps),
    ];
  };
}

function* loadShopsSaga({ api }: IDependencies) {
  yield* requestSaga(() => api.loadShops(), actions.loadShopsSuccess, actions.loadShopsFail);
}

export default getSaga;

import * as NS from '../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: loadShopsSuccess,
  execute: loadShops,
  failed: loadShopsFail,
} = makeCommunicationActionCreators<NS.ILoadShops, NS.ILoadShopsSuccess, NS.ILoadShopsFail>(
  'SHOPS:LOAD', 'SHOPS:LOAD_SUCCESS', 'SHOPS:LOAD_FAIL',
);

import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import { initial } from '../initial';

export const communicationReducer = combineReducers<NS.IReduxState['communication']>({
  saving: makeCommunicationReducer<NS.ISaveBasket, NS.ISaveBasketSuccess, NS.ISaveBasketFail>(
    'BASKET:SAVE', 'BASKET:SAVE_SUCCESS', 'BASKET:SAVE_FAIL', initial.communication.saving,
  ),
  deleting: makeCommunicationReducer<NS.IDeleteBasketItem, NS.IDeleteBasketItemSuccess, NS.IDeleteBasketItemFail>(
    'BASKET:DELETE_ITEM', 'BASKET:DELETE_ITEM_SUCCESS', 'BASKET:DELETE_ITEM_FAIL', initial.communication.deleting,
  ),
  loading: makeCommunicationReducer<NS.ILoadBasket, NS.ILoadBasketSuccess, NS.ILoadBasketFail>(
    'BASKET:LOAD', 'BASKET:LOAD_SUCCESS', 'BASKET:LOAD_FAIL', initial.communication.loading,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

export { default as withComponent } from './withComponent';
export * from './validation';

export function chunkBy<T>(arr: T[], predicate: (chunk: T[], item: T) => boolean): T[][] {
  const result = [];
  let chunk: T[] = [];

  for (const item of arr) {
    if (!predicate(chunk, item)) {
      chunk.push(item);
    } else {
      result.push(chunk);
      chunk = [item];
    }
  }

  if (result.indexOf(chunk) < 0 && chunk.length) {
    result.push(chunk);
  }

  return result;
}

import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import InlineSvg from 'svg-inline-react';
import * as GlassIcon from './img/glass.svg';
import './Input.scss';
import { Omit } from 'react-redux';

interface IProps extends Omit<React.InputHTMLAttributes<any>, 'onChange'> {
  icon?: 'search';
  onChange?: (value: string) => void;
}

class Input extends React.PureComponent<IProps, {}> {
  public render() {
    const b = block('input');
    const { icon, onChange, ...attrs } = this.props;

    return (
      <div className={b()}>
        {icon ? (
          <InlineSvg element="div" src={GlassIcon} className={b('icon', { type: icon })()} />
        ) : null}
        <input onChange={this.onChange} className={b('field', { iconified: Boolean(icon) })()} {...attrs}/>
      </div>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;

    const isNumericInput = typeof this.props.value === 'number';
    const isNumericValue = !isNaN(+value);
    const callHook = () => this.props.onChange && this.props.onChange(value);

    if (!isNumericInput || (isNumericInput && isNumericValue)) {
      callHook();
    }
  }
}

export default Input;

import * as React from 'react';
import * as block from 'bem-cn';
import { TFunc } from 'shared/types/func';
import './ViewSelector.scss';

type TView = 'grid' | 'list';

const b = block('view-selector');

export default function ViewSelector(
  { view, width, onGridSelected, onListSelected }:
  { view: TView, width?: number, onGridSelected?: TFunc, onListSelected?: TFunc },
  ) {
  return (
    <div className={b({ selected: view })()} style={{ width }}>
      <div className={b('grid')()} onClick={onGridSelected}>
        <div className={b('grid-decor')()} />
        <div className={b('grid-decor')()} />
        <div className={b('grid-decor')()} />
        <div className={b('grid-decor')()} />
      </div>
      <div className={b('list')()} onClick={onListSelected}>
        <div className={b('list-decor')()} />
        <div className={b('list-decor')()} />
        <div className={b('list-decor')()} />
      </div>
    </div>
  );
}

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ClipLoader } from 'react-spinners';
import { bind } from 'decko';

import { Input, Button } from 'shared/view/elements';
import { IReduxField, ICommunication } from 'shared/types/redux';
import { IAppReduxState } from 'shared/types/app';
import { isSuccessedByState } from 'shared/helpers/redux';

import { actions, selectors } from '../../../redux';
import './Restore.scss';

interface IOwnProps {
  onBack?: () => void;
  onSuccess?: () => void;
  token?: string;
}

interface IStateProps {
  email: IReduxField<string>;
  password: IReduxField<string>;
  confirm: IReduxField<string>;
  starting: ICommunication;
  finishing: ICommunication;
}

const actionProps = {
  changeEmail: actions.changeRestoreEmail,
  changePassword: actions.changeRestorePasword,
  changeConfirm: actions.changeRestoreConfirmPassword,
  startRestore: actions.startRestore,
  finishRestore: actions.finishRestore,
};

type IActionProps = typeof actionProps;

type Props = IOwnProps & IStateProps & IActionProps;

function mapActions(dispatch: Dispatch<any>): IActionProps {
  return bindActionCreators(actionProps, dispatch);
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    email: selectors.selectRestoreEmail(state),
    password: selectors.selectRestorePassword(state),
    confirm: selectors.selectRestoreConfirm(state),
    starting: selectors.selectCommunication(state, 'restoreStarting'),
    finishing: selectors.selectCommunication(state, 'restoreFinishing'),
  };
}

const b = block('auth-restore');

interface IState {
  started: boolean;
}

class Restore extends React.PureComponent<Props, IState> {
  public state: IState = { started: false };
  public componentWillReceiveProps(nextProps: Props) {
    if (isSuccessedByState(this.props.starting, nextProps.starting)) {
      this.setState({ started: true });
    }

    if (isSuccessedByState(this.props.finishing, nextProps.finishing)) {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  public render() {
    const { started } = this.state;
    const { changeEmail, changeConfirm, changePassword, finishing, starting } = this.props;
    const { email, onBack, token, confirm, password } = this.props;
    const restoreDisabled = !email.value || starting.isRequesting;
    const changePasswordDisabled = !confirm.value || !password.value || finishing.isRequesting;
    const isLoading = starting.isRequesting || finishing.isRequesting;

    return (
      <div className={b()}>
        <h2 className={b('title')()}>Восстановление пароля</h2>

        {
          isLoading ? (
            <div className={b('preloader')()}>
              <ClipLoader size={30} color="#009846"  />
            </div>
          ) : null
        }

        {
          token ? (
            <form className={b('form')()} onSubmit={this.onFinishRestoreSubmit}>
              <div className={b('field')()}>
                <Input
                  name="password"
                  type="password"
                  placeholder="Новый пароль"
                  value={password.value}
                  onChange={changePassword}
                />
              </div>
              <div className={b('field')()}>
                <Input
                  name="confirm-password"
                  type="password"
                  placeholder="Повторите новый пароль"
                  value={confirm.value}
                  onChange={changeConfirm}
                />
              </div>
              <div className={b('submit')()}>
                <Button disabled={changePasswordDisabled} type="submit" theme="green">
                  <span>Восстановить</span>
                </Button>
              </div>
              <p className={b('error')()}>{finishing.error}</p>
            </form>
          ) : null
        }

        {
          !started && !token ? (
            <>
              <form className={b('form')()} onSubmit={this.onRestoreSubmit}>
                <div className={b('field')()}>
                  <Input name="email" type="email" placeholder="Email" value={email.value} onChange={changeEmail}/>
                </div>
                <p className={b('hint')()}>
                  Введите e-mail, на который Вы зарегистрировали аккаунт.
                  Мы вышлем Вам письмо с ссылкой на восстановление пароля.
                </p>
                <div className={b('submit')()}>
                  <Button disabled={restoreDisabled} type="submit" theme="green">
                    <span>Восстановить</span>
                  </Button>
                </div>
                <p className={b('error')()}>{starting.error}</p>
              </form>
              <button className={b('back')()} onClick={onBack}>Назад</button>
            </>
          ) : null
        }
        {
          started && !token ? (
            <p className={b('success')()}>
              Вам было выслано письмо с ссылкой на восстановление пароля.
            </p>
          ) : null
        }
      </div>
    );
  }

  @bind
  private onRestoreSubmit(e: React.FormEvent<any>) {
    e.preventDefault();
    this.props.startRestore();
  }

  @bind
  private onFinishRestoreSubmit(e: React.FormEvent<any>) {
    e.preventDefault();
    if (this.props.token) {
      this.props.finishRestore(this.props.token);
    } else  {
      console.error('Finishing restore requested, but no token provider');
    }
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(Restore);

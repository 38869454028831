import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import * as Stub from './img/bg.png';
import './Photo.scss';

interface IProps {
  src?: string;
  type?: 'css' | 'html';
  position?: 'top';
  preview?: boolean;
}

interface IState {
  isPreviewOpened: boolean;
  margin: number;
}

class Photo extends React.PureComponent<IProps, {}> {
  public state: IState = { isPreviewOpened: false, margin: 0 };
  private fixTimeout: number | null = null;

  public componentWillUnmount() {
    if (this.fixTimeout) {
      window.clearTimeout(this.fixTimeout);
    }
  }

  public render() {
    const b = block('photo');
    const { src = Stub, type = 'html', position, preview } = this.props;
    return (
      <div className={b({ type })()} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
        <div className={b('wrapper', { preview: Boolean(preview) })()}>
          {
            type === 'html' ? (
              <img
                style={{ marginTop: `${this.state.margin}px` }}
                src={src || Stub}
                onLoad={this.onImageLoaded}
                className={b('bg', { type })()}
              />
            ) : (
                <div
                  className={b('bg', { type })()}
                  style={{ backgroundImage: `url(${src || Stub})`, backgroundPosition: position }}
                />
              )
          }
        </div>

        {/* {preview ? (
          <div className={b('preview', { shown: isPreviewOpened })()}>
            <img src={src} className={b('preview-image')()} />
          </div>
         ) : null} */}
      </div>
    );
  }

  @bind
  private onEnter() {
    if (!this.props.preview || this.state.isPreviewOpened) { return; }

    this.setState({ isPreviewOpened: true });
  }

  @bind
  private onLeave() {
    if (!this.props.preview) { return; }

    this.setState({ isPreviewOpened: false });
  }

  @bind
  private onImageLoaded() {
    // Bullshit for chrome cached images bug
    this.setState({ margin: 1 });
    this.fixTimeout = window.setTimeout(() => this.setState({ margin: 0 }), 1000);
  }
}

export default Photo;

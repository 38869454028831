import * as React from 'react';
import * as block from 'bem-cn';
import { RouteComponentProps } from 'react-router';

import OrderBasket from 'modules/shared/OrderBasket/OrderBasket';
import { BasketItemsView } from 'features/basket';
import { PersonalSettings } from 'features/auth';
import { OrdersHistory } from 'features/orders';

import { Title, Tabs } from 'shared/view/elements';

import './PersonalOffice.scss';
import { findParents } from 'shared/model/product';
import { compileProductRoute } from 'modules/shared/routes';
import { IBasketItem } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { connect } from 'react-redux';

interface IProps extends RouteComponentProps<any> {
  a?: any;
}

const b = block('personal-office');

// TODO: remake to routed tabs insted of local routing
interface IState {
  view: 'settings' | 'history' | 'basket';
}

class PersonalOffice extends React.PureComponent<IProps, IState> {
  public state: IState = { view: 'history' };

  public componentDidMount() {
    this.checkRoute();
  }

  public componentDidUpdate() {
    this.checkRoute();
  }

  public render() {
    return (
      <div className={b()}>
        <Title text="Личный кабинет"  />

        <div className={b('content')()}>
          <Tabs activeTab={this.state.view}>
            <Tabs.Tab id="history" title="История">
              <div className={b('tab-content')()}>
                <OrdersHistory ItemsView={OrderItemsView} />
              </div>
            </Tabs.Tab>
            <Tabs.Tab id="basket" title="Корзина">
              <div className={b('tab-content')()}><OrderBasket /></div>
            </Tabs.Tab>
            <Tabs.Tab id="settings" title="Настройки">
              <div className={b('tab-content')()}>
                <PersonalSettings />
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    );
  }

  private checkRoute() {
    const query = this.props.location.search;
    const view: any = new URLSearchParams(query).get('view');

    if (view && ['settings', 'history', 'basket'].includes(view)) {
      this.setState({ view });
      this.props.history.push(this.props.match.url);
    }
  }
}

// TODO: chaos!
function OrderItemsViewPure(props: any) {
  const getBasketItemLink = (item: IBasketItem) => {
    const parents = findParents(props.categories, item, a => a.categoryId || '');
    const path = compileProductRoute(parents, item);
    return path;
  };

  return <BasketItemsView {...props} cropped onGetItemLink={getBasketItemLink} />;
}

const OrderItemsView = connect<any, any, any>(
  (state: IAppReduxState) => ({ categories: state.viewCategories.data.categories }),
)(OrderItemsViewPure);

export default PersonalOffice;

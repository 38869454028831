import { createSelector } from 'reselect';
import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { ICategory, IProduct, TProductFilter, TAppliedProductFilter } from 'shared/types/models';
import { ICommunication } from 'shared/types/redux';
import { sortByLetter } from 'shared/helpers/funtools';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.viewCategories;
}

export const selectCategories = createSelector<IAppReduxState, NS.IReduxState, ICategory[]>(
  state => getFeatureState(state),
  state => [...state.data.categories]
    .map(cat => ({ ...cat, categories: [...cat.categories].sort(sortByLetter(a => a.title)) }))
    .sort(sortByLetter(a => a.title)),
);

export const selectCategory = createSelector<IAppReduxState, NS.IReduxState, ICategory | null>(
  state => getFeatureState(state),
  state => state.data.category ?
    {
      ...state.data.category,
      categories: [...state.data.category.categories].sort(sortByLetter(a => a.title)),
    } : null,
);

export function selectCategoriesLoading(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.categoriesLoading;
}

export function selectCategoryLoading(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.categoryLoading;
}

export function selectProductsLoading(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.productsLoading;
}

export function selectProductLoading(state: IAppReduxState): ICommunication {
  return getFeatureState(state).communication.productLoading;
}

export const selectProduct = createSelector<IAppReduxState, NS.IReduxState, IProduct | null>(
  state => getFeatureState(state),
  state => state.data.product ?
    {
      ...state.data.product,
      properties: [...state.data.product.properties].sort(sortByLetter(o => o.name)),
    } : null,
);

export function selectProductFilters(state: IAppReduxState): TProductFilter[] {
  const category = getFeatureState(state).data.category;
  return category ? [
    ...category.filters.map<TProductFilter>(
      f => f.type === 'choice' ? { ...f, choices: [...f.choices].sort(sortByLetter(o => o.choice))} : f,
    ),
  ].sort(sortByLetter(o => o.name)) : [];
}

export function selectSelectedProductFilters(state: IAppReduxState): TAppliedProductFilter[] {
  const category = getFeatureState(state).data.category;
  return category ? category.products.appliedFilters : [];
}

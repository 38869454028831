// import { combineReducers } from 'redux';
// import { makeCommunicationReducer } from 'shared/helpers/redux';

// import { ReducersMap } from 'shared/types/redux';
// import * as NS from '../../namespace';

// import { initial } from '../initial';

// export const communicationReducer = combineReducers<NS.IReduxState['communication']>({

// } as ReducersMap<NS.IReduxState['communication']>);

export const communicationReducer = (state = {}) => state;

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';

import { IAppReduxState } from 'shared/types/app';
import { IFlatPage } from 'shared/types/models';

import { selectors } from './../../../redux';

import './FlatPage.scss';
import { Title } from 'shared/view/elements';

interface IOwnProps {
  slug: string;
}

interface IStateProps {
  page: IFlatPage | null;
}

type IProps = IStateProps & IOwnProps;

function mapState(state: IAppReduxState, props: IOwnProps): IStateProps {
  return {
    page: selectors.selectPage(state, props.slug),
  };
}

const b = block('flat-page');

class SomeContainer extends React.PureComponent<IProps> {
  public render() {
    const { page } = this.props;

    return (
      <div className={b()}>
        {!page ? (
          <>
            <Title text="Не найдено" />
            <p className={b('empty')()}>Страница не найдена</p>
          </>
         ) : (
          <>
            <Title text={page.title} />
            <div className={b('container')()} dangerouslySetInnerHTML={{ __html: page.content }} />
          </>
        )}
      </div>
    );
  }
}

export { SomeContainer };
export default connect<IStateProps, {}, IOwnProps>(mapState)(SomeContainer);

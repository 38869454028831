import { ICategory, IProduct } from 'shared/types/models';
import { last } from 'ramda';

export function compileCategoriesRoute(parents: ICategory[], item: ICategory) {
  const path = parents
    .concat([item])
    .reduce((res, cat) => res + '/' + cat.id, '/catalog');

  return path;
}

export function compileProductRoute(categoryParents: ICategory[], product: IProduct) {
  const parent = last(categoryParents);
  const category = parent ? parent.categories.find(a => a.id === product.categoryId) : null;

  if (category) {
    const path = compileCategoriesRoute(categoryParents, category);
    return `${path}/product/${product.id}`;
  } else {
    return `/catalog/product/${product.id}`;
  }
}

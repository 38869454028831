import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { connect, Dispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import * as auth from 'features/auth';
import { AddToBasket } from 'features/basket';
import * as searchItems from 'features/searchItems';
import * as viewCats from 'features/viewCategories';

import { Tabs, Paginator } from 'shared/view/elements';
import { IUser, ICategory, IProduct, IPaginated, TProductSorting, TAppliedProductFilter } from 'shared/types/models';
import { ViewSelector } from 'shared/view/components';
import { findParents } from 'shared/model/product';
import { compileCategoriesRoute, compileProductRoute } from 'modules/shared/routes';
import { IAppReduxState } from 'shared/types/app';
import { ICommunication } from 'shared/types/redux';

import './Search.scss';

interface IStateProps {
  user: IUser | null;
  categories: ICategory[];
  foundCategoriesCount: number;
  foundProductsCount: number;
  query: string;
}

interface IActionProps {
  searchProducts: typeof searchItems.actions.searchProducts;
  searchCategories: typeof searchItems.actions.searchCategories;
}

interface IState {
  view: 'list' | 'grid';
}

type Props = IStateProps & IActionProps & RouteComponentProps<any>;

const b = block('search-page');

function mapState(state: IAppReduxState): IStateProps {
  return {
    user: auth.selectors.selectUser(state),
    categories: viewCats.selectors.selectCategories(state),
    foundCategoriesCount: searchItems.selectors.selectCount(state).categories,
    foundProductsCount: searchItems.selectors.selectCount(state).products,
    query: searchItems.selectors.selectQuery(state),
  };
}

function mapActions(dispatch: Dispatch<any>): IActionProps {
  return bindActionCreators({
    searchProducts: searchItems.actions.searchProducts,
    searchCategories: searchItems.actions.searchCategories,
  }, dispatch);
}

const { SearchItems } = searchItems;
const { ProductsList, CategoriesGridView } = viewCats;

class Search extends React.PureComponent<Props, IState> {
  public state: IState = { view: 'grid' };
  private categoriesRef: HTMLDivElement | null = null;

  public render() {
    const { view } = this.state;
    const { foundProductsCount, foundCategoriesCount } = this.props;
    const categoryWidth = this.categoriesRef ? this.categoriesRef.clientWidth / 3 : void 0;

    return (
      <div className={b()}>
        <div className={b('tabs')()}>
          <div className={b('view-selector')()}>
            <ViewSelector
              view={this.state.view}
              onGridSelected={this.onGridSelect}
              onListSelected={this.onListSelect}
            />
          </div>

          <Tabs>
            <Tabs.Tab title={`Продукты (${foundProductsCount})`} id="products">
              <div className={b('results')()}>
                <SearchItems key={view} ProductsView={this.ProductsView} type="products" />
              </div>
            </Tabs.Tab>
            <Tabs.Tab title={`Категории (${foundCategoriesCount})`} id="categories">
              <div className={b('results')()} ref={this.onCategoriesRef}>
                <SearchItems
                  key={view}
                  itemWidth={categoryWidth}
                  CategoriesView={this.CategoriesView}
                  type="categories"
                />
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    );
  }

  private ProductsView = (
    props: {
      items: IPaginated<IProduct> & { sorting: TProductSorting; appliedFilters: TAppliedProductFilter[]; },
      loading: ICommunication;
    },
  ) => {
    return (
      <ProductsList
        disableSorting
        load={this.loadProducts}
        user={this.props.user}
        items={props.items}
        loading={props.loading.isRequesting}
        view={this.state.view}
        onSelect={this.onProductSelect}
        ExtraItem={AddToBasket}
      />
    );
  }

  private CategoriesView = (
    props: { categories: IPaginated<ICategory>; loading: ICommunication; itemWidth: number; },
  ) => {
    // tslint:disable:jsx-no-lambda
    return (
      <div>
        <CategoriesGridView
          categories={props.categories.items}
          loading={props.loading}
          width={props.itemWidth}
          onSelect={this.onCategorySelect}
        />
        <Paginator
          page={props.categories.page}
          size={props.categories.size}
          pages={Math.ceil(props.categories.total / props.categories.size)}
          onChange={page => this.loadCategories(page - 1, props.categories.size)}
          onSizeChange={size => this.loadCategories(0, size)}
        />
      </div>
    );
  }

  @bind
  private onGridSelect() {
    this.setState({ view: 'grid' });
  }

  @bind
  private onListSelect() {
    this.setState({ view: 'list' });
  }

  @bind
  private onCategoriesRef(ref: HTMLDivElement | null) {
    this.categoriesRef = ref;

    if (ref) { this.forceUpdate(); }
  }

  @bind
  private onCategorySelect(_: any, cat: ICategory) {
    const { categories } = this.props;
    const parents = findParents(categories, cat, a => a.id);
    const path = compileCategoriesRoute(parents, cat);
    this.props.history.push(path);
  }

  @bind
  private onProductSelect(product: IProduct) {
    const { categories, history } = this.props;
    const parents = findParents(categories, product, a => a.categoryId || '');
    const path = compileProductRoute(parents, product);
    history.push(path);
  }

  @bind
  private loadProducts({ sorting, page, size }: { page: number; size: number; sorting: TProductSorting }) {
    this.props.searchProducts({ query: this.props.query, sort: sorting, page, size });
  }

  @bind
  private loadCategories(page: number, size: number) {
    this.props.searchCategories({ page, size, query: this.props.query });
  }
}

export default connect<IStateProps>(mapState, mapActions)(Search);

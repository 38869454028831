import * as React from 'react';
import * as block from 'bem-cn';
import { Route, Link, RouteComponentProps } from 'react-router-dom';
// tslint:disable:no-duplicate-imports
import * as RouterDom from 'react-router-dom';
import './Breadcrumbs.scss';

interface IBreadCrumbsProps {
  getName: (match: RouterDom.match<any>) => string;
}

const b = block('breadcrumbs');

export default function Breadcrumbs({ getName }: IBreadCrumbsProps): React.ReactElement<any> {
  const renderer = (props: RouteComponentProps<any>) => <BreadcrumbsItem getName={getName} {...props} />;
  const mainRenderer = (props: RouteComponentProps<any>) => (
    <li className={b('crumb')()}>
      <Link to="/" className={b('link', { active: props.match.isExact })()}>{getName(props.match)}</Link>
    </li>
  );
  return (
    <div className={b()}>
      <ul className={b('container')()}>
        <Route path="/" render={mainRenderer} />
        <Route path="/:path" render={renderer} />
      </ul>
    </div>
  );
}

export function BreadcrumbsItem(
  { getName, match }: RouteComponentProps<any> & IBreadCrumbsProps,
): React.ReactElement<any> {
  const renderer = (props: RouteComponentProps<any>) => <BreadcrumbsItem getName={getName} {...props} />;
  const name = getName(match);
  return (
    <>
      {
        name ? (
          <li className={b('crumb')()}>
            <Link to={match.url || ''} className={b('link', { active: match.isExact })()}>{name}</Link>
          </li>
        ) : null
      }
      <Route path={`${match.url}/:path`} render={renderer} />
    </>
  );
}

import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  completed: signInSuccess,
  execute: signIn,
  failed: signInFail,
} = makeCommunicationActionCreators<
  NS.ISignIn, NS.ISignInSuccess, NS.ISignInFail
>(
  'AUTH:SIGN_IN', 'AUTH:SIGN_IN_SUCCESS', 'AUTH:SIGN_IN_FAIL',
);

export const {
  completed: signUpSuccess,
  execute: signUp,
  failed: signUpFail,
} = makeCommunicationActionCreators<
  NS.ISignUp, NS.ISignUpSuccess, NS.ISignUpFail
>(
  'AUTH:SIGN_UP', 'AUTH:SIGN_UP_SUCCESS', 'AUTH:SIGN_UP_FAIL',
);

export const {
  completed: checkUserSuccess,
  execute: checkUser,
  failed: checkUserFail,
} = makeCommunicationActionCreators<
  NS.ICheckUser, NS.ICheckUserSuccess, NS.ICheckUserFail
>(
  'AUTH:CHECK_USER', 'AUTH:CHECK_USER_SUCCESS', 'AUTH:CHECK_USER_FAIL',
);

export const {
  completed: updateUserSuccess,
  execute: updateUser,
  failed: updateUserFail,
} = makeCommunicationActionCreators<
  NS.IUpdateUser, NS.IUpdateUserSuccess, NS.IUpdateUserFail
>(
  'AUTH:UPDATE_USER', 'AUTH:UPDATE_USER_SUCCESS', 'AUTH:UPDATE_USER_FAIL',
);

export const {
  completed: startRestoreSuccess,
  execute: startRestore,
  failed: startRestoreFail,
} = makeCommunicationActionCreators<
  NS.IStartRestore, NS.IStartRestoreSuccess, NS.IStartRestoreFail
>(
  'AUTH:START_RESTORE', 'AUTH:START_RESTORE_SUCCESS', 'AUTH:START_RESTORE_FAIL',
);

export const {
  completed: finishRestoreSuccess,
  execute: finishRestore,
  failed: finishRestoreFail,
} = makeCommunicationActionCreators<
  NS.IFinishRestore, NS.IFinishRestoreSuccess, NS.IFinishRestoreFail
>(
  'AUTH:FINISH_RESTORE', 'AUTH:FINISH_RESTORE_SUCCESS', 'AUTH:FINISH_RESTORE_FAIL',
);

export const {
  completed: changePasswordSuccess,
  execute: changePassword,
  failed: changePasswordFail,
} = makeCommunicationActionCreators<
  NS.IChangePassword, NS.IChangePasswordSuccess, NS.IChangePasswordFail
>(
  'AUTH:CHANGE_PASSWORD', 'AUTH:CHANGE_PASSWORD_SUCCESS', 'AUTH:CHANGE_PASSWORD_FAIL',
);

export function logout(): NS.ILogout {
  return { type: 'AUTH:LOGOUT' };
}

import { takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IDependencies } from 'shared/types/app';
import { requestSaga } from 'shared/helpers/redux';
import { IBasket } from 'shared/types/models';
import {
  loadBasketSuccess, loadBasketFail, saveBasketSuccess,
  saveBasketFail, deleteItemSuccess, deleteItemFail,
} from '../actions';
import * as NS from '../../namespace';
import { selectItems } from '../selectors';
import { mkBasket } from 'shared/model/product';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadBasketType: NS.ILoadBasket['type'] = 'BASKET:LOAD';
    const saveBasketType: NS.ISaveBasket['type'] = 'BASKET:SAVE';
    const clearBasketType: NS.IClearBasket['type'] = 'BASKET:CLEAR';
    const deleteItemType: NS.IDeleteBasketItem['type'] = 'BASKET:DELETE_ITEM';
    yield [
      takeLatest(loadBasketType, loadBasketSaga, deps),
      takeLatest(saveBasketType, saveBasketSaga, deps),
      takeLatest(deleteItemType, deleteItemSaga, deps),
      takeLatest(clearBasketType, clearBasketSaga, deps),
    ];
  };
}

function* loadBasketSaga(deps: IDependencies, { payload: user }: NS.ILoadBasket) {
  yield* requestSaga(() => deps.api.loadBasket(user), loadBasketSuccess, loadBasketFail);
}

function* clearBasketSaga(deps: IDependencies, { payload: { user } }: NS.IClearBasket) {
  yield* requestSaga(() => deps.api.saveBasket(user, mkBasket([])), saveBasketSuccess, saveBasketFail);
}

function* saveBasketSaga(deps: IDependencies, action: NS.ISaveBasket | NS.IChangeBasketCount) {
  const user = action.payload.user;
  const basket: IBasket = yield select(selectItems);
  const call = action.type === 'BASKET:SAVE' ?
    () => deps.api.saveBasket(user, basket, action.payload.cleanLocal) :
    () => deps.api.saveBasket(user, basket);
  yield* requestSaga(call, saveBasketSuccess, saveBasketFail);
}

function* deleteItemSaga(deps: IDependencies, { payload: { item, user } }: NS.IDeleteBasketItem) {
  yield* requestSaga(
    () => deps.api.deleteBasketItem(user, item),
    deleteItemSuccess,
    deleteItemFail,
  );
}

export { getSaga };

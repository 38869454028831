import * as React from 'react';
import * as block from 'bem-cn';
import InlineSvg from 'svg-inline-react';
import * as Logo from './img/logo.svg';
import * as SubTitle from './img/subtitle.svg';
import './Header.scss';

interface IProps {
  underPhoneContent: React.ReactElement<any>;
  firstMenuPart: React.ReactElement<any>;
  secondMenuPart: React.ReactElement<any>;
}

class Header extends React.PureComponent<IProps, {}> {
  public render() {
    const b = block('header');
    const { underPhoneContent, secondMenuPart, firstMenuPart } = this.props;

    return (
      <div className={b()}>
        <div className={b('logo')()}>
          <InlineSvg className={b('logo-icon')()} element="div" src={Logo} />
          <div className={b('titles')()}>
            <p className={b('title')()}>ООО "Папирус"</p>
            <InlineSvg className={b('sub-title')()} element="div" src={SubTitle} />
          </div>
        </div>

        <div className={b('block')()}>
          <div className={b('phones')()}>
            <p className={b('phone')()}>8 (3822) 90-07-90&nbsp;</p>
            {/* <p className={b('phone')()}>8 (3842) 555777</p> */}
          </div>
          <div className={b('under-phone-content')()}>
            {underPhoneContent}
          </div>
        </div>

        <div className={b('menu-part')()}>
          {firstMenuPart}
        </div>
        <div className={b('menu-part')()}>
          {secondMenuPart}
        </div>
      </div>
    );
  }
}

export default Header;

export function repeat<T>(count: number, creator: (index: number) => T): T[] {
  if (count <= 0) { return []; }

  const result: T[] = [];

  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }

  return result;
}

export function sortByLetter<T>(get: (o: T) => string) {
  return (a: T, b: T) => get(a).toLowerCase() < get(b).toLowerCase() ? -1 : 1;
}

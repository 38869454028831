import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import './Tabs.scss';

interface IProps {
  children: React.ReactChild[];
  activeTab?: string;
}

interface ITabProps {
  title: string;
  id: string;
  children: any;
}

interface IState {
  selectedTabId: string;
}

const b = block('tabs');

class Tabs extends React.PureComponent<IProps, IState> {
  public static Tab: typeof Tab = Tab;

  public state: IState = { selectedTabId: this.getTabs()[0].id };

  public componentDidMount() {
    if (this.props.activeTab) {
      this.setState({ selectedTabId: this.props.activeTab });
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.activeTab !== this.props.activeTab && nextProps.activeTab) {
      this.setState({ selectedTabId: nextProps.activeTab });
    }
  }

  public render() {
    const { } = this.props;
    const { selectedTabId } = this.state;
    const elements = this.getTabs();
    const selectedTabElement = elements.find(e => e.content.props.id === selectedTabId);

    return (
      <div className={b()}>
        <div className={b('header')()}>
          {elements.map((e, i) => (
            <button
              key={e.id}
              className={b('tab-head', { active: e.id === selectedTabId })()}
              onClick={this.selectTab.bind(this, e.id)}
            >
              <h3 key={i} className={b('tab-title')()}>
                {e.title}
              </h3>
            </button>
          ))}
        </div>

        <div className={b('content')()}>
          {selectedTabElement ? selectedTabElement.content : null}
        </div>
      </div>
    );
  }

  public getTabs() {
    return React.Children.map(
      this.props.children,
      (tab: React.ReactElement<ITabProps>) => ({
        title: tab.props.title,
        id: tab.props.id,
        content: tab,
      }),
    );
  }

  @bind
  private selectTab(id: string) {
    this.setState({ selectedTabId: id });
  }
}

function Tab(props: ITabProps) {
  return props.children;
}

export default Tabs;

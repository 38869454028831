import { combineReducers } from 'redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import { initial } from '../initial';
import { makeCommunicationReducer } from 'shared/helpers/redux';

export const communicationReducer = combineReducers<NS.IReduxState['communication']>({
  categoriesLoading: makeCommunicationReducer<
    NS.ILoadCategories, NS.ILoadCategoriesSuccess, NS.ILoadCategoriesFail
  >(
    'VIEW_CATEGORIES:LOAD_CATEGORIES',
    'VIEW_CATEGORIES:LOAD_CATEGORIES_SUCCESS',
    'VIEW_CATEGORIES:LOAD_CATEGORIES_FAIL',
    initial.communication.categoriesLoading,
  ),
  categoryLoading: makeCommunicationReducer<
    NS.ILoadCategory, NS.ILoadCategorySuccess, NS.ILoadCategoryFail
  >(
    'VIEW_CATEGORIES:LOAD_CATEGORY',
    'VIEW_CATEGORIES:LOAD_CATEGORY_SUCCESS',
    'VIEW_CATEGORIES:LOAD_CATEGORY_FAIL',
    initial.communication.categoryLoading,
  ),
  productsLoading: makeCommunicationReducer<
    NS.ILoadCategoryProducts, NS.ILoadCategoryProductsSuccess, NS.ILoadCategoryProductsFail
  >(
    'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS',
    'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS_SUCCESS',
    'VIEW_CATEGORIES:LOAD_CATEGORY_PRODUCTS_FAIL',
    initial.communication.productsLoading,
  ),
  productLoading: makeCommunicationReducer<
    NS.ILoadProduct, NS.ILoadProductSuccess, NS.ILoadProductFail
  >(
    'VIEW_CATEGORIES:LOAD_PRODUCT',
    'VIEW_CATEGORIES:LOAD_PRODUCT_SUCCESS',
    'VIEW_CATEGORIES:LOAD_PRODUCT_FAIL',
    initial.communication.productLoading,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

import * as NS from '../../namespace';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

export const {
  execute: loadDeliveryPoints,
  completed: loadDeliveryPointsSuccess,
  failed: loadDeliveryPointsFail,
} = makeCommunicationActionCreators<
  NS.ILoadDeliveryPoints, NS.ILoadDeliveryPointsSuccess, NS.ILoadDeliveryPointsFail
>(
  'ORDERS:LOAD_DELIVERY_POINTS', 'ORDERS:LOAD_DELIVERY_POINTS_SUCCESS', 'ORDERS:LOAD_DELIVERY_POINTS_FAIL',
);

export const {
  execute: createOrder,
  completed: createOrderSuccess,
  failed: createOrderFail,
} = makeCommunicationActionCreators<
  NS.ICreateOrder, NS.ICreateOrderSuccess, NS.ICreateOrderFail
>(
  'ORDERS:CREATE', 'ORDERS:CREATE_SUCCESS', 'ORDERS:CREATE_FAIL',
);

export const {
  execute: loadOrdersHistory,
  completed: loadOrdersHistorySuccess,
  failed: loadOrdersHistoryFail,
} = makeCommunicationActionCreators<
  NS.ILoadOrderHistory, NS.ILoadOrderHistorySuccess, NS.ILoadOrderHistoryFail
>(
  'ORDERS:LOAD_ORDER_HISTORY', 'ORDERS:LOAD_ORDER_HISTORY_SUCCESS', 'ORDERS:LOAD_ORDER_HISTORY_FAIL',
);

import * as NS from '../namespace';

const initial: NS.IReduxState = {
  communication: {
    loading: {
      error: '',
      isRequesting: false,
    },
  },
  data: {
    shops: [],
  },
};

export default initial;

import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { INews } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { bindActionCreators } from 'redux';
import { Title } from 'shared/view/elements';
import { ICommunication } from 'shared/types/redux';
import { actions, selectors } from '../../../redux';
import './NewsDetails.scss';

interface IOwnProps {
  id: string;
}

interface IActionProps {
  load: typeof actions.loadNewsDetails;
}

interface IStateProps {
  news: INews | null;
  loading: ICommunication;
}

type Props = IOwnProps & IActionProps & IStateProps;

const b = block('news-details');

function mapState(state: IAppReduxState): IStateProps {
  return {
    news: selectors.selectDetailedNews(state),
    loading: selectors.selectLoading(state),
  };
}

function mapActions(dispatch: any) {
  return bindActionCreators({
    load: actions.loadNewsDetails,
  }, dispatch);
}

class NewsDetails extends React.PureComponent<Props, {}> {
  public componentDidMount() {
    this.props.load({ id: this.props.id });
  }

  public render() {
    const { news, loading } = this.props;
    return (
      <div className={b()}>
        {loading.isRequesting ?  <Title text="Загрузка..." /> : null}
        {!loading.isRequesting && !news ? (
          <>
            <Title text="Новость не найдена" />
            <div className={b('empty')()}>Не найдено</div>
          </>
         ) : null}

        {
          !loading.isRequesting && news ? (
            <>
              <div className={b('content-wrapper')()}>
                <Title text={news.title} />
                <div className={b('title-photo')()}>
                  <img src={news.image} />
                </div>
                <div className={b('content')()} dangerouslySetInnerHTML={{ __html: news.content }} />
              </div>
              <p className={b('date')()}>{this.formatDate(new Date(news.date))}</p>
            </>
          ) : null
        }

      </div>
    );
  }

  private formatDate(date: Date) {
    const pad = (a: number) => String(a).padStart(2, '0');
    return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`;
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapState, mapActions)(NewsDetails);

import * as NS from '../../namespace';
import { IUser, TPaymentType, TDeliveryType, IDeliveryPoint } from 'shared/types/models';
import { validateNotEmptyString, validatePhone } from 'shared/helpers';

export function startOrdering(user: IUser): NS.IStartOrdering {
  return { type: 'ORDERS:START_ORDERING', payload: user };
}

export function changePhone(phone: string): NS.IChangePhone {
  const cleared = phone.replace(/\s/g, '').replace('+', '');
  const error = validateNotEmptyString(cleared) || validatePhone(cleared, '+7');
  return { type: 'ORDERS:CHANGE_PHONE', payload: { value: cleared, error } };
}

export function changeComment(comment: string): NS.IChangeComment {
  return { type: 'ORDERS:CHANGE_COMMENT', payload: comment };
}

export function changeFullName(name: string): NS.IChangeFullName {
  const error = validateNotEmptyString(name);
  return { type: 'ORDERS:CHANGE_FULLNAME', payload: { value: name, error } };
}

export function changePaymentType(type: TPaymentType): NS.IChangePaymentType {
  return { type: 'ORDERS:CHANGE_PAYMENT_TYPE', payload: type };
}

export function changeDeliveryType(type: TDeliveryType): NS.IChangeDeliveryType {
  return { type: 'ORDERS:CHANGE_DELIVERY_TYPE', payload: type };
}

export function changeDeliveryPoint(point: IDeliveryPoint): NS.IChangeDeliveryPoint {
  return { type: 'ORDERS:CHANGE_DELIVERY_POINT', payload: point };
}

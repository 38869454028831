import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { bind } from 'decko';
import { bindActionCreators, Dispatch } from 'redux';
import { ClipLoader } from 'react-spinners';

import { IUser } from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { Input, PhoneInput, Modal } from 'shared/view/elements';
import { IReduxField, ICommunication } from 'shared/types/redux';
import { isSuccessedByState } from 'shared/helpers/redux';

import ChangePassword from '../ChangePassword/ChangePassword';
import { selectors, actions } from '../../../redux';
import './PersonalSettings.scss';

interface IStateProps {
  user: IUser | null;
  firstName: IReduxField<string>;
  lastName: IReduxField<string>;
  phone: IReduxField<string>;
  address: IReduxField<string>;
  submitted: boolean;
  updating: ICommunication;
}

interface IActionProps {
  editUser: typeof actions.editUser;
  changeFirstName: typeof actions.changeUserFirstName;
  changeLastName: typeof actions.changeUserLastName;
  changePhone: typeof actions.changeUserPhone;
  changeAddress: typeof actions.changeUserAddress;
  updateUser: typeof actions.updateUser;
}

type Props = IStateProps & IActionProps;

function mapState(state: IAppReduxState): IStateProps {
  return {
    user: selectors.selectUser(state),
    submitted: selectors.selectIsPersonalDataSubmitted(state),
    updating: selectors.selectCommunication(state, 'updating'),
    ...selectors.selectEditingProfile(state),
  };
}

function mapActions(dispatch: Dispatch<any>) {
  return bindActionCreators({
    editUser: actions.editUser,
    changeFirstName: actions.changeUserFirstName,
    changeLastName: actions.changeUserLastName,
    changePhone: actions.changeUserPhone,
    changeAddress: actions.changeUserAddress,
    updateUser: actions.updateUser,
  }, dispatch);
}

interface IState {
  mode: 'read' | 'edit';
  isChangePasswordOpened: boolean;
}

const b = block('personal-settings');

class PersonalSettings extends React.PureComponent<Props, IState> {
  public state: IState = { mode: 'read', isChangePasswordOpened: false };

  public componentWillReceiveProps(nextProps: Props) {
    if (isSuccessedByState(this.props.updating, nextProps.updating)) {
      this.setState({ mode: 'read' });
    }
  }

  public render() {
    const { user, firstName, lastName, phone, address, submitted, updating } = this.props;
    const { changeAddress, changeFirstName, changeLastName, changePhone } = this.props;
    const { isChangePasswordOpened } = this.state;
    const { mode } = this.state;

    if (!user) { return null; }

    return (
      <section className={b()}>
        <header className={b('header')()}>
          <h2 className={b('title')()}>Мои данные</h2>
        </header>
        <div className={b('content')()}>
          <div className={b('fields')()}>
            <div className={b('field')()}>
              <p className={b('label')()}>Имя</p>
              <div className={b('value')()}>
                <Field
                  showError={submitted}
                  field={firstName}
                  mode={mode}
                  value={user.firstName}
                  onChange={changeFirstName}
                  placeholder="Имя"
                />
              </div>
            </div>
            <div className={b('field')()}>
              <p className={b('label')()}>Фамилия</p>
              <div className={b('value')()}>
                <Field
                  showError={submitted}
                  field={lastName}
                  mode={mode}
                  value={user.lastName}
                  onChange={changeLastName}
                  placeholder="Фамилия"
                />
              </div>
            </div>
            <div className={b('field')()}>
              <p className={b('label')()}>Телефон</p>
              <div className={b('value')()}>
                <Field
                  showError={submitted}
                  field={phone}
                  mode={mode}
                  value={user.phone}
                  onChange={changePhone}
                  type="phone"
                  placeholder="Телефон"
                />
              </div>
            </div>
            <div className={b('field')()}>
              <p className={b('label')()}>Адрес</p>
              <div className={b('value')()}>
                <Field
                  showError={submitted}
                  field={address}
                  mode={mode}
                  value={user.address}
                  onChange={changeAddress}
                  placeholder="Адрес"
                />
              </div>

              {
                mode === 'read' ? (
                  <button
                    onClick={this.onEditClick}
                    title="Редактировать данные"
                    type="button"
                    className={b('action')()}
                  >
                    Редактировать
                  </button>
                ) : null
              }

              {
                mode === 'edit' ? (
                  <div className={b('actions')()}>
                    <button
                      onClick={this.onSubmitClick}
                      title="Подтвердить изменение"
                      type="button"
                      className={b('action')()}
                    >
                      Сохранить
                    </button>
                    <button
                      onClick={this.onDeclineEditClick}
                      title="Отменить изменение"
                      type="button"
                      className={b('action')()}
                    >
                      Отменить
                    </button>
                  </div>
                ) : null
              }
            </div>
            <div className={b('field')()}>
              <p className={b('label')()}>E-mail</p>
              <div className={b('value')()}>
                {user.email || 'Не указано'}
              </div>
              <button title="Сменить пароль" type="button" className={b('action')()} onClick={this.openChangePassword}>
                Сменить пароль
              </button>
            </div>
          </div>

          {updating.isRequesting ? (
            <div className={b('loader')()}><ClipLoader size={30} color="#009846"  /></div>
           ) : null}
        </div>

        <Modal isOpen={isChangePasswordOpened} onRequestClose={this.closeChangePassword}>
          <ChangePassword onSuccess={this.closeChangePassword} />
        </Modal>
      </section>
    );
  }

  @bind
  private onEditClick() {
    if (!this.props.user) { return; }

    this.setState({ mode: 'edit' });
    this.props.editUser(this.props.user);
    this.props.changeFirstName(this.props.user.firstName);
    this.props.changeLastName(this.props.user.lastName);
    this.props.changePhone(this.props.user.phone);
  }

  @bind
  private onSubmitClick() {
    this.props.updateUser();
  }

  @bind
  private onDeclineEditClick() {
    this.setState({ mode: 'read' });
  }

  @bind
  private openChangePassword() {
    this.setState({ isChangePasswordOpened: true });
  }

  @bind
  private closeChangePassword() {
    this.setState({ isChangePasswordOpened: false });
  }
}

interface IFieldProps {
  mode: IState['mode'];
  type?: 'phone';
  placeholder: string;
  value: string;
  field: IReduxField<string>;
  showError?: boolean;
  onChange?: (value: string) => void;
}

function Field({ mode, placeholder, value, field, showError, onChange, type }: IFieldProps) {
  return mode === 'edit' ? (
    <>
      {type === 'phone' ? (
        <PhoneInput phone={field.value} onChange={onChange} />
      ) : (
        <Input value={field.value} placeholder={placeholder} onChange={onChange} />
      )}
      {showError && field.error ? <p className={b('field-error')()}>{field.error}</p> : null}
    </>
  ) : (
    <>{value || 'Не указано'}</>
  );
}

export default connect<IStateProps, IActionProps>(mapState, mapActions)(PersonalSettings);

import { head } from 'ramda';
import * as NS from '../../namespace';
import { initial } from '../initial';

export function editReducer(state: NS.IReduxState['edit'] = initial.edit, action: NS.Action): NS.IReduxState['edit'] {
  switch (action.type) {
    case 'ORDERS:START_ORDERING': {
      const fullName = `${action.payload.firstName} ${action.payload.lastName}`.trim();
      return {
        ...state,
        order: {
          ...state.order,
          personPhone: { error: '', value: action.payload.phone },
          personFullName: { error: '', value: fullName },
        },
      };
    }
    case 'ORDERS:LOAD_DELIVERY_POINTS_SUCCESS': {
      return {
        ...state,
        order: {
          ...state.order,
          deliveryPoint: head(action.payload) || null,
        },
      };
    }
    case 'ORDERS:CHANGE_DELIVERY_TYPE': {
      return {
        ...state,
        order: { ...state.order, delivery: action.payload },
      };
    }
    case 'ORDERS:CHANGE_PAYMENT_TYPE': {
      return {
        ...state,
        order: { ...state.order, payment: action.payload },
      };
    }
    case 'ORDERS:CHANGE_COMMENT': {
      return {
        ...state,
        order: { ...state.order, comment: action.payload },
      };
    }
    case 'ORDERS:CHANGE_PHONE': {
      return {
        ...state,
        order: { ...state.order, personPhone: action.payload },
      };
    }
    case 'ORDERS:CHANGE_FULLNAME': {
      return {
        ...state,
        order: { ...state.order, personFullName: action.payload },
      };
    }
    case 'ORDERS:CHANGE_DELIVERY_POINT': {
      return {
        ...state,
        order: {
          ...state.order,
          deliveryPoint: action.payload,
        },
      };
    }
    case 'ORDERS:CREATE': {
      return { ...state, order: { ...state.order, submited: true } };
    }
    case 'ORDERS:CREATE_SUCCESS': {
      return { ...initial.edit };
    }
    default: return state;
  }
}

import { call, put } from 'redux-saga/effects';
import { IAction, IPlainFailAction, IPlainAction } from '../../types/redux';
import getErrorMsg from '../getErrorMsg';

export * from './namespace';
export * from './multiConnect';
export * from './communication';
export * from './field';
export { default as composeReducers } from './composeReducers';
export { default as makeIdentityReducer } from './makeIdentityReducer';
export { default as makeResetStateReducer } from './makeResetStateReducer';
export { default as validateFields } from './validateFields';
export { default as isSuccessedByState } from './isSuccessedByState';

export function* requestSaga<P>(
  requester: () => Promise<P>,
  success: (payload: P) => IAction<any, P>,
  fail: (err: string) => IPlainFailAction<any>,
) {
  try {
    const response: P = yield call(requester);
    yield put(success(response));
  } catch (error) {
    const msg = getErrorMsg(error);
    yield put(fail(msg));
  }
}

export function* methodSaga(
  requester: () => Promise<void>,
  success: () => IPlainAction<any>,
  fail: (err: string) => IPlainFailAction<any>,
) {
  try {
    yield call(requester);
    yield put(success());
    return true;
  } catch (error) {
    const msg = getErrorMsg(error);
    yield put(fail(msg));
    return false;
  }
}

import * as NS from '../namespace';

export const initial: NS.IReduxState = {
  communication: {
    categoriesLoading: {
      error: '',
      isRequesting: false,
    },
    categoryLoading: {
      error: '',
      isRequesting: false,
    },
    productsLoading: {
      error: '',
      isRequesting: false,
    },
    productLoading: {
      error: '',
      isRequesting: false,
    },
  },
  data: {
    categories: [],
    category: null,
    product: null,
  },
};

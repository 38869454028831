import * as namespace from './namespace';
import { reducer, getSaga } from './redux';
import { IReduxEntry } from 'shared/types/app';

const reduxEntry: IReduxEntry = {
  reducers: { shops: reducer },
  sagas: [getSaga],
};

export * from './redux';
export * from './view';
export { namespace, reduxEntry };

import { IProduct, ICategory, IBasketItem, IBasket } from '../types/models';
import { sum, map } from 'ramda';

export function isExists(product: IProduct) {
  return product.count > 0;
}

export function findParents<T>(cats: ICategory[], item: T, getId: (a: T) => string): ICategory[] {
  function isDirectParent(parent: ICategory) {
    return Boolean(parent.categories.find(c => c.id === getId(item)));
  }

  for (const c of cats) {
    if (isDirectParent(c)) {
      return [c];
    } else {
      const parents = findParents(c.categories, item, getId);
      if (parents.length) {
        return [c, ...parents];
      }
    }
  }

  return [];
}

export function findCategoryById(id: string, items: ICategory[]): ICategory | null {
  for (const item of items) {
    if (item.id === id) { return item; }
    const nested = findCategoryById(id, item.categories);
    if (nested) { return nested; }
  }

  return null;
}

export function mkBasket(items: IBasketItem[]): IBasket {
  const summary = sum(map(item => item.price * item.basketCount, items));
  return {
    items,
    summaryPrice: Math.max(0, summary),
  };
}

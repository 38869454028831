import * as React from 'react';
import * as block from 'bem-cn';
import InlineSvg from 'svg-inline-react';
import { IShop } from 'shared/types/models';
import * as EnvelopeIcon from './img/envelope.svg';
import * as PencilIcon from './img/pencil.svg';
import * as PhoneIcon from './img/phone.svg';
import * as CalendarIcon from './img/calendar.svg';
import * as PlacemarkIcon from './img/placemark.svg';
import './ShopDetails.scss';

interface IProps {
  shop: IShop;
}

const b = block('shop-details');

class ShopDetails extends React.PureComponent<IProps> {
  public render() {
    const { shop } = this.props;
    return (
      <div className={b()}>
        <h3 className={b('title')()}>{shop.name}</h3>
        <div className={b('info')()}>
          <InfoItem icon={PlacemarkIcon}><>{shop.city}, {shop.address}</></InfoItem>
          <InfoItem icon={PhoneIcon}><a href={`tel:${shop.phone}`}>{shop.phone}</a></InfoItem>
          <InfoItem icon={EnvelopeIcon}><a href={`mailto:${shop.mail}`}>{shop.mail}</a></InfoItem>
          <InfoItem icon={CalendarIcon} hide={!shop.workingTime}><>{shop.workingTime}</></InfoItem>
          <InfoItem icon={PencilIcon} hide={!shop.descr}><>{shop.descr}</></InfoItem>
        </div>
      </div>
    );
  }
}

function InfoItem({ children, icon, hide }: { children: React.ReactElement<any>; hide?: boolean; icon: any; }) {
  if (hide) { return null; }

  return (
    <div className={b('info-item')()}>
      <InlineSvg className={b('icon')()} element="div" src={icon} />
      <p className={b('text')()}>{children}</p>
    </div>
   );
}

export default ShopDetails;

import * as React from 'react';
import * as block from 'bem-cn';
import './RowsLayout.scss';

interface IProps {
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  headerContent?: React.ReactNode;
}

class RowsLayout extends React.PureComponent<IProps> {
  public render() {
    const b = block('rows-layout');
    const { children, footerContent, headerContent } = this.props;

    return (
      <div className={b()}>
        {Boolean(headerContent) && <header className={b('header')()}>{headerContent}</header>}
        <main className={b('content')()}>
          {children}
        </main>
        {Boolean(footerContent) && <footer className={b('footer')()}>{footerContent}</footer>}
      </div>
    );
  }
}

export default RowsLayout;

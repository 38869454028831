export type TFunc<R = void> = () => R;
export type TFunc1<A, R = void> = (a1: A) => R;
export type TFunc2<A1, A2, R = void> = (a1: A1, a2: A2) => R;
export type TFunc3<A1, A2, A3, R = void> = (a1: A1, a2: A2, a3: A3) => R;

export function range(start: number, step: number, end: number): number[] {
  const result: number[] = [];

  for (let i = start; i <= end; i += step) {
    result.push(i);
  }

  return result;
}

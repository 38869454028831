import { combineReducers } from 'redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import communication from './communication';
import edit from './edit';
import data from './data';
import view from './view';

export default combineReducers<NS.IReduxState>({
  communication, edit, data, view,
} as ReducersMap<NS.IReduxState>);

import * as NS from '../namespace';

export const initial: NS.IReduxState = {
  communication: {
    deliveryPointsLoading: {
      error: '',
      isRequesting: false,
    },
    creatingOrder: {
      error: '',
      isRequesting: false,
    },
  },
  data: {
    deliveryPoints: [],
    ordersHistory: [],
    createdOrder: null,
  },
  edit: {
    order: {
      submited: false,
      comment: '',
      delivery: 'own',
      payment: 'money',
      deliveryPoint: null,
      personFullName: {
        error: '', value: '',
      },
      personPhone: {
        error: '', value: '',
      },
    },
  },
};

export function validatePhone(phone: string, phonePrefix: string): string {
  return validateOnlyDigits(phone) || validateDigitsAmount(phone, 13 - phonePrefix.length);
}

export function validateDigitsAmount(value: string, length: number) {
  return `${value}`.length !== length ? 'Необходимо ' + length + ' цифр' : '';
}

export function validateNotEmptyString(value: string): string {
  return !value.trim().length ? 'Поле обязательно' : '';
}

export function validateOnlyDigits(value: string): string {
  return !/^\d+$/.test(value) ? 'Только цифры' : '';
}

export function validateCount(value: string, count: number): string {
  return value.length > count ? 'Слишком длинное значение' : '';
}

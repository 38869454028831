import * as uuid from 'uuid';
import * as NS from '../../namespace';
import { IProduct, IBasketItem, IUser } from 'shared/types/models';

export function addToBasket(product: IProduct, count: number): NS.IAddToBasket {
  return { type: 'BASKET:ADD', payload: {...product, basketCount: count, basketItemId: uuid.v4() } };
}

export function changeBasketCount(product: IBasketItem, count: number, user: IUser | null): NS.IChangeBasketCount {
  return { type: 'BASKET:CHANGE_ITEM_COUNT', payload: { item: product, count, user } };
}

export function clearBasket(user: IUser | null): NS.IClearBasket {
  return { type: 'BASKET:CLEAR', payload: { user } };
}

import * as NS from '../../namespace';
import initial from '../initial';

function editReducer(state: NS.IReduxState['edit']= initial.edit, action: NS.Action): NS.IReduxState['edit'] {
  switch (action.type) {
    case 'SEARCH:CHANGE_QUERY': {
      return { ...state, query: action.payload };
    }
    default: return state;
  }
}

export default editReducer;

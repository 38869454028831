import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): NS.IReduxState['data'] {
  switch (action.type) {
    case 'VIEW_NEWS:LOAD_NEWS_SUCCESS': {
      return { ...state, news: action.payload };
    }
    case 'VIEW_NEWS:LOAD_NEWS_DETAILS_SUCCESS': {
      return { ...state, detailedNews: action.payload };
    }
    default: return state;
  }
}

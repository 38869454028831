import * as React from 'react';
import ContentLoader from 'react-content-loader';

interface IProps {
  width?: number;
  margin?: number;
  type: 'card' | 'row';
}

class ProductPreloader extends React.PureComponent<IProps, {}> {
  public render() {
    const { width, margin, type } = this.props;
    return type === 'card' ? (
      <div style={{ width: `${width}px`, height: '160px', margin: `${margin}px` }}>
        <ContentLoader height={160} width={width}>
          <rect x="3" y="80" rx="3" ry="3" width={width || '250'} height="6.5" />
          <rect x="3" y="100" rx="3" ry="3" width={width || '250'} height="6.5" />
          <rect x="2" y="120" rx="3" ry="3" width={width || '250'} height="6.5" />
          <rect x="3" y="0.05" rx="0" ry="0" width={width || '250'} height="62" />
          <rect x="4" y="140" rx="0" ry="0" width={width || '250'} height="24" />
        </ContentLoader>
      </div>
    ) : (
      <div style={{ height: '100%', margin: `${(margin || 0) + 5}px` }}>
        <ContentLoader height={40}>
          <rect x="0" y="0" rx="0" ry="0" width="40" height="40" />
          <rect x="50" y="2.05" rx="0" ry="0" width="72%" height="5" />
          <rect x="50" y="18" rx="0" ry="0" width="72%" height="5" />
          <rect x="50" y="33" rx="0" ry="0" width="72%" height="5" />
          <rect x="89.5%" y="1.44" rx="0" ry="0" width="50" height="9" />
          <rect x="89.5%" y="24" rx="0" ry="0" width="50" height="15" />
        </ContentLoader>
      </div>
    );
  }
}

export default ProductPreloader;

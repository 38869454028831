import { ReactElement } from 'react';
import { RouteProps } from 'react-router';
import { Store, Reducer, ActionCreator, Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import * as homeModuleNamespace from 'modules/Home/namespace';
import { namespace as viewCategoriesNamespace } from 'features/viewCategories';
import { namespace as viewNewsNamespace } from 'features/viewNews';
import { namespace as basketNamespace } from 'features/basket';
import { namespace as authNamespace } from 'features/auth';
import { namespace as ordersNamespace } from 'features/orders';
import { namespace as shopsNamespace } from 'features/shops';
import { namespace as flatPagesNamespace } from 'features/flatPages';

import Api from 'services/api/Api';
import { ILogout } from 'features/auth/namespace';

export abstract class Module<C = any> {
  public getRoutes?(): ReactElement<RouteProps> | Array<ReactElement<RouteProps>>;
  public getReduxEntry?(): IReduxEntry;
}

export interface IAppData {
  modules: Module[];
  store: Store<IAppReduxState>;
}

export interface IDependencies {
  api: Api;
}

export type IDictionary<T, S extends keyof any = string> = {
  [key in S]: T;
};

export interface IReduxEntry {
  reducers?: { [key in keyof IAppReduxState]?: Reducer<IAppReduxState[key]> };
  sagas?: Array<(deps: IDependencies) => () => SagaIterator>;
}

export interface IFeatureEntry<
  C extends IDictionary<React.ReactType<any>, keyof C> | void,
  A extends IDictionary<ActionCreator<Action>, keyof A> | void,
  S extends IDictionary<(state: any, ...args: any[]) => any, keyof S> | void,
  > extends IReduxEntry {
  actions?: A;
  selectors?: S;
  containers?: C;
}

export interface IAppReduxState {
  searchItems: any;
  basket: basketNamespace.IReduxState;
  auth: authNamespace.IReduxState;
  orders: ordersNamespace.IReduxState;
  viewCategories: viewCategoriesNamespace.IReduxState;
  viewNews: viewNewsNamespace.IReduxState;
  shops: shopsNamespace.IReduxState;
  flatPages: flatPagesNamespace.IReduxState;
  home: homeModuleNamespace.IReduxState;
}

export type Diff<T extends keyof any, U extends keyof any> =
  ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];

export type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>;

export type GetProps<T extends React.ComponentType<any>> =
  T extends React.StatelessComponent<infer SP> ? SP :
  T extends React.ComponentClass<infer CP> ? CP : never;

export type RootSaga = (deps: IDependencies) => () => SagaIterator;

export type Lang = 'en' | 'he';

export type Uid = number;

export interface IAssets {
  javascript: string[];
  styles: string[];
}

export type TCommonAction = ILogout;

export * from '../helpers/redux/namespace';

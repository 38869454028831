import * as NS from '../namespace';

export const initial: NS.IReduxState = {
  communication: {
    detailsNewsLoading: {
      error: '',
      isRequesting: false,
    },
  },
  data: {
    news: [],
    detailedNews: null,
  },
};

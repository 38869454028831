import * as React from 'react';
import * as block from 'bem-cn';
import { Shops } from 'features/shops';
import { Title } from 'shared/view/elements';

const b = block('shops-layout');

class ShopsLayout extends React.PureComponent<{}> {
  public render() {
    return (
      <div className={b()}>
        <Title text="Магазины" />
        <Shops />
      </div>
    );
  }
}

export default ShopsLayout;

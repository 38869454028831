import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import MaskedInput from 'react-maskedinput';

interface IProps {
  phone: string;
  placeholder?: string;
  onChange?: (phone: string) => void;
}

const b = block('input'); // TODO: union with simple input by type

class PhoneInput extends React.PureComponent<IProps, {}> {
  public render() {
    const { phone, placeholder } = this.props;
    return (
      <div className={b()}>
        <MaskedInput
          className={b('field')()}
          placeholder={placeholder}
          mask="+7 111 111 11 11"
          name="phone"
          value={phone}
          onChange={this.onChange}
        />
      </div>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<any>) {
    console.log(e.currentTarget.value);
    this.props.onChange && this.props.onChange(e.currentTarget.value);
  }
}

export default PhoneInput;

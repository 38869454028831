import { IAppReduxState } from 'shared/types/app';
import * as NS from '../namespace';
import { IFlatPage } from 'shared/types/models';

export function getFeatureState(state: IAppReduxState): NS.IReduxState {
  return state.flatPages;
}

export function selectPage(state: IAppReduxState, type: string): IFlatPage | null {
  return selectPages(state).find(p => p.slug === type) || null;
}

export function selectPages(state: IAppReduxState): IFlatPage[] {
  return getFeatureState(state).data.pages;
}

export function selectMenuFlatPages(state: IAppReduxState): IFlatPage[] {
  return getFeatureState(state).data.pages.filter(p => p.isInMenu && p.title && p.slug);
}

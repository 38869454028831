import * as React from 'react';
import { connect } from 'react-redux';
import { IAppReduxState } from 'shared/types/app';
import * as selectors from '../../redux/selectors';

interface IStateProps {
  isAuthorized: boolean;
}

function mapState(state: IAppReduxState): IStateProps {
  return {
    isAuthorized: selectors.isAuthorized(state),
  };
}

class IsAuthorized extends React.PureComponent<IStateProps, {}> {
  public render() {
    const { isAuthorized, children } = this.props;
    return (
      isAuthorized ? children : null
    );
  }
}

export default connect<IStateProps>(mapState)(IsAuthorized);

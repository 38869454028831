import * as React from 'react';
import * as block from 'bem-cn';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { bind } from 'decko';
import Masonry, { MasonryOptions } from 'react-masonry-component';

import { IAppReduxState } from 'shared/types/app';
import { ICategory, IProduct, IUser, TProductSorting } from 'shared/types/models';
import { Button } from 'shared/view/elements';
import { ICommunication } from 'shared/types/redux';

import * as actions from '../../../redux/actions';
import { selectCategory, selectCategoryLoading, selectProductsLoading } from '../../../redux/selectors';
import ProductsList from '../../components/ProductsList/ProductsList';
import './CategoryDetails.scss';

interface IOwnProps {
  id: string;
  user: IUser | null;
  onProductSelect?: (product: IProduct) => void;
  onCategorySelect?: (category: ICategory) => void;
  ExtraProductContent?: React.ComponentType<{ product: IProduct; user: IUser | null }>;
}

interface IActionProps {
  loadCategory: typeof actions.loadCategory;
  loadCategoryProducts: typeof actions.loadCategoryProducts;
}

interface IStateProps {
  loading: ICommunication;
  productsLoading: ICommunication;
  category: ICategory | null;
}

type Props = IOwnProps & IActionProps & IStateProps;

function mapStateToProps(state: IAppReduxState): IStateProps {
  return {
    category: selectCategory(state),
    loading: selectCategoryLoading(state),
    productsLoading: selectProductsLoading(state),
  };
}

function mapActionToProps(dispatch: Dispatch<any>) {
  return bindActionCreators({
    loadCategory: actions.loadCategory,
    loadCategoryProducts: actions.loadCategoryProducts,
  }, dispatch);
}

class CategoryDetails extends React.PureComponent<Props> {
  private static masonryOpts: MasonryOptions = { horizontalOrder: true, resize: true };
  public componentDidMount() {
    this.props.loadCategory(this.props.id);
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.id !== nextProps.id) {
      this.props.loadCategory(nextProps.id);
    }
  }

  public render() {
    const { user, category, onProductSelect, loading, productsLoading, ExtraProductContent } = this.props;
    const b = block('category-details');

    return (
      <div className={b()}>
        <h2 className={b('title')()}>{category && !loading.isRequesting ? category.title : 'Загрузка...'}</h2>
        <div className={b('sub-categories')()}>
          <Masonry options={CategoryDetails.masonryOpts}>
            {category && !loading.isRequesting ? category.categories.map(c =>
              <div className={b('sub-category')()} key={c.id}>
                <Button onClick={this.onCategorySelected.bind(this, c)}>
                  <span>{c.title}</span>
                </Button>
              </div>,
            ) : null}
          </Masonry>
        </div>

        <div className={b('products')()}>
          <ProductsList
            ExtraItem={ExtraProductContent}
            user={user}
            loading={loading.isRequesting || productsLoading.isRequesting}
            load={this.onLoadProducts}
            onSelect={onProductSelect}
            items={
              category ?
                category.products : { items: [], total: 60, size: 30, page: 0, sorting: 'name', appliedFilters: [] }}
          />
        </div>
      </div>
    );
  }

  @bind
  private onCategorySelected(category: ICategory) {
    const { onCategorySelect } = this.props;
    onCategorySelect && onCategorySelect(category);
  }

  @bind
  private onLoadProducts({ page, size, sorting }: { page: number, size: number, sorting: TProductSorting }) {
    this.props.loadCategoryProducts({ page, size, sort: sorting });
  }
}

export default connect<IStateProps, IActionProps, IOwnProps>(mapStateToProps, mapActionToProps)(CategoryDetails);

import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): NS.IReduxState['data'] {
  switch (action.type) {
    case 'FLAT_PAGES:LOAD_PAGES_SUCCESS': {
      return { ...state, pages: action.payload };
    }
    default: return state;
  }
}

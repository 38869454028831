import * as NS from '../namespace';

const initial: NS.IReduxState = {
  data: {
    products: {
      items: [],
      page: 1,
      size: 30,
      sorting: 'name',
      total: 0,
      appliedFilters: [],
    },
    categories: {
      items: [],
      page: 1,
      size: 30,
      total: 0,
    },
  },
  edit: {
    query: '',
  },
  communication: {
    loadingCategories: {
      error: '',
      isRequesting: false,
    },
    loadingProducts: {
      error: '',
      isRequesting: false,
    },
  },
};

export default initial;

import { combineReducers } from 'redux';
import * as NS from '../../namespace';
import { initial } from '../initial';
import { initField, makeEditFieldReducer } from 'shared/helpers/redux';
import { ReducersMap } from 'shared/types/redux';

const profileEditingReducer = combineReducers<NS.IReduxState['edit']['profile']>({
  submitted: (state = initial.edit.profile.submitted) => state,
  firstName: makeEditFieldReducer<
    NS.IChangeFirstName, NS.IReduxState['edit']['profile']['firstName']
  >('AUTH:EDIT_FIRST_NAME', initial.edit.profile.firstName),
  lastName: makeEditFieldReducer<
    NS.IChangeLastName, NS.IReduxState['edit']['profile']['lastName']
  >('AUTH:EDIT_LAST_NAME', initial.edit.profile.lastName),
  phone: makeEditFieldReducer<
    NS.IChangePhone, NS.IReduxState['edit']['profile']['phone']
  >('AUTH:EDIT_PHONE', initial.edit.profile.phone),
  address: makeEditFieldReducer<
    NS.IChangeAddress, NS.IReduxState['edit']['profile']['address']
  >('AUTH:EDIT_ADDRESS', initial.edit.profile.address),
});

const restoreEditingReducer = combineReducers<NS.IReduxState['edit']['restore']>({
  token: (state = initial.edit.restore.token) => state,
  confirm: makeEditFieldReducer<NS.IChangeRestoreConfirmPassword, NS.IReduxState['edit']['restore']['confirm']>(
    'AUTH:EDIT_RESTORE_CONFIRM_PASSWORD', initial.edit.restore.confirm,
  ),
  password: makeEditFieldReducer<NS.IChangeRestorePassword, NS.IReduxState['edit']['restore']['password']>(
    'AUTH:EDIT_RESTORE_PASSWORD', initial.edit.restore.password,
  ),
  email: makeEditFieldReducer<NS.IChangeRestoreEmail, NS.IReduxState['edit']['restore']['email']>(
    'AUTH:EDIT_RESTORE_EMAIL', initial.edit.restore.email,
  ),
} as ReducersMap<NS.IReduxState['edit']['restore']>);

function editReducer(state: NS.IReduxState['edit'] = initial.edit, action: NS.Action): NS.IReduxState['edit'] {
  switch (action.type) {
    case 'AUTH:CHANGE_SIGNIN_EMAIL': {
      return { ...state, signIn: { ...state.signIn, email: action.payload } };
    }
    case 'AUTH:CHANGE_SIGNIN_PASSWORD': {
      return { ...state, signIn: { ...state.signIn, password: action.payload } };
    }
    case 'AUTH:CHANGE_SIGNIN_REMEMBER_ME': {
      return { ...state, signIn: { ...state.signIn, remember: action.payload } };
    }
    case 'AUTH:CHANGE_SIGNUP_EMAIL': {
      return { ...state, signUp: { ...state.signUp, email: action.payload } };
    }
    case 'AUTH:CHANGE_SIGNUP_NAME': {
      return { ...state, signUp: { ...state.signUp, name: action.payload } };
    }
    case 'AUTH:CHANGE_SIGNUP_PASSWORD': {
      return { ...state, signUp: { ...state.signUp, password: action.payload } };
    }
    case 'AUTH:SIGN_UP_SUCCESS': {
      return { ...state, signUp: { ...initial.edit.signUp } };
    }
    case 'AUTH:SIGN_IN_SUCCESS': {
      return { ...state, signIn: { ...initial.edit.signIn } };
    }
    case 'AUTH:EDIT_USER': {
      return {
        ...state,
        profile: {
          submitted: false,
          firstName: initField(action.payload.firstName),
          lastName: initField(action.payload.lastName),
          phone: initField(action.payload.phone),
          address: initField(action.payload.address),
        },
      };
    }
    case 'AUTH:UPDATE_USER': {
      return {
        ...state,
        profile: {
          ...state.profile,
          submitted: true,
        },
      };
    }
    case 'AUTH:START_FINISHING_RESTORING': {
      return { ...state, restore: { ...state.restore, token: action.payload } };
    }
    case 'AUTH:CLOSE_MODAL': {
      return { ...state, restore: { ...state.restore, token: '' } };
    }
    case 'AUTH:CHANGE_PASSWORD_CHANGE_PASS': {
      return { ...state, changePassword: { ...state.changePassword, password: action.payload } };
    }
    case 'AUTH:CHANGE_PASSWORD_CHANGE_CONFIRM': {
      return { ...state, changePassword: { ...state.changePassword, confirm: action.payload } };
    }
    case 'AUTH:CHANGE_PASSWORD_SUCCESS': {
      return { ...state, changePassword: { ...initial.edit.changePassword } };
    }
    default:
      return {
        ...state,
        profile: profileEditingReducer(state.profile, action),
        restore: restoreEditingReducer(state.restore, action),
      };
  }
}

export default editReducer;

import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';

import { initial } from '../initial';

export const communicationReducer = combineReducers<NS.IReduxState['communication']>({
  detailsNewsLoading: makeCommunicationReducer
    <NS.ILoadNewsDetails, NS.ILoadNewsDetailsSuccess, NS.ILoadNewsDetailsFail>(
    'VIEW_NEWS:LOAD_NEWS_DETAILS', 'VIEW_NEWS:LOAD_NEWS_DETAILS_SUCCESS', 'VIEW_NEWS:LOAD_NEWS_DETAILS_FAIL',
    initial.communication.detailsNewsLoading,
  ),
} as ReducersMap<NS.IReduxState['communication']>);

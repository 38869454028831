import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import Masonry from 'react-masonry-component';
import InlineSvg from 'svg-inline-react';

import { IProduct, TProductSorting, ICategory, IUser } from 'shared/types/models';
import { Paginator } from 'shared/view/elements';
import { ViewSelector } from 'shared/view/components';
import { repeat } from 'shared/helpers/funtools';

import ProductItem from '../ProductItem/ProductItem';
import Preloader from './Preloader';
import * as ArrowIcon from './img/arrow.svg';
import './ProductsList.scss';

type TView = 'grid' | 'list';
interface IProps {
  items: ICategory['products'];
  user: IUser | null;
  load: (args: { page: number, size: number, sorting: TProductSorting }) => void;
  loading?: boolean;
  ExtraItem?: React.ComponentType<{ product: IProduct; user: IUser | null; }>;
  onSelect?: (product: IProduct) => void;
  view?: TView;
  disableSorting?: boolean;
}

interface IState {
  view: TView;
}

const b = block('products-list');

class ProductsList extends React.PureComponent<IProps, IState> {
  private static GetCountByScreenSize() {
    const size = window.innerWidth;

    if (size < 1000) {
      return 3;
    }

    if (size >= 1000 && size <= 1366) {
      return 3;
    }

    if (size > 1366 && size <= 1600) {
      return 4;
    }

    return 5;
  }

  public state: IState = { view: 'grid' };
  private contentRef: HTMLDivElement | null = null;

  public componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  public render() {
    const { items, loading, ExtraItem, onSelect, user, disableSorting } = this.props;
    const countInRow = ProductsList.GetCountByScreenSize();
    const itemWidth = this.contentRef ? this.contentRef.clientWidth / countInRow - 20 : void 0;
    const view = this.props.view || this.state.view;
    const contentWidth = '100%';
    const sortingNames = {
      name: 'названию',
      price: 'цене',
    };

    return (
      <div className={b({ view })()} ref={this.onContentRef}>
        <div className={b('params')()} style={{ width: contentWidth }}>
          {
            !disableSorting ? (
              <div className={b('ordering')()}>
                Сортировать по:
                {
                  Object.keys(sortingNames).map((s: keyof typeof sortingNames) => (
                    <div
                      key={s}
                      className={b('ordering-item', { selected: s === items.sorting.replace('-', '') })()}
                    >
                      <InlineSvg
                        element="div"
                        className={b('ordering-icon', { dir: 'up', selected: s === items.sorting as string})()}
                        src={ArrowIcon}
                        onClick={this.onSortingSelect.bind(this, s)}
                      />
                      <InlineSvg
                        element="div"
                        className={b('ordering-icon', { dir: 'down', selected: ('-' + s) === items.sorting })()}
                        src={ArrowIcon}
                        onClick={this.onSortingSelect.bind(this, '-' + s)}
                      />
                      <span
                        className={b('ordering-name')()}
                        onClick={this.onSortingSelect.bind(this, s.replace('-', ''))}
                      >
                        {sortingNames[s]}
                      </span>
                    </div>
                  ))
                }
              </div>
            ) : null
          }
          {!this.props.view ? (
            <ViewSelector view={view} onGridSelected={this.onViewGridSelect} onListSelected={this.onViewListSelect} />
          ) : null}
        </div>

        {
          view === 'grid' ? (
            <Masonry options={{ resize: true }} className={b('grid')()}>
              {loading ? repeat(8, i => <Preloader type="card" key={i} width={itemWidth} margin={10} />) : null}
              {!loading && !items.items.length ? <p className={b('empty')()}>Продукты отсутствуют</p> : null}
              {!loading ? items.items.map(product =>
                <div key={product.id} className={b('item')()} style={{ width: itemWidth }}>
                  <ProductItem user={user} onClick={onSelect} Extra={ExtraItem} view="card" product={product} />
                </div>,
              ) : null}
            </Masonry>
          ) : (
            <div className={b('list')()} style={{ width: contentWidth }}>
              {loading ? repeat(3, i => <Preloader type="row" width={itemWidth} margin={15} key={i} />) : null}
              {!loading && !items.items.length ? <p className={b('empty')()}>Продукты отсутствуют</p> : null}
              {!loading ? items.items.map(product =>
                <div key={product.id} className={b('item')()}>
                  <ProductItem user={user} onClick={onSelect} Extra={ExtraItem} view="row" product={product} />
                </div>,
              ) : null}
            </div>
          )
        }
        <div className={b('paginator')()} style={{ width: contentWidth }}>
          <Paginator
            page={items.page}
            size={items.size}
            pages={Math.ceil(items.total / items.size)}
            onChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }

  @bind
  private onResize() {
    this.forceUpdate();
  }

  @bind
  private onContentRef(ref: HTMLDivElement | null) {
    this.contentRef = ref;

    if (ref) { this.forceUpdate(); }
  }

  @bind
  private onViewGridSelect() {
    this.setState({ view: 'grid' });
  }

  @bind
  private onViewListSelect() {
    this.setState({ view: 'list' });
  }

  @bind
  private onPageChange(page: number) {
    this.props.load({
      page: page - 1,
      size: this.props.items.size,
      sorting: this.props.items.sorting,
    });
  }

  @bind
  private onSizeChange(size: number) {
    this.props.load({ page: 0, size, sorting: this.props.items.sorting });
  }

  @bind
  private onSortingSelect(sorting: TProductSorting) {
    this.props.load({
      page: this.props.items.page - 1,
      size: this.props.items.size,
      sorting,
    });
  }
}

export default ProductsList;

import * as NS from '../../namespace';
import { initial } from '../initial';

function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): NS.IReduxState['data'] {
  switch (action.type) {
    case 'AUTH:SIGN_IN_SUCCESS': {
      return { ...state, user: action.payload };
    }
    case 'AUTH:FINISH_RESTORE_SUCCESS': {
      return { ...state, user: action.payload };
    }
    case 'AUTH:CHECK_USER_SUCCESS': {
      return { ...state, user: action.payload };
    }
    case 'AUTH:LOGOUT': {
      return { ...state, user: null };
    }
    case 'AUTH:UPDATE_USER_SUCCESS': {
      return { ...state, user: action.payload };
    }
  }
  return state;
}

export default dataReducer;

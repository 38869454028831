import * as React from 'react';
import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import 'normalize.css';

import { IAppData, Module } from 'shared/types/app';
import { PageScroll } from 'shared/view/components';

import createRoutes from './routes';

interface IAppProps {
  disableStylesGeneration?: boolean;
}

export function App({ modules, store }: IAppData & IAppProps) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {renderSharedPart(modules)}
      </BrowserRouter>
    </Provider>
  );
}

export function ServerApp(props: IAppData & StaticRouter['props']) {
  const { modules, store, ...routerProps } = props;
  return (
    <Provider store={store}>
      <StaticRouter {...routerProps}>
        {renderSharedPart(modules)}
      </StaticRouter>
    </Provider>
  );
}

function renderSharedPart(modules: Array<Module<any>>) {
  return (
    <PageScroll>
      {createRoutes(modules)}
    </PageScroll>
  );
}

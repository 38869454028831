import * as React from 'react';
import * as block from 'bem-cn';
import { bind } from 'decko';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { NewsList, NewsDetails } from 'features/viewNews';
import { Title } from 'shared/view/elements';
import { INews } from 'shared/types/models';

type Props = RouteComponentProps<void>;

const b = block('news');

class News extends React.PureComponent<Props, {}> {
  public render() {
    const { match } = this.props;
    return (
      <div className={b()}>
        <Switch>
          <Route exact path={`${match.url}/:id`} component={NewsDetailsRoute} />
          <Route exact path={match.url}>
            <>
              <Title text="Новости компании" />
              <NewsList view="list" onNewsSelected={this.onNewsSelected} />
            </>
          </Route>
        </Switch>
      </div>
    );
  }

  @bind
  private onNewsSelected(news: INews) {
    this.props.history.push(`/news/${news.id}`);
  }
}

function NewsDetailsRoute({ match }: RouteComponentProps<{ id: string }>) {
  return (
    <NewsDetails id={match.params.id} />
  );
}

export default News;

import { makeCommunicationActionCreators } from 'shared/helpers/redux';
import * as NS from '../../namespace';

export const {
  completed: searchCategoriesSuccess, execute: searchCategories, failed: searchCategoriesFail,
} = makeCommunicationActionCreators<
  NS.ISearchCategories, NS.ISearchCategoriesSuccess, NS.ISearchCategoriesFail
>(
  'SEARCH:EXECUTE_CATEGORIES', 'SEARCH:EXECUTE_CATEGORIES_SUCCESS', 'SEARCH:EXECUTE_CATEGORIES_FAIL',
);

export const {
  completed: searchProductsSuccess, execute: searchProducts, failed: searchProductsFail,
} = makeCommunicationActionCreators<
  NS.ISearchProducts, NS.ISearchProductsSuccess, NS.ISearchProductsFail
>(
  'SEARCH:EXECUTE_PRODUCTS', 'SEARCH:EXECUTE_PRODUCTS_SUCCESS', 'SEARCH:EXECUTE_PRODUCTS_FAIL',
);

export function changeQuery(query: string): NS.IChangeQuery {
  return { type: 'SEARCH:CHANGE_QUERY', payload: query };
}

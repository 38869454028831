import * as NS from '../namespace';

export const initial: NS.IReduxState = {
  communication: {
    deleting: {
      error: '',
      isRequesting: false,
    },
    saving: {
      error: '',
      isRequesting: false,
    },
    loading: {
      error: '',
      isRequesting: false,
    },
  },
  data: {

  },
  edit: {
    basket: { items: [], summaryPrice: 0 },
  },
};

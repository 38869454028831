import { takeLatest, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IDependencies } from 'shared/types/app';
import { requestSaga } from 'shared/helpers/redux';
import { validateOrder } from 'shared/model/order';

import * as NS from '../../namespace';
import * as actions from '../actions';
import * as selectors from '../selectors';

function getSaga(deps: IDependencies) {
  return function* saga(): SagaIterator {
    const loadPointsType: NS.ILoadDeliveryPoints['type'] = 'ORDERS:LOAD_DELIVERY_POINTS';
    const createOrderType: NS.ICreateOrder['type'] = 'ORDERS:CREATE';
    const loadHistoryType: NS.ILoadOrderHistory['type'] = 'ORDERS:LOAD_ORDER_HISTORY';
    yield [
      takeLatest(loadPointsType, loadPointsSaga, deps),
      takeLatest(createOrderType, createOrderSaga, deps),
      takeLatest(loadHistoryType, loadHistorySaga, deps),
    ];
  };
}

function* loadPointsSaga({ api }: IDependencies) {
  yield* requestSaga(
    () => api.loadDeliveryPoints(),
    actions.loadDeliveryPointsSuccess,
    actions.loadDeliveryPointsFail,
  );
}

function* createOrderSaga({ api }: IDependencies, { payload: { user, basket, order } }: NS.ICreateOrder) {
  const error = validateOrder(order);
  const hasFieldErrors = yield select(selectors.selectIsHasFieldErrors);

  if (hasFieldErrors) {
    yield put(actions.createOrderFail('Пожалуйста, исправьте ошибки выше'));
    return;
  }

  if (error) {
    yield put(actions.createOrderFail(error));
    return;
  }

  yield* requestSaga(
    () => api.createOrder(order),
    actions.createOrderSuccess,
    actions.createOrderFail,
  );
}

function* loadHistorySaga({ api }: IDependencies) {
  yield* requestSaga(
    () => api.loadOrdersHistory(),
    actions.loadOrdersHistorySuccess,
    actions.loadOrdersHistoryFail,
  );
}
export { getSaga };

import { combineReducers } from 'redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../namespace';
import data from './data';
import communication from './communication';
import edit from './edit';

export default combineReducers<NS.IReduxState>({
  data, communication, edit,
} as ReducersMap<NS.IReduxState>);

import * as React from 'react';
import * as block from 'bem-cn';
import './Button.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: Array<React.ReactElement<any>> | React.ReactElement<any>;
  theme?: 'light' | 'green';
  inline?: boolean;
  onRef?: (ref: HTMLButtonElement | null) => void;
}

class Button extends React.PureComponent<IProps, {}> {
  public render() {
    const { children, theme = 'light', onRef, inline = false, ...attrs } = this.props;
    const b = block('button');
    return (
      <button ref={onRef} className={b({ inline, theme, disabled: Boolean(attrs.disabled) })()} {...attrs}>
        {children}
      </button>
    );
  }
}

export default Button;

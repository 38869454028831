import * as NS from '../namespace';
import initial from './initial';

export default function reducer(state: NS.IReduxState = initial, action: NS.Action): NS.IReduxState {
  switch (action.type) {
    case 'HOME:LOAD_BANNERS_SUCCESS': {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    default: return state;
  }
}

import * as React from 'react';
import * as block from 'bem-cn';
import { Range } from 'rc-slider';
import { bind } from 'decko';
import 'rc-slider/assets/index.css';

interface IProps {
  from: number;
  to: number;
  fromVal: number;
  toVal: number;
  onChange?: (d: { from: number; to: number }) => void;
}

const b = block('slider');

class SliderInput extends React.PureComponent<IProps, {}> {
  public render() {
    const { from, to, fromVal, toVal } = this.props;
    const handleStyle = {
      border: '1px solid #ccc',
    };
    const trackStyle = {
      backgroundColor: '#008f32',
      height: '7px',
    };

    return (
      <div className={b()}>
        <Range
          min={from}
          max={to}
          value={[fromVal, toVal]}
          onChange={this.onChange}
          trackStyle={[trackStyle, trackStyle]}
          handleStyle={[handleStyle, handleStyle]}
        />
      </div>
    );
  }

  @bind
  private onChange(value: number[]) {
    this.props.onChange && this.props.onChange({ from: value[0], to: value[1] });
  }
}

export default SliderInput;
